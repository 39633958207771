import React, { Component } from 'react';
import router from '../../config/router-config.js';
import './style.css';
import { Modal, Toast, TextareaItem, Steps, WingBlank, WhiteSpace } from 'antd-mobile';
import ProcessInfo from './components/ProcessInfo';
import Button from '../../components/Button';
import Time from '../../components/Time';
import Multistate from '../../components/Multistate';
import Dictionaries from '../../components/Dictionaries';
import DataById from '../../components/DataById';
import Tag from './components/Tag';
import ItemContainer from './components/ItemContainer';
import ItemKV from './components/ItemKV';
import showLast from '../../statics/imgs/show_last.png';
import unShowLast from '../../statics/imgs/unshow_last.png';
import showNext from '../../statics/imgs/show_next.png';
import unShowNext from '../../statics/imgs/unshow_next.png';

import bohui from '../../statics/imgs/ic_bohui.png';  // 拒绝图标
import chaosong from '../../statics/imgs/ic_chaosong.png'; // 抄送图标
import duigou from '../../statics/imgs/ic_duigou.png'; // 提交、统一图标
import shenpi from '../../statics/imgs/ic_shenpi.png'; // 审核图标

import axios from 'axios';
import url from '../../config/url-config';
import { withActivation } from 'react-activation'
import { CONTRACT_ID, TOKEN, SHOW_ADVANCE_TYPE, CUSTOMER_ID, AUDIT_RESULT } from '../../config/constant';

import emitter from '../../utils/events';

const Step = Steps.Step;

class Detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null, // 合同id
      dictionaries: {
        businessType: [],
        businessSub: []
      },
      bigPicModalVisible: false, // pc端微信大图预览弹窗
      bigPicModalImgUrl: '', // pc端微信预览大图的url
      approvalProcess: null, // 审批流程数据
      hasLast: false,// 是否有上一条数据
      hasNext: false, // 是否有下一条数据
      lastData: null, // 上一条数据
      nextData: null, // 下一条数据
      auditCount: 1, // 待处理的总条数
      detail: null, // 合同详情数据
      enableButton: true, // button 是否可用
      multistate: 'content', // 页面状态
      errorMsg: '', // 接口请求错误提示
      myAdvanceType: 0, // 合同类型 3 待处理
      agreeModalVisible: false, // 控制审核同意弹窗显示与隐藏
      agreeReason: '', // 审批通过原因
      examOptionData: { // 审批意见弹框
        title: '',
        type: 0, // 0：通过 1：驳回
        visible: false, // 控制审核同意弹窗显示与隐藏
        reason: '', // 审批通过原因
        placeholder: '', // 占位文字
        confirmBtnText: '', // 确定按钮显示的文字
      },
      auditProcessList: [], // 审核流程
      approvalResultOpts: { 0: "同意", 1: "驳回", 2: "待审核", 3: "提交" }, // 审核状态
      approvalIconOpts: { 0: duigou, 1: bohui, 2: shenpi, 3: duigou, 4: chaosong },
      auditTypeFlag: {
        2: '合同',
        3: '合同办理',
        7: '合同作废',
        8: '合同停止合作'
      },
      msg: null,
    }
  }

  componentDidActivate() { // 缓存激活
    document.title = router.contract.detail.title;
  }

  componentDidMount() {
    document.title = router.contract.detail.title;
    this.eventEmitter = emitter.addListener(AUDIT_RESULT, this.receiverMessage); // 广播监听
    const id = sessionStorage.getItem(CONTRACT_ID);
    const myAdvanceType = sessionStorage.getItem(SHOW_ADVANCE_TYPE);
    this.setState({
      id: id,
      myAdvanceType: myAdvanceType
    })
    if (id === null) {
      // 退回登录页，重新登录
      window.location.href = url.qyweixinUrl
      return;
    }

    // 微信sdk验证
    this.loadWxSign();
    // 业务类型字典
    this.loadBusinessType();
    // 用章单位字典
    this.loadChapterUnit();
    // 业务类型细分字典
    // this.loadBusinessDetailType();
    this.modifyData(id);

  }

  receiverMessage=(msg) =>{
    console.log('get receiverMessage:',msg);
    this.showNextData();
  }

  componentWillUnmount(){
    emitter.removeAllListeners([AUDIT_RESULT]);
  }

  // 请求接口
  loadNetData(id) {
    this.setState({
      // 重置以下数据，避免重复点击
      id: id,
      // 重置数据
      approvalProcess: null, // 审批流程数据
      hasLast: false,// 是否有上一条数据
      hasNext: false, // 是否有下一条数据
      lastData: null, // 上一条数据
      nextData: null, // 下一条数据
      auditCount: 1, // 待处理的总条数
      detail: null, // 合同详情数据
      multistate: 'load', // 页面状态
      errorMsg: ''
    })
    this.loadDetail(id); // 详情数据
    this.loadApprovalProcess(id); // 垫付审批新流程数据
    this.checkDataHasNextOrLast(id); // 检查是否有下一条数据
    this.auditCount(); // 待处理的总条数
  }

  // 调用微信jssdk数据
  loadWxSign() {
    axios.get(url.tadvancepaymentWxGetSign, {
      params: {
        url: window.location.href // 当前页面完整的url
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          this.wxConfig(res.data.data)
        }
      })
  }

  // 业务类型字典数据
  loadBusinessType() {
    const that = this
    axios.get(url.bussinessType)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          let dictionaries = that.state.dictionaries
          const types = that.filterMultiDictText(res.data.data);
          dictionaries.businessType = types;
          dictionaries.businessSub = types;
          that.setState({
            dictionaries
          })
        }
      })
  }

  loadChapterUnit() {
    // 获取用章单位字典
    const that = this;
    axios.get(`${url.itemType}chapter_unit`)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          let dictionaries = that.state.dictionaries
          dictionaries.chapters = that.filterMultiDictText(res.data.data, 'value')
          that.setState({
            dictionaries
          })

        }
      })


  }

  // 业务类型细分字典数据
  loadBusinessDetailType() {
    const that = this
    axios.get(`${url.itemType}customer_business_sub_type`)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          let dictionaries = that.state.dictionaries
          dictionaries.businessSub = that.filterMultiDictText(res.data.data)
          that.setState({
            dictionaries
          })
        }
      })
  }

  // 字典数据转换
  filterMultiDictText(optionArray, type = "id") {
    var result = {};
    if (optionArray && Array.isArray(optionArray)) {
      optionArray.forEach(item => {
        result[item[type]] = item.label;
      });
    }
    return result;
  }

  // 垫付详情
  loadDetail(id) {
    const _this = this;
    axios.get(`${url.tcustomercontractDetail}${id}`)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          var data = res.data.data
          this.setState({
            detail: data,
            multistate: 'content',
            errorMsg: ''
          })
          this.goToTop();
          sessionStorage.setItem(CONTRACT_ID, data.id);
          sessionStorage.setItem(CUSTOMER_ID, data.customerId);
        }
      })
      .catch(function (error) {
        _this.setState({
          errorMsg: error,
          multistate: 'error'
        })
      });
  }

  auditCount() {
    axios.get(url.tcustomercontractWxAuditCount)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          this.setState({
            auditCount: res.data.data
          })
        }
      })
  }

  // 当前页面滚动到顶部
  goToTop() {
    const scrollToTop = window.setInterval(function () {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 2);
  }

  // 获取审批流程数据
  loadApprovalProcess(id) {
    axios.get(`${url.getNewAllAuditInfo}${id}`)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          const data = res.data.data;
          const auditInfo = data.auditInfo || [];
          const nextNodeInfo = [];

          if(data.nextNodeInfo && data.nextNodeInfo.length>1) {
            data.nextNodeInfo.forEach(item => {
              nextNodeInfo.push(
                  {
                    label: item.name,
                    value: item.id,
                    type: item.type
                  }
              )
            })
          }
          if (data.copyInfo && data.copyInfo.length) {

            let maxLength = 0;
            data.copyInfo.forEach(item => {
              const name = item.auditUser;
              if (name.length > maxLength) {
                maxLength = name.length;
              }
            });





            auditInfo.push({
              nodeName: '抄送',
              id: 'chaosongcc001',
              copyInfo: data.copyInfo.map(item => ({
                maxLength,
                ...item
              })),
              approvalResult: '4',
            })
          }
          this.setState({
            approvalProcess: data,
            auditProcessList: auditInfo,
            nextNodeInfo: nextNodeInfo
          })
        }
      })
  }

  // 检查有没有上下一条数据
  checkDataHasNextOrLast(id) {
    axios.get(url.tcustomercontractWxGetPreOrNextOne, {
      params: {
        id: id,
        auditStatus: '2' // 审批状态（0是通过，1是不通过，2待审核，3待提交 5已办理 6.已归档)
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          if (res.data && res.data.data) { // 至少有一条数据
            const data = res.data.data;
            // upOrDownFlag 1上一页  2下一页
            var lastData = null;
            var nextData = null;
            var hasLast = false;
            var hasNext = false;
            try {
              const data1 = data[0];
              // 判断有几条数据
              if (data.length >= 2) {
                const data2 = data[1];
                hasLast = true;
                hasNext = true;
                if (String(data1.upOrDownFlag) === "1") { // 第一条数据是上一条数据
                  lastData = data1;
                  nextData = data2;
                }
                if (String(data1.upOrDownFlag) === "2") { // 第一条数据是下一条数据
                  lastData = data2;
                  nextData = data1;
                }
              } else if (data.length === 1) {
                if (String(data1.upOrDownFlag) === "1") { // 第一条数据是上一条数据
                  lastData = data1;
                  hasLast = true;
                } else {
                  nextData = data1;
                  hasNext = true;
                }
              }
            } catch (e) { }
            this.setState({
              hasLast: hasLast,
              hasNext: hasNext,
              lastData: lastData,
              nextData: nextData
            })
          }
        }
      })
  }

  // 切换当前页面数据
  modifyData(id) {
    // 更新接口数据
    this.loadNetData(id)
  }

  // 当前页面数据切换成上一条或者下一条
  showLastOrNextData(type) {
    switch (type) {
      case 'last':
        if (this.state.hasLast) {
          this.modifyData(this.state.lastData.id);
        }
        break;
      case 'next':
        if (this.state.hasNext) {
          this.modifyData(this.state.nextData.id);
        }
        break;
      default:
    }
  }

  // 上传审核结果后处理下一条数据，如果没有下一条数据则处理上一条数据，如果都没有数据则退出
  showNextData() {
    this.setState({
      multistate: 'load', // 页面状态
      errorMsg: ''
    });
    setTimeout(() => {
      if (this.state.hasLast) {
        this.showLastOrNextData('last');
      } else if (this.state.hasNext) {
        this.showLastOrNextData('next');
      } else {
        // 刷新当前页面
        this.modifyData(this.state.id)
      }
    }, 800);
  }

  wxConfig(config) {
    window.wx.config({
      beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.corpid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳
      nonceStr: config.noncestr, // 必填，生成签名的随机串
      signature: config.sign,// 必填，签名
      jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
    });
  }

  // 同意按钮
  agreeButton = () => {
    // this.props.history.push(router.contract.agree.url)
    this.props.history.push({ pathname:router.contract.agree.url,state:{nextNodeInfo : this.state.nextNodeInfo } })

  }

  // 驳回按钮
  rejectButton = () => {
    this.props.history.push(router.contract.reject.url)
  }

  // 审批同意意见输入
  operateTextAreaChange = (text) => {
    let examData = this.state.examOptionData
    examData.reason = text
    this.setState({
      examData: examData
    })
  }

  // 审批通过弹窗点击确定同意
  operateModalOk = () => {
    let examData = this.state.examOptionData
    // 0通过 1不通过
    this.submitAuditResult(examData.type, examData.reason)
  }
  // 审批通过弹窗点击取消按钮
  operateModalCancel = () => {
    let examData = this.state.examOptionData
    examData.visible = false
    examData.reason = ''
    this.setState({
      examOptionData: examData,
      enableButton: true,
    })
  }

  // 弹窗取消按钮
  handleRejectCancel = e => {
    this.setState({
      bigPicModalVisible: false,
      enableButton: true
    });
  }
  // 上传审核结果
  submitAuditResult(type, inputApprovalOpinion = '') {
    const id = this.state.id;
    const _this = this;
    Toast.loading('提交中...', 0);
    axios.request({
      url: `${url.tcustomercontractWxAudit}`,
      method: 'post',
      data: {
        id: id,
        prAuditFlag: type, // 0通过 1不通过
        prAuditOpinion: inputApprovalOpinion
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          // 审核成功,处理下一条数据
          _this.operateModalCancel()
          Toast.success('已完成审批', 1)
          setTimeout(() => {
            _this.showNextData();
          }, 1100)
          try {
            // 清空缓存后回到列表页，列表页会重新请求数据
            var token = sessionStorage.getItem(TOKEN);
            sessionStorage.removeItem(token); // 清空缓存
          } catch (e) {
          }
        } else {
          Toast.offline(res.data.msg, 2);
        }
      })
      .catch(function (error) {
        Toast.offline(error, 2);
        _this.operateModalCancel()
      });
  }

  // 预览大图
  previewImage(tempUrl, urls) {
    const reg = RegExp(/Windows|Macintosh/i);
    if (navigator.userAgent.match(reg)) {
      this.setState({
        bigPicModalVisible: true,
        bigPicModalImgUrl: tempUrl
      })
    } else {
      window.wx.previewImage({
        current: tempUrl, // 当前显示图片的http链接
        urls: urls// 需要预览的图片http链接列表
      });
    }
  }

  render() {
    // 分页列表传过来的数据
    const data = this.state.detail || {};
    // 判断是否需要显示底部操作按钮
    let bottomOperateShow = false;
    if (Number(data.auditFlag) === 0) { // 非当前处理人，不显示底部按钮
      bottomOperateShow = false
    } else if (Number(data.oldProcesFlag) === 1) { // 旧流程，不显示底部按钮
      bottomOperateShow = false
    } else if (Number(data.prAuditFlag) !== 2) { // 审核流程 除了 待审核状态，其他状态不显示底部按钮
      bottomOperateShow = false
    } else {
      bottomOperateShow = true
    }
    // 业务类型细分的字典数值（适配老数据中的 ’895,897‘格式数据）
    let resultKey = data.businessSub
    if (data.businessSub && data.businessSub.indexOf(",") !== -1) {
      // 是老数据格式，选取第一个
      const array = data.businessSub.split(",")
      if (Array.isArray(array) && array.length) {
        resultKey = array[0]
      }
    }

    const advanceLetterTempUrls = [];
    const _this = this;
    const advanceLetterView = ((data && data.fileList && data.fileList.length > 0)
      ? (data.fileList.map(function (item, index) {
        var image = (/(.jpg)|(.jpeg)|(.png)/i);
        var pdf = (/(.pdf)/i);
        var pc = /Windows|Macintosh/i;
        var zip = (/(.zip)|(.rar)|(.arj)/i);

        if (image.test(item.name)) { // 过滤掉非图片
          advanceLetterTempUrls.push(item.url);
          return (<a><img onClick={() => {
            _this.previewImage(item.url, advanceLetterTempUrls)
          }} className='contract-enclosure' src={item.url} alt="合同附件" /></a>)
        } else if (zip.test(item.name)) {
          return '压缩文件！请去CRM电脑端查看'
        } else {
          let enclosureUrl = item.url
          if (pc.test(navigator.userAgent) && !pdf.test(item.name)) { // 如果是pc且非pdf格式，使用office365在线预览
            enclosureUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(item.url)}`;
          }
          return <a key={index} href={enclosureUrl} target="_blank" rel="noopener noreferrer"><div className='contract-enclosure' >查看<br />附件</div></a>
        }
      }))
      : <div>-</div>)


    const advanceQuotationAttachView = ((data && data.quotationAttaList && data.quotationAttaList.length > 0)
      ? (data.quotationAttaList.map(function (item, index) {
        var image = (/(.jpg)|(.jpeg)|(.png)/i);
        var pdf = (/(.pdf)/i);
        var pc = /Windows|Macintosh/i;
        var zip = (/(.zip)|(.rar)|(.arj)/i);

        if (image.test(item.name)) { // 过滤掉非图片
          advanceLetterTempUrls.push(item.url);
          return (<a><img onClick={() => {
            _this.previewImage(item.url, advanceLetterTempUrls)
          }} className='contract-enclosure' src={item.url} alt="报价单附件" /></a>)
        } else if (zip.test(item.name)) {
          return '压缩文件！请去CRM电脑端查看'
        } else {
          let enclosureUrl = item.url
          if (pc.test(navigator.userAgent) && !pdf.test(item.name)) { // 如果是pc且非pdf格式，使用office365在线预览
            enclosureUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(item.url)}`;
          }
          return <a key={index} href={enclosureUrl} target="_blank" rel="noopener noreferrer"><div className='contract-enclosure' >查看<br />附件</div></a>
        }
      }))
      : <div>-</div>)

    return (
      <Multistate type={this.state.multistate} msg={this.state.errorMsg}>
        <div style={{ position: "sticky", width: '100%', top: "0px", zIndex: '999' }}>
          <div className={`contract-tab ${(Number(this.state.myAdvanceType) === 3) ? '' : 'hidden'}`}>
            <div>共{this.state.auditCount}条待处理</div>
            <div className='contract-button'>
              <div onClick={() => this.showLastOrNextData('next')}>
                <img src={(this.state.hasNext) ? showLast : unShowLast} alt="下一条" />
              </div>
              <div onClick={() => this.showLastOrNextData('last')}>
                <img src={(this.state.hasLast) ? showNext : unShowNext} alt="上一条" />
              </div>
            </div>
          </div>

          <div className="contract-title">
            <div>
              {/* <DataById type="user">{data && data.createUser}</DataById> */}
              {data.submitManName || '-'}
              发起的合同申请</div>
            <Tag type={data && data.prAuditFlag} style={{ marginLeft: "12px" }} />
          </div>

          <div className={`contract-detail-reject ${(data && String(data.prAuditFlag) === '1' && data.prAuditOpinion) ? '' : 'hidden'}`} >
            <div>驳回原因</div>
            <div className="text">{(data && data.prAuditOpinion) || '没有填写驳回原因'}</div>
          </div>
        </div>

        <ItemContainer title="基本信息" >
          <ItemKV title="提交时间" fixWidthLeft><Time type="LDH">{data && data.createTime}</Time></ItemKV>
          <ItemKV title="合同名称" fixWidthLeft>{data && data.contractName}</ItemKV>
          {/* <ItemKV title="CRM编号" fixWidthLeft>{data && data.crmNo}</ItemKV> */}
          <ItemKV title="客户单位" fixWidthLeft>{data && data.customerName}
            <Dictionaries type='serviceSide'>{data && data.serviceSide}</Dictionaries>
          </ItemKV>
          <ItemKV title="客户类型" fixWidthLeft><Dictionaries type='customerType'>{data && data.customerType}</Dictionaries></ItemKV>
          <ItemKV title="商务主体" fixWidthLeft>{data && data.businessSubjectName}</ItemKV>
          <ItemKV title="合同起止" fixWidthLeft><Time type="L">{data && data.startDate}</Time>&nbsp;-&nbsp;<Time type="L">{data && data.endDate}</Time></ItemKV>
          <ItemKV title="签订状态" fixWidthLeft><Dictionaries type='signingStatus'>{data && data.signingStatus}</Dictionaries></ItemKV>
          <ItemKV title="是否老客户" fixWidthLeft><Dictionaries type='oldCustomerFlag'>{data && data.oldCustomerFlag}</Dictionaries></ItemKV>
          {/** add by hfcai */}
          <ItemKV title="是否外带" fixWidthLeft><Dictionaries type='outWithFlag'>{data && data.outWithFlag}</Dictionaries></ItemKV>
          <ItemKV title="客户先行盖章" fixWidthLeft><Dictionaries type='chapterCustomerPre'>{data && data.chapterCustomerPre}</Dictionaries></ItemKV>
          {/** end by hfcai */}
          <ItemKV title="用章类型" fixWidthLeft><Dictionaries type='chapterType'>{data && data.chapterType}</Dictionaries></ItemKV>
          <ItemKV title="用章单位" fixWidthLeft><Dictionaries datas={this.state.dictionaries.chapters}>{data && data.chapterUnit}</Dictionaries></ItemKV>
          {/** add by hfcai */}
          {/* <ItemKV title="合同模板" fixWidthLeft> <Dictionaries type='contractTemplate'>{data && data.contractTemplate}</Dictionaries> </ItemKV> */}
          <ItemKV title="业务划分" fixWidthLeft><Dictionaries type="r2Flag">{data && data.r2Flag}</Dictionaries></ItemKV>
          <ItemKV title="其他标签" fixWidthLeft><Dictionaries type="priceFlag">{data && data.priceFlag}</Dictionaries></ItemKV>
          {/** end by hfcai */}
        </ItemContainer>

        {/** add by hfcai */}

        <ItemContainer title="报价信息" style={{ display: (data && data.businessOpportunityId) ? 'block' : 'none' }}>
          <ItemKV title="关联商机" fixWidthLeft>{data && data.businessOpportunityName || '-'}</ItemKV>
          <ItemKV title="关联报价单" fixWidthLeft>{data && data.quotationName || '-'}</ItemKV>
          <ItemKV title="报价单附件" fixWidthLeft>{advanceQuotationAttachView}</ItemKV>
        </ItemContainer>

        {/** end by hfcai */}

        <ItemContainer title="业务信息" >
          <ItemKV title="业务类型" fixWidthLeft>
            <Dictionaries datas={this.state.dictionaries.businessType}>{data && data.businessType}</Dictionaries>/
            <Dictionaries datas={this.state.dictionaries.businessSub}>{resultKey}</Dictionaries>
          </ItemKV>
          <ItemKV title="合同模板" fixWidthLeft> <Dictionaries type='contractTemplate'>{data && data.contractTemplate}</Dictionaries> </ItemKV>
          <ItemKV title="合同金额" fixWidthLeft type="money">{data && data.money}</ItemKV>
          <ItemKV title="预估收入" fixWidthLeft type="money">{data && data.estimatedIncome}</ItemKV>
          <ItemKV title="服务人数" fixWidthLeft>{data && data.servicePeopleNum}</ItemKV>
          <ItemKV title="销售负责人" fixWidthLeft><DataById type="user">{data && data.businessDeveloperId}</DataById></ItemKV>
          <ItemKV title="归属部门" fixWidthLeft><DataById type="organizationinfo">{data && data.businessDeveloperOrgan}</DataById></ItemKV>
          {/* <ItemKV title="结算周期" fixWidthLeft> <Dictionaries type='settleCycle'>{data && data.settleCycle}</Dictionaries> </ItemKV>
          <ItemKV title="费用结算方式" fixWidthLeft> <Dictionaries type='settleType'>{data && data.settleType}</Dictionaries> </ItemKV> */}
          <ItemKV title="合同要点" fixWidthLeft>{data && data.remark}</ItemKV>
          <ItemKV title="合同附件" fixWidthLeft>{advanceLetterView}</ItemKV>
        </ItemContainer>

        <ItemContainer title="审批流程" style={{ borderRadius: "4px 4px 0 0", paddingBottom:80, }}>
          <WingBlank>
            <WhiteSpace />
            <Steps size="small">
              {
                this.state.auditProcessList && this.state.auditProcessList.map(item => {
                  return <Step title={item.nodeName} key={String(item.id)} icon={<img src={this.state.approvalIconOpts[String(item.approvalResult)]}
                    style={{ width: 14 }} alt="" />} description={
                      (item.copyInfo && item.copyInfo.length) ? item.copyInfo.map((info, index) => {
                        return <ProcessInfo name={info.auditUser} type='CC'
                          time={info.createTime}
                          style={{ marginTop: index > 0 ? 12 : 0 }}
                          key={info.id}
                          maxLength={info.maxLength}
                          backNodeName={null} />
                      }) : <ProcessInfo name={item.approvalManName}
                        reason={item.approvalOpinion || ''}
                        type={item.approvalResult}
                        backNodeName={item.backNodeName}
                        time={item.approvalTime} key={item.id} />
                    } >

                  </Step>
                })
              }

            </Steps>
            <WhiteSpace />

          </WingBlank>




          {/* 
          <div className="vertical-layout">
            <div className="horizontal-layout contract-step-container">
              <div className={`contract-step-line ${(this.state.approvalProcess && this.state.approvalProcess.copyInfo && this.state.approvalProcess.copyInfo.length > 0) ? '' : 'hidden'}`} >
              </div>
              <div className="contract-step-dot-container">
                <img className="contract-step-dot-img" src={approvedIcon} alt="" />
              </div>
              <div className="vertical-layout contract-step-item-container">
                <div className="contract-step-title">审批人</div>
                {
                  (this.state.approvalProcess && this.state.approvalProcess.auditInfo) ?
                    this.state.approvalProcess.auditInfo.map(function (item) {
                      return (<ProcessInfo name={item.approvalManName} reason={item.approvalOpinion || ''} type={item.approvalResult} time={item.approvalTime} key={item.id} />)
                    }) : null
                }
              </div>
            </div>

            <div className={`horizontal-layout contract-step-container ${(this.state.approvalProcess && this.state.approvalProcess.copyInfo && this.state.approvalProcess.copyInfo.length > 0) ? '' : 'hidden'}`} style={{ marginTop: "7.5px" }}>
              <div className="contract-step-dot-container">
                <img className="contract-step-dot-img" src={ccIcon} alt="" />
              </div>
              <div className="vertical-layout contract-step-item-container">
                <div className="contract-step-title">抄送人</div>
                {
                  (this.state.approvalProcess && this.state.approvalProcess.copyInfo) ?
                    this.state.approvalProcess.copyInfo.map(function (item) {
                      return (<ProcessInfo name={item.auditUser} type='CC' time={item.createTime} key={item.id} />)
                    }) : null
                }
              </div>
            </div>
          </div>
      */}

        </ItemContainer>

        {/* auditFlag是否是当前处理人 0否 1是
          prAuditFlag业务审核状态 0已通过/1不通过/2待审核/3待提交/5已办理/6已归档
          oldProcesFlag 新旧流程标志 1旧流程 2新流程*/}
        <div className={`contract-detail-apply horizontal-layout ${bottomOperateShow ? '' : 'hidden'}`}>
          <Button enable={this.state.enableButton} type="white" onClick={this.rejectButton} style={{ flexGrow: 3 }}>驳回</Button>
          <Button enable={this.state.enableButton} type="blue" onClick={this.agreeButton} style={{ flexGrow: 7, marginLeft: '10px' }}>同意</Button>
        </div>
        <div className={`contract-detail-apply step-title ${(data && (String(data.oldProcesFlag) === '2' || String(data.prAuditFlag) !== '2')) ? 'hidden' : ''}`} style={{ marginTop: "10px" }}>此流程是旧流程，请去人力云电脑端审批</div>

        <Modal
          onClose={this.handleRejectCancel}
          className="ap-modal"
          closable={true}
          transparent={true}
          visible={this.state.bigPicModalVisible}
          maskClosable={true}
          footer={[]}
        >
          <img style={{ width: "90%", height: "90%" }} src={this.state.bigPicModalImgUrl} alt="" />
        </Modal>

        {/* 审核通过弹窗 */}
        <Modal
          title={this.state.examOptionData.title}
          className="ap-modal"
          transparent
          visible={this.state.examOptionData.visible}
          onOk={this.operateModalOk}
          onClose={this.operateModalCancel}
          maskClosable={true}
          footer={[{ text: '取消', onPress: () => { this.operateModalCancel() } }, { text: this.state.examOptionData.confirmBtnText, onPress: () => { this.operateModalOk(this.state.examOptionData.type) } }]}
        >
          <TextareaItem
            placeholder={this.state.examOptionData.placeholder}
            count={500}
            rows={3}
            onChange={(val) => this.operateTextAreaChange(val)}
          />
        </Modal>
      </Multistate >
    );
  }
}
export default withActivation(Detail);
