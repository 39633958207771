import React,{ Component } from 'react';
import Multistate from '../components/Multistate';

export default class NotFound extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    // document.title = '页面不存在'
  }

  render(){
    return(
      <Multistate type="404"/>
    )
  }
}