import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import router from '../../config/router-config.js'
import KeepAlive, { AliveScope } from 'react-activation';

import EmergencyList from './EmergencyList';
import EmergencyDetail from './EmergencyDetail';


class Emergency extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <AliveScope>
        <Switch>
          <Route path={router.emergency.list.url} render={props => (
            <KeepAlive id={router.emergency.list.url} name={router.emergency.list.url}>
              <EmergencyList {...props} />
            </KeepAlive>
          )} />

          <Route render={props => (
            <KeepAlive when={[false, true]} id={router.emergency.detail.url} name={router.emergency.detail.url}>
              <EmergencyDetail {...props} />
            </KeepAlive>
          )} />
        </Switch>
      </AliveScope>
    )
  }
}

export default Emergency;