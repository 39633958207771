import React, { Component } from 'react';
import router from '../../config/router-config.js';
import './style.css';
import { Link } from 'react-router-dom';
import { Modal, Toast, TextareaItem,Flex } from 'antd-mobile';
import KVMap from '../../components/KVMap';
import ItemContainer from '../contract/components/ItemContainer';
import Tag from '../../components/Tag';
import ProcessInfo from '../../components/ProcessInfo';
import Button from '../../components/Button';
import StepIcon from '../../components/StepIcon';
import Time from '../../components/Time';
import Multistate from '../../components/Multistate';
import showLast from '../../statics/imgs/show_last.png';
import unShowLast from '../../statics/imgs/unshow_last.png';
import showNext from '../../statics/imgs/show_next.png';
import unShowNext from '../../statics/imgs/unshow_next.png';
import agreeIcon from '../../statics/imgs/agree_icon.png';
import rejectIcon from '../../statics/imgs/reject_icon.png';
import nextIcon from '../../statics/imgs/next_icon.png';
import warningIcon from '../../statics/imgs/warning.png';

import { withActivation } from 'react-activation'
import axios from 'axios';
import url from '../../config/url-config';
import { ADVANCE_ID, SHOW_ADVANCE_TYPE, ADVANCE_BILL_TYPE, SALARY_SETTLE_BILL_ID, ENGINEERING_BILL_ID, TOKEN } from '../../config/constant';


class Detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null, // 结算单id
      rejectModalVisible: false, // 驳回弹窗是否显示
      bigPicModalVisible: false, // pc端微信大图预览弹窗
      bigPicModalImgUrl: '', // pc端微信预览大图的url
      advanceLetterUrl: null, // 垫付函链接
      advanceSum: 0, // 累计垫付
      advanceBackSum: 0, // 累计回款
      incomeSum: 0,   //  累计收入
      noPayback: 0, // 尚未回款
      advancePaybackPercent: 0, // 回款百分比
      approvalProcess: null, // 审批流程数据
      rejectRemark: '', // 驳回理由
      hasLast: false,// 是否有上一条数据
      hasNext: false, // 是否有下一条数据
      lastData: null, // 上一条数据
      nextData: null, // 下一条数据
      inputApprovalOpinion: '', // 审核不通过的原因
      auditCount: 1, // 待处理的总条数
      detail: null, // 垫付详情数据
      myAdvanceType: 0, // 结算单类型 1 待处理
      enableButton: true, // button 是否可用
      multistate: 'load', // 页面状态
      errorMsg: '', // 接口请求错误提示
      noBackSHow: false,
      noPaybackData: {
        total:'',
        list:[]
      },
      approveTitle:'',//审批弹窗标题
      placeholderText:'',//审批期望值
      approveType:null//审批结果 // 0通过 1不通过
    }
  }

  componentDidActivate() { // 缓存激活
    document.title = router.advancepayment.detail.title;
  }

  componentDidMount() {
    document.title = router.advancepayment.detail.title;
    var id = sessionStorage.getItem(ADVANCE_ID);
    var myAdvanceType = sessionStorage.getItem(SHOW_ADVANCE_TYPE);
    this.setState({
      id: id,
      myAdvanceType: myAdvanceType
    })
    if (id === null) {
      // 退回登录页，重新登录
      window.location.href = url.qyweixinUrl
      return;
    }

    // 微信sdk验证
    this.loadWxSign();
    // debugger
    this.modifyData(id);
  }

  // 请求接口
  loadNetData(id) {
    this.setState({
      // 重置以下数据，避免重复点击
      id: id,
      // 重置数据
      rejectModalVisible: false, // 驳回弹窗是否显示
      advanceLetterUrl: null, // 垫付函链接
      advanceSum: 0, // 累计垫付
      advanceBackSum: 0, // 累计回款
      incomeSum: 0,   //  累计收入
      noPayback: 0, // 尚未回款
      // 过去12个月
      advanceMoneyAllByMonth: 0, // 当月已垫付
      advanceNotPayBackByYear:0,  // 最近一年，已垫付未回款金额
      advanceMoneyAllByYear:0,  // 最近一年，垫付金额
      avgDayByYear:0, // 最近一年，平均回款周期

      advancePaybackPercent: 0, // 回款百分比
      approvalProcess: null, // 审批流程数据
      rejectRemark: '', // 驳回理由
      hasLast: false,// 是否有上一条数据
      hasNext: false, // 是否有下一条数据
      lastData: null, // 上一条数据
      nextData: null, // 下一条数据
      inputApprovalOpinion: '', // 审核不通过的原因
      auditCount: 1, // 待处理的总条数
      detail: null, // 垫付详情数据
      multistate: 'load', // 页面状态
      errorMsg: ''
    })

    this.loadDetail(id); // 详情数据
    this.loadadvanceSum(id); // 累计垫付数据
    this.loadAdvanceLetter(id); // 垫付函数据
    this.loadApprovalProcess(id); // 垫付审批新流程数据
    this.checkDataHasNextOrLast(id); // 检查是否有下一条数据
  }

  // 调用微信jssdk数据
  loadWxSign() {
    axios.get(url.tadvancepaymentWxGetSign, {
      params: {
        url: window.location.href // 当前页面完整的url
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          this.wxConfig(res.data.data)
        }
      })
      .catch(function (error) {
      });
  }

  // 垫付详情
  loadDetail(id) {
    const _this = this;
    axios.get(url.tadvancepaymentDetail, {
      params: {
        id: id // 当前页面完整的url
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          var data = res.data.data
          this.setState({
            detail: data,
            multistate: 'content',
            errorMsg: ''
          })
          this.goToTop();
          sessionStorage.setItem(ADVANCE_ID, data.id);
          sessionStorage.setItem(ADVANCE_BILL_TYPE, data.billType);
          sessionStorage.setItem(SALARY_SETTLE_BILL_ID, data.salarySettleBillId);
          sessionStorage.setItem(ENGINEERING_BILL_ID, data.engineeringBillId);
        }
      })
      .catch(function (error) {
        _this.setState({
          errorMsg: error,
          multistate: 'error'
        })
      });
  }

  // 当前页面滚动到顶部
  goToTop() {
    const scrollToTop = window.setInterval(function () {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 2);
  }

  // 获取累计垫付数据
  loadadvanceSum(id) {
    axios.get(url.tadvancepaymentQueryWxPassSumBySellteDomin, {
      params: {
        id: id // 当前页面完整的url
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          // 回款进度计算
          var advancePaybackPercent = 0; // 回款金额百分数
          var noPayback = 0; // 尚未回款进度
          var advanceSum = 0; // 累计垫付
          var advanceBackSum = 0; // 累计回款
          if (res.data) {
            if (res.data.data.advanceSum) {
              advanceSum = res.data.data.advanceSum;
            }
            if (res.data.data.advanceBackSum) {
              advanceBackSum = res.data.data.advanceBackSum;
            }
          }
          noPayback = advanceSum - advanceBackSum;
          if (advanceSum !== 0) {
            advancePaybackPercent = parseInt((advanceBackSum / advanceSum) * 100); // 累计回款 / 累计垫付
          }
          this.setState({
            advanceSum: advanceSum, // 累计垫付
            advanceBackSum: advanceBackSum, // 累计回款
            incomeSum: res.data.data.incomeSum,   //  累计收入
            noPayback: noPayback, // 尚未回款
            advancePaybackPercent: advancePaybackPercent, // 回款百分比
            advanceNotPayBackByYear:res.data.data.advanceNotPayBackByYear,
            advanceMoneyAllByYear:res.data.data.advanceMoneyAllByYear,
            advanceMoneyAllByMonth:res.data.data.advanceMoneyAllByMonth,
            avgDayByYear:res.data.data.avgDayByYear,
          });
        }
      })
      .catch(function (error) {

      });
  }

  // 获取垫付函预览大图
  loadAdvanceLetter(id) {
    axios.get(url.tpaymentattainfoGeteAttas + id)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          if (res.data.data) {
            this.setState({
              advanceLetterUrl: res.data.data, // 垫付函地址
            });
          }
        }
      })
      .catch(function (error) {

      });
  }

  // 获取审批流程数据
  loadApprovalProcess(id) {
    axios.get(url.tapproveauditinfoWxGetAllAuditAndCopyInfo, {
      params: {
        mainId: id
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          const data = res.data.data;
          // 循环查出驳回原因
          var auditRemark = '';
          try {
            if (data && data.auditInfo) {
              var item = data.auditInfo[data.auditInfo.length - 1];
              // （ 1.审核通过 2审核未通过 3待审核
              if (String(item.auditStatus) === "2" && item.auditRemark) {
                auditRemark = item.auditRemark;
              } else { 
                auditRemark = '';
              }
            }
          } catch (e) {

          }
          this.setState({
            approvalProcess: data,
            rejectRemark: auditRemark,
            auditCount: data.auditCount
          })
        }
      })
      .catch(function (error) {
      });
  }

  // 检查有没有上下一条数据
  checkDataHasNextOrLast(id) {
    axios.get(url.tadvancepaymentWxGetPreOrNextOne, {
      params: {
        id: id,
        auditStatus: '1' // 审批状态（0待提交/1待审核/2审核通过/3审核退回/4已作废/5已终止)
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          if (res.data && res.data.data) { // 至少有一条数据
            const data = res.data.data;
            // upOrDownFlag 1上一页  2下一页
            var lastData = null;
            var nextData = null;
            var hasLast = false;
            var hasNext = false;
            try {
              const data1 = data[0];
              // 判断有几条数据
              if (data.length >= 2) {
                const data2 = data[1];
                hasLast = true;
                hasNext = true;
                if (String(data1.upOrDownFlag) === "1") { // 第一条数据是上一条数据
                  lastData = data1;
                  nextData = data2;
                }

                if (String(data1.upOrDownFlag) === "2") { // 第一条数据是下一条数据
                  lastData = data2;
                  nextData = data1;
                }
              } else if (data.length === 1) {
                if (String(data1.upOrDownFlag) === "1") { // 第一条数据是上一条数据
                  lastData = data1;
                  hasLast = true;
                } else {
                  nextData = data1;
                  hasNext = true;
                }
              }
            } catch (e) { }
            this.setState({
              hasLast: hasLast,
              hasNext: hasNext,
              lastData: lastData,
              nextData: nextData
            })
          }
        }
      })
      .catch(function (error) {

      });
  }

  // 切换当前页面数据
  modifyData(id) {
    // 更新接口数据
    this.loadNetData(id)
  }

  // 当前页面数据切换成上一条或者下一条
  showLastOrNextData(type) {
    switch (type) {
      case 'last':
        if (this.state.hasLast) {
          this.modifyData(this.state.lastData.id);
        }
        break;
      case 'next':
        if (this.state.hasNext) {
          this.modifyData(this.state.nextData.id);
        }
        break;
      default:
    }
  }

  // 上传审核结果后处理下一条数据，如果没有下一条数据则处理上一条数据，如果都没有数据则退出
  showNextData() {
    this.setState({
      multistate: 'load', // 页面状态
      errorMsg: ''
    });

    setTimeout(() => {
      if (this.state.hasLast) {
        this.showLastOrNextData('last');
      } else if (this.state.hasNext) {
        this.showLastOrNextData('next');
      } else {
        // 刷新当前页面
        this.modifyData(this.state.id)
      }
    }, 800);
  }

  wxConfig(config) {
    window.wx.config({
      beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.corpid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳
      nonceStr: config.noncestr, // 必填，生成签名的随机串
      signature: config.sign,// 必填，签名
      jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
    });
  }

  // 同意按钮
  agreeButton = () => {
    this.setState({
      enableButton: false,
      approveTitle:'通过',
      placeholderText:'请输入审批意见',
      rejectModalVisible: true,
      approveType:0
    });
    
  }

  // 驳回按钮
  rejectButton = () => {
    this.setState({
      enableButton: false,
      approveTitle:'驳回',
      placeholderText:'请输入驳回理由(必填)',
      rejectModalVisible: true,
      approveType:1
    })
  }

  // 弹窗确定按钮
  handleRejectOk = e => {
    const inputApprovalOpinion = this.state.inputApprovalOpinion;
    if (this.state.approveType == 1&&!inputApprovalOpinion) {
      Toast.fail('审批原因不能为空', 1)
      return
    }
    this.submitAuditResult(this.state.approveType, inputApprovalOpinion)
    this.setState({
      rejectModalVisible: false
    });
  };

  // 弹窗取消按钮
  handleRejectCancel = e => {
    this.setState({
      rejectModalVisible: false, // 这两个按钮可以同时可用
      bigPicModalVisible: false,
      enableButton: true,
      approveType:null,
      inputApprovalOpinion:''
    });
  };

  // 上传审核结果
  submitAuditResult(type, inputApprovalOpinion = '') {
    const id = this.state.id;
    const _this = this;
    Toast.loading('提交中...', 0);
    axios.post(url.tpaymentapprovalrecordSubmitAndaudit, {
      advancePaymentId: id,
      approvalResult: type, // 0通过 1不通过
      approvalOpinion: inputApprovalOpinion
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          // 审核成功,处理下一条数据
          Toast.success('已完成审批', 1)
          setTimeout(() => {
            this.showNextData();
          }, 1100)

          try {
            // 清空缓存后回到列表页，列表页会重新请求数据
            var token = sessionStorage.getItem(TOKEN);
            sessionStorage.removeItem(token); // 清空缓存
          } catch (e) {

          }
        } else {
          Toast.offline(res.data.msg, 2);
        }
        this.setState({
          enableButton: true,
        });
      })
      .catch(function (error) {
        Toast.offline(error, 2);
        _this.setState({
          enableButton: true
        });
      });
  }

  // 驳回理由输入框监听
  theModelTextareaChangeListener(text) {
    this.setState({
      inputApprovalOpinion: text
    })
  }

  // 预览大图
  previewImage(url, urls) {
    const reg = RegExp(/Windows|Macintosh/i);
    if (navigator.userAgent.match(reg)) {
      this.setState({
        bigPicModalVisible: true,
        bigPicModalImgUrl: url
      })
    } else {
      
      window.wx.previewImage({
        current: url, // 当前显示图片的http链接
        urls: urls// 需要预览的图片http链接列表
      });
    }
  }

  // 垫付类型转换成字符串
  advanceTypeText(type) {
    switch (type) {
      case "1":
        return '业务约定垫付';
      case "2":
        return '偶发性垫付';
      case "3":
        return '常规性垫付';
      case "4":
        return '渠道垫付';
      case "5":
      default:
        return '其他';
    }
  }

  showNoPayback(){
    axios.get(url.noPaybackList + this.state.detail.settleDomainId)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          const datas = res.data.data
          if (datas) {
            const obj = {total:datas.advanceNotPaybackTotal,list:datas.overDuePaymentList}
            this.setState({
              noPaybackData: obj,
              noBackSHow: true
            });
          }
        }
      }).catch(function (error) {
        console.log(error)
      });
  }

  closePayBack = ()=>{
    this.setState({
      noBackSHow: false
    });
  }

  render() {
    // 分页列表传过来的数据
    const data = this.state.detail || {};
    const advanceLetterTempUrls = [];
    const _this = this;
    const advanceLetterView = ((this.state.advanceLetterUrl && this.state.advanceLetterUrl.length > 0) ?
      (this.state.advanceLetterUrl.map(function (item,index) {
        var reg = (/(.jpg|.jpeg|.png|.gif|.bmp)/i);
        var pdf = (/(.pdf)|(.doc(x)?)|(.ppt(x)?)|(.xls(x)?)/i);
        var pc = /Windows|Macintosh/i;
        if (reg.test(item.name)) { // 过滤掉非图片
          advanceLetterTempUrls.push(item.url);
          return (<a><img key={index} onClick={() => {
            _this.previewImage(item.url, advanceLetterTempUrls)

          }} style={{ width: "46px", height: "46px", padding: "0 10px 3px 0",float:'left' }} src={item.url} alt="垫付函" /></a>)
        } else if (pdf.test(item.name)) {
          let enclosureUrl = item.url
          if (pc.test(navigator.userAgent) && !pdf.test(item.name)) { // 如果是pc且非pdf格式，使用office365在线预览
            enclosureUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(item.url)}`;
          }
          return <a href={enclosureUrl} key={index} target="_blank" rel="noopener noreferrer" style={{ float:'left' }}><div className='contract-enclosure' >查看<br />附件</div></a>
        } else {
          return null
        }
      }))
      : <div>-</div>)

    // 判断是否需要显示底部操作按钮
    let bottomOperateShow = false;
    if (Number(data.auditFlag) === 0) { // 非当前处理人，不显示底部按钮
      bottomOperateShow = false
    } else if (Number(data.oldProcesFlag) === 1) { // 旧流程，不显示底部按钮
      bottomOperateShow = false
    } else if (Number(data.auditStatus) !== 1) { // 审核流程 除了 待审核状态，其他状态不显示底部按钮
      bottomOperateShow = false
    } else {
      bottomOperateShow = true
    }


    const showWarningIcon = (data.existOverduePayment && sessionStorage.getItem('showAdvanceType') == 1) ? <img src={warningIcon} onClick={() => this.showNoPayback()} style={{width:'25px',position: 'absolute',right: '0px',top: '-33px'}} /> : null

    return data ? (
      <Multistate type={this.state.multistate} msg={this.state.errorMsg}>
        <div className={this.props.className}>
          <div className={`ap-detail-tab ${(Number(data.auditFlag )=== 1 && Number(data.auditStatus) === 1) ? '' : 'hidden'}`}>
            <div>共{this.state.auditCount}条待处理</div>
            <div className="button">
              <div onClick={() => this.showLastOrNextData('last')}>
                <img src={(this.state.hasLast) ? showLast : unShowLast} alt="上一条" />
              </div>
              <div onClick={() => this.showLastOrNextData('next')}>
                <img src={(this.state.hasNext) ? showNext : unShowNext} alt="下一条" />
              </div>
            </div>
          </div>

          {
            (Number(data.auditStatus) === 5 && data.terminationResion) ? (
              <div className="ap-reject-container">
                <div className="ap-detail-reject">
                  <div>终止原因</div>
                  <div className="text">{data.terminationResion}</div>
                </div>
              </div>
            ) : null
          }
          {
            (Number(data.auditStatus) === 3 && this.state.rejectRemark) ? (
              <div className="ap-reject-container">
                <div className="ap-detail-reject">
                  <div>驳回原因</div>
                  <div className="text">{this.state.rejectRemark}</div>
                </div>
              </div>
            ) : null
          }
          <ItemContainer>
            <div className='titleWrap' style={{ marginBottom: '10px' }}>
              <div className="titleText">{(data) ? data.createUser : ''}的垫付申请</div>
              <Tag type={0} status={(data) ? data.auditStatus : ''}>审批中</Tag>
            </div>
            <KVMap title="垫付编号" >{(data) ? data.advancePaymentNo : ''}</KVMap>
            <KVMap title="提交时间" ><Time type="LDH">{(data) ? data.createTime : ''}</Time></KVMap>
            <KVMap title="预计回款"><Time type="Y">{(data) ? data.paybackDate : ''}</Time></KVMap>
            <KVMap title="垫付金额" type="money" theme="warn">{(data) ? data.advanceMoney : ''}</KVMap>
            <KVMap title="结算金额" type="money">{(data) ? data.settleMoney : ''}</KVMap>
            {/* <KVMap title="累计垫付" type="money">{this.state.advanceSum}</KVMap> */}
            <KVMap title="累计收入" type="money">{this.state.incomeSum}</KVMap>
          </ItemContainer>
          <ItemContainer title="回款情况(过去12个月的数据统计)">
            <div style={{marginTop: "13px",position:'relative'}}>
              { showWarningIcon }
              <KVMap title="当月已垫付" type="money">{this.state.advanceMoneyAllByMonth}</KVMap>
              <KVMap title="尚未回款" type="money" theme="warn">{this.state.advanceNotPayBackByYear}</KVMap>
              <KVMap title="累计垫付" type="money">{this.state.advanceMoneyAllByYear}</KVMap>
              <KVMap title="平均回款周期">{this.state.avgDayByYear ? (this.state.avgDayByYear+'天') : '-'}</KVMap>
            </div>
          </ItemContainer>
          {/* <ItemContainer title="回款情况">
            <div className="horizontal-layout payment-collection">
              <KVMap title="尚未回款" type="money" space="small" theme="warn">{this.state.noPayback}</KVMap>
              <KVMap title="回款进度" space="small">{this.state.advancePaybackPercent}%</KVMap>
            </div>
            <Progress
              percent={this.state.advancePaybackPercent} position="normal"
              style={{ marginTop: "13px", marginBottom: "9px", height: "7.5px", background: "#f3f3f3", borderRadius: "7.5px" }} />
          </ItemContainer> */}

          <ItemContainer>
            <KVMap title="客户单位" className="kv_item">{(data) ? data.customerName : ''}</KVMap>
            <div className="line" />
            <KVMap title="结算主体" className="kv_item">{(data) ? data.departName : ''}</KVMap>
            <div className="line" />
            <KVMap title="商务主体" className="kv_item">{(data) ? data.applyUnitName : ''}</KVMap>
            <div className="line" />
            <KVMap title="垫付类型" className="kv_item">{this.advanceTypeText((data) ? data.advanceType : '')}</KVMap>
            <div className="line" />
            <KVMap title="回款周期" className="kv_item">{(data && data.backPayCycle) ? (data.backPayCycle+'个月') : ''}</KVMap>
            <div className="line" />
            <KVMap title="垫付原因" className="kv_item">{(data) ? data.advanceReason : ''}</KVMap>
            <div className="line" />
            <KVMap title="垫付函" className="kv_item" style={{ padding: '6px 0 1px 0' }}>
              {advanceLetterView}
            </KVMap>
          </ItemContainer>

          {/* 是否逾期垫付 */}
          <ItemContainer>
            <KVMap title="是否逾期垫付" className="kv_item">{(data) ? (data.spNo?'是':'否') : ''}</KVMap>
            <div className="line" />
            <KVMap title="逾期垫付编号" className="" className={`kv_item  ${data.spNo ? '' : 'hidden'}` }>{(data) ? data.spNo : '-'}</KVMap>
          </ItemContainer>

          <ItemContainer>
            {/* billType 0:普通薪资 1: 工程薪资 */}
            <Link to={router.advancepayment.settleaccounts.url}>
              <div className="titleWrap">
                <div className="title">查看结算单</div>
                <img style={{ height: "11px" }} src={nextIcon} alt="查看更多" />
              </div>
            </Link>
          </ItemContainer>

          <ItemContainer>
            <div className="title" style={{ marginBottom: "16px" }}>审批流程</div>
            <div className= "vertical-layout">
              <div className="horizontal-layout step-container">
                <div className={`step-line ${((this.state.approvalProcess && this.state.approvalProcess.wxAllCopyInfo)) ? '' : 'hidden'}`} >

                </div>
                <div className="step-dot-container">
                  <StepIcon type="primary" />
                </div>
                <div className="vertical-layout step-item-container">
                  <div className="step-title">审批人</div>
                  {
                    (this.state.approvalProcess && this.state.approvalProcess.auditInfo) ?
                      this.state.approvalProcess.auditInfo.map(function (item) {
                        return (
                          <div key={item.id}>
                            <ProcessInfo name={item.auditUser} type={item.auditStatus} time={item.auditTime}  />
                            {item.auditRemark ? <div className="auditRemark" >
                                  审批意见：{item.auditRemark}
                              </div>:null
                            }
                            
                          </div>
                        )
                      }) : null
                  }
                </div>
                
              </div>

              <div className={`horizontal-layout step-container ${((this.state.approvalProcess && this.state.approvalProcess.wxAllCopyInfo)) ? '' : 'hidden'}`} style={{ marginTop: "7.5px" }}>

                <div className="step-dot-container">
                  <StepIcon type="ghost" />
                </div>
                <div className="vertical-layout step-item-container">
                  <div className="step-title">抄送人</div>
                  {
                    (this.state.approvalProcess && this.state.approvalProcess.wxAllCopyInfo) ?
                      this.state.approvalProcess.wxAllCopyInfo.map(function (item) {
                        return (<ProcessInfo name={item.auditUser} type='CC' time={item.createTime} key={item.id} />)
                      }) : null
                  }
                </div>
              </div>
            </div>
          </ItemContainer>
          {/* auditFlag是否是当前处理人 0否 1是
          auditStatus审批状态 0待提交/1待审核/2审核通过/3审核退回/4已作废/5已终止
          oldProcesFlag 新旧流程标志 1旧流程 2新流程*/}
          <div className={`ap-detail-apply horizontal-layout ${bottomOperateShow ? '' : 'hidden'}`}>
            <Button enable={this.state.enableButton} type="white" img={rejectIcon} onClick={this.rejectButton} style={{ flexGrow: 3 }}>驳回</Button>
            <Button enable={this.state.enableButton} type="blue" img={agreeIcon} onClick={this.agreeButton} style={{ flexGrow: 7, marginLeft: '10px' }}>同意</Button>
          </div>
          <div className={`ap-detail-apply step-title ${((data && Number(data.oldProcesFlag) === 1)) ? '' : 'hidden'}`} style={{ marginTop: "10px" }}>此流程是旧流程，请去人力云电脑端审批</div>
        </div>
      
        {/* 审核通过/驳回弹窗 */}
        <Modal
          title={this.state.approveTitle}
          className="ap-modal"
          transparent
          visible={this.state.rejectModalVisible}
          onOk={this.handleRejectOk}
          onClose={this.handleRejectCancel}
          maskClosable={true}
          footer={[{ text: '取消', onPress: () => { this.handleRejectCancel(); } }, { text: '确定', onPress: () => { this.handleRejectOk(); } }]}
        >
          <TextareaItem
            placeholder={this.state.placeholderText}
            count={500}
            rows={3}
            onChange={(val) => this.theModelTextareaChangeListener(val)}
          />
        </Modal>
        
        <Modal
          onClose={this.handleRejectCancel}
          className="ap-modal"
          closable={true}
          transparent={true}
          visible={this.state.bigPicModalVisible}
          maskClosable={true}
          footer={[]}
        >
          <img style={{ width: "90%", height: "90%" }} src={this.state.bigPicModalImgUrl} alt="" />
        </Modal>

        <Modal
          className="list-modal"
          closable={true}
          transparent={true}
          visible={this.state.noBackSHow}
          maskClosable={true}
          footer={[]}
          onClose={this.closePayBack}
        >
          <p>逾期未回款情况</p>
          <p>（逾期未回款合计：{ this.state.noPaybackData.total }元）</p>
          <div className="box">
            <div className="boxLeft">
              <span>垫付申请日期</span>
              <span>最迟回款日期</span>
              <span>垫付金额</span>
              <span style={{color:'red'}}>尚未回款</span>
              <span>承诺回款日期</span>
              <span className="noBot">逾期天数</span>
            </div>
            <div className= {`boxRight  ${this.state.noPaybackData.list.length < 3? 'boxRight1' : ''}`}>
              <div>
                {
                  (this.state.noPaybackData&&this.state.noPaybackData.list)?this.state.noPaybackData.list.map((item,index)=>{
                        return (
                          <span   key={index}>{item.createTime}</span>
                        )
                    }):null
                  }
              </div>
              <div>
                {
                  (this.state.noPaybackData&&this.state.noPaybackData.list)?this.state.noPaybackData.list.map((item,index)=>{
                        return (
                          <span key={index}>{item.paybackDateFinal}</span>
                        )
                    }):null
                  }
              </div>
              <div>
                {
                  (this.state.noPaybackData&&this.state.noPaybackData.list)?this.state.noPaybackData.list.map((item,index)=>{
                        return (
                          <span key={index}>{item.advanceMoney}</span>
                        )
                    }):null
                  }
              </div>
              <div>
                {
                  (this.state.noPaybackData&&this.state.noPaybackData.list)?this.state.noPaybackData.list.map((item,index)=>{
                        return (
                          <span key={index} style={{color:'red'}}>{item.advanceNotPayback}</span>
                        )
                    }):null
                  }
              </div>
              <div>
                {
                  (this.state.noPaybackData&&this.state.noPaybackData.list)?this.state.noPaybackData.list.map((item,index)=>{
                        return (
                          <span key={index}>{item.paybackDate}</span>
                        )
                    }):null
                  }
              </div>
              <div>
                {
                  (this.state.noPaybackData&&this.state.noPaybackData.list)?this.state.noPaybackData.list.map((item,index)=>{
                        return (
                          <span className="noBot" key={index}>{item.overdueDay}</span>
                        )
                    }):null
                  }
              </div>
            </div>
          </div>
        </Modal>

      </Multistate>
    ) : null
  }
}
export default withActivation(Detail)
