import React, { Component } from 'react';
import './style.css';

import Time from './Time';

import agreeIcon from '../statics/imgs/step_agree_icon.png';
import rejectIcon from '../statics/imgs/step_unagree_icon.png';
import ccIcon from '../statics/imgs/step_cc_icon.png';

// name=>操作的对象，即人员名称
// type=>状态（ 1.审核通过 2审核未通过 3待审核） CC 已抄送 ,不传或传其他:无状态
// time=>操作时间
class ProcessInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getStatus(type) {
        switch (type) {
            case '1':
                return ['已同意', agreeIcon];
            case '2':
                return ['已驳回', rejectIcon];
            case 'CC':
                return ['已抄送', ccIcon];
            default:
                return null;
        }
    }

    render() {

        const status = this.getStatus(this.props.type);
        var statusView = null;
        if (status) {
            statusView = (<>
                <div className="horizontal-layout pi-container child">

                    <div className="horizontal-layout">
                        <img src={status[1]} alt={status[0]}/>
                        <div style={{ marginLeft: "7px" }}>{status[0]}</div>
                    </div>
                    <Time type='DH' style={{ marginLeft: "5px" }}>{this.props.time}</Time>
                </div>
            </>)
        }

        return (<div className={this.props.className} style={this.props.style}>

            <div className="horizontal-layout pi-container">
                <div >{this.props.name || '未知'}</div>
                {statusView}
            </div>

        </div>);
    }
}

export default ProcessInfo;