import React from 'react';
import { CONTRACT_ID, TOKEN, REJECT_STACT, AUDIT_RESULT } from '../../config/constant';
import { Toast, TextareaItem, Picker, List, WhiteSpace, Button } from 'antd-mobile';
import './style.css';
import router from '../../config/router-config.js';
import axios from 'axios';
import url from '../../config/url-config';
import emitter from '../../utils/events';
import { withActivation } from 'react-activation'

// 驳回合同审批
class Reject extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id:null,
      inputApprovalOpinion: '', // 审核不通过的原因
      enableButton: false, // button 是否可用
      pickData: [], // 驳回至选项数据
      backNodeId: null,
      loading:false,
    }
  }

  componentDidActivate() { // 缓存激活，此处没有效果，需要注意解决方案
    document.title = router.contract.reject.title;
    this.setState({
      inputApprovalOpinion: ''
    })
  }

  componentDidMount() {
    let that = this
    document.title = router.contract.reject.title;
    var id = sessionStorage.getItem(CONTRACT_ID);
    this.setState({
      id: id,
      inputApprovalOpinion: ''
    })
    this.getRejectData(id);
    this.props.history.listen(r => {
      if(r.pathname === '/contract/reject'){
        that.getRejectData(id);
      }
    })
  }

  getRejectData(id) {
    let that = this;
    axios.get(`${url.rejectList}?auditType=2&domainId=${id}`).then(res => {
      console.log('获取驳回选择列表：', res)
      if (res.data && Number(res.data.code) === 200 && res.data.data) {
        const data = res.data.data;
        that.setState({
          pickData: data.map(item => {
            return {
              label: item.nodeName,
              value: item.nodeId,
            }
          }),
          backNodeId:[data[res.data.data.length-1].nodeId]
        });
      }
    }, error => {
      that.props.history.goBack();
    });
  }

  // 驳回理由输入框监听
  theModelTextareaChangeListener(text) {
    this.setState({
      inputApprovalOpinion: text,
      enableButton: !!(text.trim() && text.trim().length && this.state.backNodeId && this.state.backNodeId.length),
    });
  }

  // 上传审核结果
  submitAuditResult = () =>  {
    this.loading =true;
    const id = this.state.id;
    const _this = this;
    Toast.loading('提交中...', 0);

    const params = {
      id,
      prAuditFlag: 1, // 0通过 1不通过
      prAuditOpinion:this.state.inputApprovalOpinion,
      backNodeId:this.state.backNodeId[0]
    }
    // 驳回接口

    axios.post(url.tcustomercontractWxAudit,params,).then(res => {
        if (res && res.data.code === 200) {
          // 审核成功,处理下一条数据
          Toast.success('已驳回', 1)
          setTimeout(() => {
            // 驳回成功，处理数据
            emitter.emit(AUDIT_RESULT,{
              type:1,
              result:'success'
            });
            _this.props.history.goBack();
          }, 1100)
          try {
            // 清空缓存后回到列表页，列表页会重新请求数据
            var token = sessionStorage.getItem(TOKEN);
            sessionStorage.removeItem(token); // 清空缓存
          } catch (e) {
            console.log('get exception:',e);
          }
        } else {
          Toast.offline(res.data.msg, 2);
        }
        this.setState({
          loading: false
        });
      })
      .catch(function (error) {
        Toast.offline(error, 2);
        _this.setState({
          loading: false
        });
      });
  }

  onChange = (value) => {
    this.setState({
      backNodeId: value,
      enableButton: !!(this.state.inputApprovalOpinion.trim() && this.state.inputApprovalOpinion.trim().length && value && value.length) ,
    })
  }

  render() {
    return (<div className="contract-reject-container" >
       <WhiteSpace />
      <Picker cols={1} data={this.state.pickData}
        onChange={this.onChange}
        value={this.state.backNodeId}>
        <List.Item className="contract-require-item" arrow="horizontal">驳回至</List.Item>
      </Picker>

      <WhiteSpace />

      <div className="contract-reject-content">
        <div className="contract-reject-title">驳回意见</div>
        <TextareaItem
          value={this.state.inputApprovalOpinion}
          placeholder='请填写驳回意见，不超过200字'
          count={200}
          rows={10}
          style={{ fontSize: 14 }}
          onChange={(val) => this.theModelTextareaChangeListener(val)}
        />
      </div>

      <div className="contract-reject-button">
        <Button loading={this.loading} type="primary" disabled={!this.state.enableButton} onClick={this.submitAuditResult}>确认</Button>
      </div>

    </div>)
  }
}

export default withActivation(Reject);
