import React from 'react';
import './App.css';
import './config/axios-config.js';
import router from './config/router-config.js';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AdvancePayment from './pages/advance-payment/AdvancePayment'
import Contract from './pages/contract/Contract';
import Emergency from './pages/emergency/Emergency';

import NotFound from './pages/NotFound';
import Login from './pages/Login';
import { Provider } from 'react-redux';
import store from './redux/store';


function App() {
  return (
    <div className="app-container">
      <div className="app-content">
        <Provider store={store}>
          <Router>
              <Switch>
                <Route path={router.login.url} component={Login} />
                <Route path={router.advancepayment.url} component={AdvancePayment} />
                <Route path={router.contract.url} component={Contract} />
                <Route path={router.emergency.url} component={Emergency} />
                <Route component={NotFound} />
              </Switch>
          </Router>
        </Provider>
      </div>
    </div>
  );
}

export default App;
