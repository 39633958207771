import React, { Component } from 'react';
import router from '../../config/router-config.js';
import './style.css';
import KVMap from '../../components/KVMap';
import Time from '../../components/Time';
import axios from 'axios';
import url from '../../config/url-config';
import Multistate from '../../components/Multistate';
import SalaryItem from '../../components/SalaryItem';
import NoTaxItem from '../../components/NoTaxItem';
import { ListView, PullToRefresh, Flex } from 'antd-mobile';
import { withActivation } from 'react-activation'
import { ADVANCE_BILL_TYPE, SALARY_SETTLE_BILL_ID,ADVANCE_ID, ENGINEERING_BILL_ID } from '../../config/constant';

const dataSource = new ListView.DataSource({
    rowHasChanged: (row1, row2) => row1 !== row2,
});

class SettleAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settleDepartName: '', // 结算单位
            settleMonth: '', // 结算时间
            settlementAmount: '', // 结算金额
            invoiceAmount: '', // 开票金额
            tabularData: [], // 表格数据
            multistate: 'load', // 页面状态，默认加载数据
            errorMsg: '', // 页面状态提示信息
            listData: [], // 列表数据
            isLoading: false,
            noMoreData: false,// 没有更多数据了，为true时不触发加载更多
            listHeight: document.documentElement.clientHeight - 200,
            pageIndex: 1,// 当前请求数据的页码
            settleFormId: '', // 结算单id
        }
    }

    componentDidActivate() { // 缓存激活
        document.title = router.advancepayment.settleaccounts.title;
    }

    componentDidMount() {
        document.title = router.advancepayment.settleaccounts.title;
        this.getData();
    }

    getData() {
        var billType = sessionStorage.getItem(ADVANCE_BILL_TYPE);
        if (billType === "0") { // 普通薪资
            this.loadDataForNet(url.salaryOpenBill, sessionStorage.getItem(SALARY_SETTLE_BILL_ID))
        } else if (billType === "1") { // 工程薪资
            this.loadDataForNet(url.engineerOpenBill, sessionStorage.getItem(ENGINEERING_BILL_ID))
        } else if(billType === "2"){ // 非扣税项
            // this.getHeadData(url.tadvancepaymentDetail)
            this.loadDataForNet(url.salaryOpenBill, sessionStorage.getItem(SALARY_SETTLE_BILL_ID))
            this.loadNoTaxList(1)
        } else {
            // 退回登录页，重新登录
            window.location.href = url.qyweixinUrl
        }
    }

    getHeadData(url){
        const param = { id: sessionStorage.getItem(ADVANCE_ID) }
        axios.get(url, {params: param}).then(res => {
            if (res && Number(res.data.code) === 200) {
                this.setState({
                    settleDepartName: res.data.data.departName, // 结算单位
                    settleMonth: res.data.data.applyAdvanceDate, // 结算时间
                    settlementAmount: res.data.data.settleMoney, // 结算金额
                    invoiceAmount: res.data.data.ticketMoney, // 开票金额
                    multistate: 'content',
                    errorMsg: ''
                })
            }
        })
    }

    loadDataForNet(requestUrl, salaryFormId) {
        const _this = this;
        axios.request({
            url: requestUrl,
            method: 'post',
            params: {
                salaryFormId: salaryFormId
            }
        })
            .then(res => {
                if (res && res.data.code === 200) {
                    switch (requestUrl) {
                        case url.salaryOpenBill: // 普通薪资
                            _this.salaryBill(res.data.data);
                            _this.setState({
                                settleFormId: res.data.data.settlementhForm.id
                            })
                            break;
                        case url.engineerOpenBill: // 工程薪资
                            _this.engineerBill(res.data.data);
                            _this.setState({
                                settleFormId: res.data.data.engineerSettlementForm.id
                            })
                            break;
                        default:
                    }
                }
            })
            .catch(function (error) {
                _this.setState({
                    multistate: 'error',
                    errorMsg: error
                })
            });
    }

    salaryBill(item) {// 普通薪资结算单数据
        const datas = [];
        // ['项目', '金额', '增值税', '小计']
        datas.push(['应发工资', item.settlementhForm.salarySum, item.settlementhForm.salaryTax, item.settlementhForm.salaryTicket]);
        datas.push(['实发工资(银付)', item.settlementhForm.cardPay, '', '']);
        datas.push(['实发工资(现金)', item.settlementhForm.moneyPay, '', '']);
        datas.push(['暂停发金额', item.settlementhForm.pausePay, '', '']);
        datas.push(['代缴单位社保', item.settlementhForm.unitSocial, item.settlementhForm.unitSocialTax, item.settlementhForm.unitSocialTicket]);
        datas.push(['代缴个人社保', item.settlementhForm.personalSocial, '', '']);
        datas.push(['没工资员工单位社保', item.settlementhForm.noSalaryUnitSocial, item.settlementhForm.noSalaryUnitSocialTax, item.settlementhForm.noSalaryUnitSocialTicket]);
        datas.push(['没工资员工个人社保', item.settlementhForm.noSalaryPersonSocial, item.settlementhForm.noSalaryPersonSocialTax, item.settlementhForm.noSalaryPersonSocialTicket]);
        datas.push(['代缴单位公积金', item.settlementhForm.unitFund, item.settlementhForm.unitFundTax, item.settlementhForm.unitFundTicket]);
        datas.push(['代缴个人公积金', item.settlementhForm.personalFund, '', '']);
        datas.push(['没工资员工单位公积金', item.settlementhForm.noSalaryUnitFund, item.settlementhForm.noSalaryUnitFundTax, item.settlementhForm.noSalaryUnitFundTicket]);
        datas.push(['没工资员工个人公积金', item.settlementhForm.noSalaryPersonalFund, item.settlementhForm.noSalaryPersonalFundTax, item.settlementhForm.noSalaryPersonalFundTicket]);
        datas.push(['代扣当月个人税', item.settlementhForm.personalCurmonthTax, '', '']);
        datas.push(['代扣个人年终奖税', item.settlementhForm.personalYearTax, '', '']);
        datas.push(['代扣个人社保', item.settlementhForm.withholidingPersonSocial, '', '']);
        datas.push(['代扣个人公积金', item.settlementhForm.withholidingPersonFund, '', '']);
        datas.push(['代扣单位社保', item.settlementhForm.withholidingUnitSocial, item.settlementhForm.withholidingUnitSocialTax, item.settlementhForm.withholidingUnitSocialTicket]);
        datas.push(['代扣单位公积金', item.settlementhForm.withholidingUnitFund, item.settlementhForm.withholidingUnitFundTax, item.settlementhForm.withholidingUnitFundTicket]);
        datas.push(['商险金额', item.settlementhForm.takingRisks, item.settlementhForm.takingRisksTax, item.settlementhForm.takingRisksTicket]);
        datas.push(['商险差额', item.settlementhForm.takingBalance, item.settlementhForm.takingBalanceTax, item.settlementhForm.takingBalanceTicket]);
        datas.push(['风险抵押金', item.settlementhForm.riskFundDy, '', '']);
        datas.push(['代扣个人费用', item.settlementhForm.deductPersonal, '', '']);
        datas.push(['代扣单位费用', item.settlementhForm.deductUnit, item.settlementhForm.deductUnitTax, item.settlementhForm.deductUnitTicket]);
        datas.push(['其它代办费2', item.settlementhForm.otherDbMoney, '', '']);
        datas.push(['单位待补足', item.settlementhForm.unitSupplement, item.settlementhForm.unitSupplementTax, item.settlementhForm.unitSupplementTicket]);
        datas.push(['个人待补足', item.settlementhForm.persoanSupplement, '', '']);
        datas.push(['个人社保、公积金欠款', item.settlementhForm.personalDebt, '', '']);
        datas.push(['个人其他费用', item.settlementhForm.personalOtherMoney, '', '']);
        datas.push(['前次个人待补足', item.settlementhForm.prevPersoanSupplement, '', '']);
        datas.push(['单位返还', item.settlementhForm.unitBack, item.settlementhForm.unitBackTax, item.settlementhForm.unitBackTicket]);
        datas.push(['单位社保卡费', item.settlementhForm.unitSocialCard, '', '']);
        datas.push(['免个税个人代扣', item.settlementhForm.exemptionPersionTax, '', '']);
        datas.push(['企业（职业）年金', item.settlementhForm.enterpriseAnnuity, '', '']);
        datas.push(['独生子女费', item.settlementhForm.onlyChild, '', '']);
        datas.push(['风险金', item.settlementhForm.riskFund, item.settlementhForm.riskFundTax, item.settlementhForm.riskFundTicket]);
        datas.push(['人数', item.settlementhForm.personNum, '', '']);
        datas.push(['人次', item.settlementhForm.personTime, '', '']);
        datas.push(['管理费', item.settlementhForm.managementMoney, item.settlementhForm.managementMoneyTax, item.settlementhForm.managementMoneyTicket]);
        datas.push(['管理费2', item.settlementhForm.managementMoneyTwo, item.settlementhForm.managementMoneyTwoTax, item.settlementhForm.managementMoneyTwoTicket]);
        datas.push(['结算金额合计', '', item.settlementhForm.countTax, item.settlementhForm.accountMoneySum]);
        datas.push(['开票金额合计', '', '', item.settlementhForm.ticketMoneySum]);
        datas.push(['上次差余额', '', '', item.settlementhForm.prevPoorBalance]);
        datas.push(['本次差余额', '', '', item.settlementhForm.curPoorBalance]);
        datas.push(['备注', item.settlementhForm.remark, '', '']);

        this.setState({
            settleDepartName: item.settlementhForm.settleDepartName, // 结算单位
            settleMonth: item.settlementhForm.settleMonth, // 结算时间
            settlementAmount: item.settlementhForm.accountMoneySum, // 结算金额
            invoiceAmount: item.settlementhForm.ticketMoneySum, // 开票金额
            tabularData: datas,
            multistate: 'content',
            errorMsg: ''

        })
    }

    engineerBill(item) { // 工程薪资结算单数据
        const datas = [];
        // ['项目', '金额', '增值税', '小计']
        datas.push(['应发工资', item.engineerSettlementForm.salarySum, '', item.engineerSettlementForm.salaryTicket]);
        datas.push(['实发工资(银付)', item.engineerSettlementForm.cardPay, '', '']);
        datas.push(['实发工资(现金)', item.engineerSettlementForm.moneyPay, '', '']);
        datas.push(['暂停发金额', item.engineerSettlementForm.pausePay, '', '']);
        datas.push(['代缴单位社保', item.engineerSettlementForm.usocialDeduction, '', '']);
        datas.push(['代缴个人社保', item.engineerSettlementForm.psocialDeduction, '', '']);
        datas.push(['代缴单位公积金', item.engineerSettlementForm.ufundDeduction, '', '']);
        datas.push(['代缴个人公积金', item.engineerSettlementForm.pfundDeduction, '', '']);
        datas.push(['雇主责任险', item.engineerSettlementForm.employerLiabilityInsurance, '', '']);
        datas.push(['商险差余额', item.engineerSettlementForm.takingBalance, '', '']);
        datas.push(['其它费用', item.engineerSettlementForm.other, '', '']);
        datas.push(['增值税金及附加', item.engineerSettlementForm.valueDaddedTax, '', item.engineerSettlementForm.valueDaddedTaxTicket]);
        datas.push(['预缴企业所得税', item.engineerSettlementForm.unitIncomeTax, '', item.engineerSettlementForm.unitIncomeTaxTicket]);
        datas.push(['预缴个人所得税', item.engineerSettlementForm.personIncomeTax, '', item.engineerSettlementForm.personIncomeTaxTicket]);
        datas.push(['人数', item.engineerSettlementForm.personCount, '', '']);
        datas.push(['管理费', item.engineerSettlementForm.managementCost, '', item.engineerSettlementForm.managementCostTicket]);
        datas.push(['结算金额合计', '', '', item.engineerSettlementForm.accountMoneySum]);
        datas.push(['开票金额合计', '', '', item.engineerSettlementForm.ticketMoneySum]);
        datas.push(['备注', item.engineerSettlementForm.remark, '', '']);

        this.setState({
            settleDepartName: item.engineerSettlementForm.settleDepartName, // 结算单位
            settleMonth: item.engineerSettlementForm.settleMonth, // 结算时间
            settlementAmount: item.engineerSettlementForm.accountMoneySum, // 结算金额
            invoiceAmount: item.engineerSettlementForm.ticketMoneySum, // 开票金额
            tabularData: datas,
            multistate: 'content',
            errorMsg: ''
        })
    }
    // 下拉刷新触发
    onRefresh = () => {
        this.setState({ isLoading: true });
        this.refreshData();
    };
    // 刷新数据
    refreshData() {
        // 重置数据并重新请求
        this.setState({
            pageIndex: 1,
            noMoreData: false
        })

        this.loadNoTaxList(1);
    }
    // 处理数据
    handleData(tempData, page) {
        var templistData = []
        if (page === 1) {
            templistData = tempData.records || [];
        } else {
            templistData = this.state.listData.concat(tempData.records)
        }
        this.setState({
            noMoreData: tempData.current >= tempData.pages, // 当前页等于总页数，数据已全部请求
            pageIndex: tempData.current,
            listData: templistData,
            isLoading: false,
            multistate: (templistData.length === 0 && page === 1) ? 'empty' : 'contnent',
            errorMsg: (templistData.length === 0 && page === 1) ? `暂无` : ''
        });
    }
    // 请求数据
    loadNoTaxList(page) {
        var tempUrl = url.noTaxBill + '?billType=2&settleFormId=' + sessionStorage.getItem(SALARY_SETTLE_BILL_ID) + '&current=' + page + '&size=10';
        const _this = this;
        axios.get(tempUrl)
            .then(res => {
                if (res && Number(res.data.code) === 200) {
                    this.handleData(res.data.data, page);
                }
            })
            .catch(function (error) {
                _this.setState({
                    isLoading: false,
                    multistate: 'error',
                    errorMsg: error
                });
            });
    }
    // 加载数据
    loadMoreData() {
        var num = this.state.pageIndex;
        this.loadNoTaxList(++num);
    }
    // 加载更多触发
    onEndReached = () => {
        if (this.state.isLoading && this.state.noMoreData) {
            return;
        }
        this.setState({ isLoading: true });
        this.loadMoreData();
    }

    render() {
        const row = (data, _, rowID) => {
            return (<NoTaxItem key={rowID} index={rowID} data={data} />);
        };

        return (<Multistate type={this.state.multistate} msg={this.state.errorMsg}>

            <div className="ap-detail-apply border">
                <div className="title">{this.state.settleDepartName}</div>
                <KVMap title="结算时间" style={{ marginTop: "10px" }}><Time type="YYYYMM">{this.state.settleMonth}</Time></KVMap>
                <KVMap title="结算金额" type="money">{this.state.settlementAmount}</KVMap>
                <KVMap title="开票金额" type="money">{this.state.invoiceAmount}</KVMap>
            </div>

                {
                    sessionStorage.getItem(ADVANCE_BILL_TYPE) == 2 ? 
                    <div className="salaryList">
                        <Flex className="salaryListItem">
                            <Flex.Item><span>账户名</span></Flex.Item>
                            <Flex.Item><span>项目名称</span></Flex.Item>
                            <Flex.Item><span>金额</span></Flex.Item>
                        </Flex>
                        <ListView
                        ref={el => this.lv = el}
                        initialListSize={this.state.listData && this.state.listData.length}
                        dataSource={dataSource.cloneWithRows(this.state.listData || [])}
                        renderFooter={() => (
                            <div style={{ padding: 10, textAlign: 'center' }}>
                            {this.state.noMoreData ? '没有更多数据了' : this.state.isLoading ? '加载中...' : '请求完成'}
                            </div>
                        )}
                        renderRow={row}
                        style={{
                            height: this.state.listHeight,
                            overflow: 'auto'
                        }}
                        contentContainerStyle={{ height: "100%" }}
                        scrollRenderAheadDistance={500}
                        onEndReached={this.onEndReached}
                        onEndReachedThreshold={500}
                        pullToRefresh={<PullToRefresh
                            refreshing={this.state.isLoading}
                            onRefresh={this.onRefresh}
                        />}
                        />
                    </div>
                    : 
                    <div className="sa-tables-container">
                        <div className="sa-tables-item">
                            <div className="sa-tables-item-1">项目</div>
                            <div className="sa-tables-item-2">金额</div>
                            <div className="sa-tables-item-3">增值税</div>
                            <div className="sa-tables-item-4">小计</div>
                        </div>
                        {
                            this.state.tabularData.map(function (item, i) {
                                return (<div className="sa-tables-item item" key={i}>

                                    {
                                        item.map(function (da, j) {
                                            return (<div key={j}>{da}</div>)
                                        })
                                    }

                                </div>)
                            })
                        }
                    </div>
                }

            

            


        </Multistate>);
    }
}

export default withActivation(SettleAccounts);