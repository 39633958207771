import React, { Component } from 'react';
import router from '../../config/router-config.js';
import './style.css';
// import { Link } from 'react-router-dom';
import { Modal, Toast, TextareaItem  } from 'antd-mobile';
import KVMap from '../../components/KVMap';
import ItemContainer from '../contract/components/ItemContainer';
import url from '../../config/url-config';


import Tag from '../../components/Tag';
import EmergecyProcess from '../../components/EmergecyProcess';
import Button from '../../components/Button';
import StepIcon from '../../components/StepIcon';
import Time from '../../components/Time';
import Multistate from '../../components/Multistate';
import showLast from '../../statics/imgs/show_last.png';
import unShowLast from '../../statics/imgs/unshow_last.png';
import showNext from '../../statics/imgs/show_next.png';
import unShowNext from '../../statics/imgs/unshow_next.png';


import { withActivation } from 'react-activation'
import axios from 'axios';

import { EMERGENCY_ID, TOKEN,SHOW_EMERGENCY_TYPE } from '../../config/constant';

class Detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null, // 结算单id
      riskBearOrgan: '', // 部门或单位
      treatmentDict: [], // 治疗类型
      processRecord: [], // 审批流程数据
      fileList: [], // 突发事件附件
      detail: null, // 突发事件详情
      modelType: null,
      modelTitle: '',
      formLabel: '',
      toMajorShow: false,
      index: 0,
      total: 0,
      customerName: '', // 客户名称
      departName: '', // 结算主体名称
      departAll: [], // 所有结算主体
      hasLast: false,// 是否有上一条数据
      hasNext: false, // 是否有下一条数据
      injuryDict: [],
      nonworkDict: [],
      retiredDict: [],
      businessTypeDict: [],
      enventType:null,
      
      rejectModalVisible: false, // 驳回弹窗是否显示
      bigPicModalVisible: false, // pc端微信大图预览弹窗
      bigPicModalImgUrl: '', // pc端微信预览大图的url
      advanceLetterUrl: null, // 垫付函链接

      inputApprovalOpinion: '', // 审核不通过的原因
      enableButton: true, // button 是否可用
      multistate: 'load', // 页面状态
      errorMsg: '', // 接口请求错误提示

    }
  }

  componentDidActivate() { // 缓存激活
    document.title = router.emergency.detail.title;
  }

  componentDidMount() {
    document.title = router.emergency.detail.title;
    var id = sessionStorage.getItem(EMERGENCY_ID);
    this.setState({
      id: id
    })
    if (id === null) {
      // 退回登录页，重新登录
      window.location.href = url.qyweixinUrl
      return;
    }

    // 微信sdk验证
    this.loadWxSign();
    this.getDictionData();

  }

  getResultData(res,val){
    if(res.status === 200 && res.data.code === 200){
      return res.data.data
    }else{
      return val
    }
  }

  // 获取字典
  getDictionData = async () =>{
    // 治疗类型
    const res1 = await axios.get('admin/dict/itemType/treatment_type');
    // 工伤事件类型
    const res2 = await axios.get('admin/dict/itemType/emergency_event_type');
    // 非因公事件类型
    const res3 = await axios.get('admin/dict/itemType/nonwork_event_type');
    // 退工事件类型
    const res4 = await axios.get('admin/dict/itemType/retired_type');
    // 业务类型
    const res5 = await axios.get('/hrBase/sysdictbusinesstype/getSysDictBusinessTypeList');
    // 待处理个数
    const res6 = await axios.post('/hrEmergency/h5/emergency/countPending');
    this.setState({
      treatmentDict: this.getResultData(res1,[]),
      injuryDict: this.getResultData(res2,[]),
      nonworkDict: this.getResultData(res3,[]),
      retiredDict: this.getResultData(res4,[]),
      businessTypeDict: this.getResultData(res5,[]),
      total: this.getResultData(res6,0),
    })
    await this.modifyData(sessionStorage.getItem(EMERGENCY_ID));
  }

  // 请求接口
  loadNetData(id) {
    this.setState({
      // 重置以下数据，避免重复点击
      id: id,
      fileList: [],
      detail: null, // 突发事件详情
      processRecord: [], // 审批流程数据
      // 重置数据
      rejectModalVisible: false, // 驳回弹窗是否显示
      advanceLetterUrl: null, // 垫付函链接
      inputApprovalOpinion: '', // 审核不通过的原因
      multistate: 'load', // 页面状态
      errorMsg: ''
    })
    this.getEmergencyDetail(id);
    // this.loadDetail(id); // 详情数据

  }

  // 调用微信jssdk数据
  loadWxSign() {
    axios.get(url.tadvancepaymentWxGetSign, {
      params: {
        url: window.location.href // 当前页面完整的url
      }
    }).then(res => {
      if (res && Number(res.data.code) === 200) {
        this.wxConfig(res.data.data)
      }
    })
  }
  
  getEmergencyDetail = async (id) => {
    const index = Number(sessionStorage.getItem('index'))
    const total = this.state.total
    const res = await axios.get(url.emergencyDetail,{params: { id: id }});
    if(res.status === 200 && res.data.code === 200){
      const data = res.data.data;
      if(data){
        sessionStorage.setItem(SHOW_EMERGENCY_TYPE, data.handlingStatus);
        let details = await this.handleData(data);
        let fileList = []
        if(details){
          // 获取附件
          const fileRes = await axios.get(`/hrEmergency/fileUpload/ossFileUrl/domain/${data.eventId}?type=${data.type}`);
          details.fileList = this.handleFileList(fileRes);
          fileList = details.fileList;
          const record = await axios.get(url.emergencyProcessRecord, {params: { size:50,eventType: data.type, eventId: data.eventId }});
          const temp6 = this.getResultData(record,[]);
          const processRecord = temp6 ? temp6.records : []

          this.setState({
            detail: details, // 突发事件详情
            fileList: fileList,
            enventType:data.type,
            processRecord:processRecord,
            multistate: 'content',
            errorMsg: '',
            index: index,
            hasLast: index > 1,
            hasNext: index < total
          })
          this.goToTop();
        }else{
          Toast.fail('该事件不存在', 1)
          const _this = this;
          setTimeout(function(){
            window.close();
          },1500);
        }
        
      }
    }
  }
  handleFileList(res){
    if(res && res.status === 200 && res.data.code === 200){
      return res.data.data ? res.data.data : []
    }else{
      return []
    }
  }
  // 处理详情数据
  handleData = async (data) => {
    let detail = null
    const socialOption = ['已购买，与劳动合同起始时间一致', '已购买，与劳动合同起始时间不一致', '未购买']
    const injuryDict = this.state.injuryDict;
    const nonworkDict = this.state.nonworkDict;
    const businessTypeDict = this.state.businessTypeDict;
    const retiredDict = this.state.retiredDict;
    const customer = await axios.get(`/hrBase/tcustomerinfo/${data.cusId}`);
    const settleDomain = await axios.get(`/hrBase/tsettledomain/${data.settleDomainId}`);
    const temp1 = this.getResultData(customer,'');
    const customerName = temp1 ? temp1.customerName : ''
    const temp2 = this.getResultData(settleDomain,'');
    const departName = temp2 ? temp2.departName : '';
    let eventTypeName = '-', businessTypeName = '-';

    switch (data.type) {
      case '0':
        if(data.workInjuryVo){
          const workInjuryEvent = data.workInjuryVo.workInjuryEvent
          eventTypeName = this.getFieldName(injuryDict, workInjuryEvent.eventType, 'value', 'label')
          businessTypeName = this.getFieldName(businessTypeDict, workInjuryEvent.businessType, 'id', 'label')
          let riskBearOrgan = ''
          if (workInjuryEvent.riskBear === '1') {
            const riskBearOrganRes = await axios.get(`/hrBase/tcustomerinfo/${workInjuryEvent.riskBearUnit}`);
            const temp4 = this.getResultData(riskBearOrganRes,'');
            riskBearOrgan = temp4 ? temp4.customerName : ''
          }else if(workInjuryEvent.riskBear === '0'){
            const riskBearOrganRes = await axios.get(`/admin/organizationinfo/${workInjuryEvent.riskBearOrgan}`);
            const temp4 = this.getResultData(riskBearOrganRes,'');
            riskBearOrgan = temp4 ? temp4.organName : ''
          }
          detail = {
            curloginUser:data.curloginUser,
            curAuditMan:data.curAuditMan,
            type:workInjuryEvent.type,
            emName: workInjuryEvent.emName,
            auditStatus: workInjuryEvent.auditStatus,
            reportDate: workInjuryEvent.reportDate,
            riskBear: workInjuryEvent.riskBear,
            riskBearOrgan: riskBearOrgan,
            departName: departName,
            customerName: customerName,
            post: workInjuryEvent.post,
            sex: this.getSex(workInjuryEvent.emIdCard),
            empAge: workInjuryEvent.empAge,
            empPhone: workInjuryEvent.empPhone,
            eventType:workInjuryEvent.eventType, // 5交通事故
            eventTypeName: eventTypeName,
            businessTypeName: businessTypeName, // 业务类型
            socialInsurance: socialOption[workInjuryEvent.socialStatus],
            insurance: this.getIncurance(data.workInjuryVo.insurance),
            area: `${workInjuryEvent.provinceName || ''}${workInjuryEvent.cityName || ''}${workInjuryEvent.townName || ''}`,
            contractRange: `${workInjuryEvent.contractStart || ''}~${workInjuryEvent.contractEnd || ''}`,
            enventTypeName: '工伤',
            eventCode: workInjuryEvent.eventCode,
            eventOccurrenceTime: workInjuryEvent.eventOccurrenceTime, // 事件发生时间
            eventOccurrenceAddr: workInjuryEvent.eventOccurrenceAddr, // 事故发生地点
            disabilityLevel: workInjuryEvent.disabilityLevel, // 伤残等级
            injuryPosition: workInjuryEvent.injuryPosition, // 	伤害部位
            isCuring: workInjuryEvent.isCuring, // 是否治疗（0是/1否）
            curingType: workInjuryEvent.curingType, // 治疗类型（字典）
            curingHospital: workInjuryEvent.curingHospital, // 就诊医院
            eventThrough: workInjuryEvent.eventThrough, // 事故发生经过
            payPlan: workInjuryEvent.payPlan, // 赔付方案
            customerOpinion: workInjuryEvent.customerOpinion, // 客户意见
            fileList: []
          }
          if(workInjuryEvent.eventType === '5'){
            const params = {
              carOfUnit: workInjuryEvent.carOfUnit, // 车辆归属单位
              payStrongInsurance: workInjuryEvent.payStrongInsurance, // 交强险赔付额
              carInsuranceUnit: workInjuryEvent.carInsuranceUnit, // 车辆保险购买单位
              thirdPartyLiabilityInsurance: workInjuryEvent.thirdPartyLiabilityInsurance, // 第三责任险保额
              woundedHukouAttribution: workInjuryEvent.woundedHukouAttribution == 0 ? '城镇居民' : '农村户口', // 伤者户口归属
              injuredArea: workInjuryEvent.injuredArea, // 受伤部位
              injuredDegree: workInjuryEvent.injuredDegree, // 受伤程度
              age: workInjuryEvent.age, // 年龄
              thirdPersonIdCard: workInjuryEvent.thirdPersonIdCard, // 第三人身份号
              injuredFamilyStatus: workInjuryEvent.injuredFamilyStatus, // 伤者家庭情况
              treatmentCosts: workInjuryEvent.treatmentCosts, // 治疗费（预判）

            }
            detail = Object.assign(detail,params)
            console.log(params)
          }
        }
        break
      case '1':
        if(data.nonWorkVo){
          const nonWorkEvent = data.nonWorkVo.nonWorkEvent;
          eventTypeName = this.getFieldName(nonworkDict, nonWorkEvent.eventType, 'value', 'label');
          businessTypeName = this.getFieldName(businessTypeDict, nonWorkEvent.businessType, 'id', 'label');
          let riskBearOrgan = ''
          if (nonWorkEvent.riskBear === '1') {
            const riskBearOrganRes = await axios.get(`/hrBase/tcustomerinfo/${nonWorkEvent.riskBearUnit}`);
            const temp4 = this.getResultData(riskBearOrganRes,'');
            riskBearOrgan = temp4 ? temp4.customerName : ''
          }else if(nonWorkEvent.riskBear === '0'){
            const riskBearOrganRes = await axios.get(`/admin/organizationinfo/${nonWorkEvent.riskBearOrgan}`);
            const temp4 = this.getResultData(riskBearOrganRes,'');
            riskBearOrgan = temp4 ? temp4.organName : ''
          }
          detail = {
            curloginUser:data.curloginUser,
            curAuditMan:data.curAuditMan,
            type:nonWorkEvent.type,
            emName: nonWorkEvent.emName,
            auditStatus: nonWorkEvent.auditStatus,
            departName: departName,
            customerName: customerName,
            reportDate: nonWorkEvent.reportDate,
            riskBear: nonWorkEvent.riskBear,
            riskBearOrgan: riskBearOrgan,
            post: nonWorkEvent.post,
            empAge: nonWorkEvent.empAge,
            sex: this.getSex(nonWorkEvent.emIdCard),
            empPhone: nonWorkEvent.empPhone,
            eventType:nonWorkEvent.eventType,
            eventTypeName: eventTypeName,
            businessTypeName: businessTypeName, // 业务类型
            socialInsurance: socialOption[nonWorkEvent.socialStatus],
            insurance: this.getIncurance(data.nonWorkVo.insurance),
            area: `${nonWorkEvent.provinceName || ''}${nonWorkEvent.cityName || ''}${nonWorkEvent.townName || ''}`,
            contractRange: `${nonWorkEvent.contractStart || ''}~${nonWorkEvent.contractEnd || ''}`,
            enventTypeName: '非因工',
            eventCode: nonWorkEvent.eventCode,
            eventOccurrenceTime: nonWorkEvent.eventOccurrenceTime, // 事件发生时间
            eventOccurrenceAddr: nonWorkEvent.eventOccurrenceAddr, // 事故发生地点
            disabilityLevel: nonWorkEvent.disabilityLevel, // 伤残等级
            injuryPosition: nonWorkEvent.injuryPosition, // 	伤害部位
            isCuring: nonWorkEvent.isCuring, // 是否治疗（0是/1否）
            curingType: nonWorkEvent.curingType, // 治疗类型（字典）
            curingHospital: nonWorkEvent.curingHospital, // 就诊医院
            eventThrough: nonWorkEvent.eventThrough, // 事故发生经过
            payPlan: nonWorkEvent.payPlan, // 赔付方案
            customerOpinion: nonWorkEvent.customerOpinion, // 客户意见
            fileList: []
          }
        }
        break
      case '2':
        if(data.retiredWorkVo){
          const retiredWorkEvent = data.retiredWorkVo.retiredWorkEvent
          eventTypeName = this.getFieldName(retiredDict, retiredWorkEvent.eventType, 'value', 'label')
          businessTypeName = this.getFieldName(businessTypeDict, retiredWorkEvent.businessType, 'id', 'label')
          detail = {
            curloginUser:data.curloginUser,
            curAuditMan:data.curAuditMan,
            type:retiredWorkEvent.type,
            emName: retiredWorkEvent.emName,
            auditStatus: retiredWorkEvent.auditStatus,
            departName: departName,
            customerName: customerName,
            reportDate: retiredWorkEvent.reportDate,
            post: retiredWorkEvent.post,
            empAge: retiredWorkEvent.empAge,
            sex: this.getSex(retiredWorkEvent.emIdCard),
            empPhone: retiredWorkEvent.empPhone,
            eventType:retiredWorkEvent.eventType,
            eventTypeName: eventTypeName,
            businessTypeName: businessTypeName, // 业务类型
            socialInsurance: socialOption[retiredWorkEvent.socialStatus],
            insurance: this.getIncurance(data.retiredWorkVo.insurance),
            area: `${retiredWorkEvent.provinceName || ''}${retiredWorkEvent.cityName || ''}${retiredWorkEvent.townName || ''}`,
            contractRange: `${retiredWorkEvent.contractStart || ''}~${retiredWorkEvent.contractEnd || ''}`,
            workLengthWxYear: `${retiredWorkEvent.workLengthWxYear || ''}年${retiredWorkEvent.workLengthWxMonth || ''}月`,
            workLengthCustomerYear: `${retiredWorkEvent.workLengthCustomerYear || ''}年${retiredWorkEvent.workLengthCustomerMonth || ''}月`,
            averageSalary: retiredWorkEvent.averageSalary,
            enventTypeName: '退工',
            eventCode: retiredWorkEvent.eventCode,
            eventOccurrenceTime: retiredWorkEvent.eventOccurrenceTime, // 事件发生时间
            eventThrough: retiredWorkEvent.eventThrough, // 事件说明
            payPlan: retiredWorkEvent.payPlan, // 赔付方案
            customerOpinion: retiredWorkEvent.customerOpinion, // 客户意见
            fileList: []
          }
        }
        break
      case '3':
        if(data.abitrationVo){
          const abitrationEvent = data.abitrationVo.abitrationEvent
          businessTypeName = this.getFieldName(businessTypeDict, abitrationEvent.businessType, 'id', 'label')
          detail = {
            curloginUser:data.curloginUser,
            curAuditMan:data.curAuditMan,
            type:abitrationEvent.type,
            emName: abitrationEvent.emName,
            auditStatus: abitrationEvent.auditStatus,
            departName: departName,
            customerName: customerName,
            reportDate: abitrationEvent.reportDate,
            post: abitrationEvent.post,
            empAge: abitrationEvent.empAge,
            sex: this.getSex(abitrationEvent.emIdCard),
            empPhone: abitrationEvent.empPhone,
            eventType:abitrationEvent.eventType,
            businessTypeName: businessTypeName, // 业务类型
            socialInsurance: socialOption[abitrationEvent.socialStatus],
            insurance: this.getIncurance(data.abitrationVo.insurance),
            area: `${abitrationEvent.provinceName || ''}${abitrationEvent.cityName || ''}${abitrationEvent.townName || ''}`,
            contractRange: `${abitrationEvent.contractStart || ''}~${abitrationEvent.contractEnd || ''}`,
            workLengthWxYear: `${abitrationEvent.workLengthWxYear || ''}年${abitrationEvent.workLengthWxMonth || ''}月`,
            workLengthCustomerYear: `${abitrationEvent.workLengthCustomerYear || ''}年${abitrationEvent.workLengthCustomerMonth || ''}月`,
            averageSalary: abitrationEvent.averageSalary,
            enventTypeName: '仲裁',
            eventCode: abitrationEvent.eventCode,
            eventOccurrenceTime: abitrationEvent.eventOccurrenceTime, // 仲裁时间
            eventOccurrenceAddr: abitrationEvent.eventOccurrenceAddr, // 仲裁委员会
            eventThrough: abitrationEvent.eventThrough, // 仲裁个人诉求
            fileList: []
          }
        }
        break
      case '4':
        if(data.litigationVo){
          const litigationEvent = data.litigationVo.litigationEvent
          // 获取客户名称
          const cname = await axios.get(`/hrBase/tcustomerinfo/${litigationEvent.unitId}`);
          const temp3 = this.getResultData(cname,'');
          const unitName = temp3 ? temp3.customerName : '';
          businessTypeName = this.getFieldName(businessTypeDict, litigationEvent.businessType, 'id', 'label')
          detail = {
            curloginUser:data.curloginUser,
            curAuditMan:data.curAuditMan,
            type:litigationEvent.type,
            emName: litigationEvent.emName,
            auditStatus: litigationEvent.auditStatus,
            departName: departName,
            customerName: customerName,
            reportDate: litigationEvent.reportDate,
            post: litigationEvent.post,
            empAge: litigationEvent.empAge,
            eventType:litigationEvent.eventType,
            sex: this.getSex(litigationEvent.emIdCard),
            empPhone: litigationEvent.empPhone,
            businessTypeName: businessTypeName, // 业务类型
            socialInsurance: socialOption[litigationEvent.socialStatus],
            insurance: this.getIncurance(data.litigationVo.insurance),
            area: `${litigationEvent.provinceName || ''}${litigationEvent.cityName || ''}${litigationEvent.townName || ''}`,
            contractRange: `${litigationEvent.contractStart || ''}~${litigationEvent.contractEnd || ''}`,
            workLengthWxYear: `${litigationEvent.workLengthWxYear || ''}年${litigationEvent.workLengthWxMonth || ''}月`,
            workLengthCustomerYear: `${litigationEvent.workLengthCustomerYear || ''}年${litigationEvent.workLengthCustomerMonth || ''}月`,
            averageSalary: litigationEvent.averageSalary,
            enventTypeName: '诉讼',
            eventCode: litigationEvent.eventCode,
            eventOccurrenceTime: litigationEvent.eventOccurrenceTime, // 诉讼时间
            plaintiffOrDefendant: litigationEvent.plaintiffOrDefendant, // 原告被告（0原告1被告）
            unitId: unitName, // 客户单位ID（原告为客户）
            eventOccurrenceAddr: litigationEvent.eventOccurrenceAddr, // 法院
            eventThrough: litigationEvent.eventThrough, // 原告诉求
            fileList: []
          }
        }
        break
      case '5':
        if(data.tcomplaintVo){
          const complaintEvent = data.tcomplaintVo.complaintEvent
          businessTypeName = this.getFieldName(businessTypeDict, complaintEvent.businessType, 'id', 'label')
          detail = {
            curloginUser:data.curloginUser,
            curAuditMan:data.curAuditMan,
            type:complaintEvent.type,
            emName: complaintEvent.emName,
            auditStatus: complaintEvent.auditStatus,
            departName: departName,
            customerName: customerName,
            reportDate: complaintEvent.reportDate,
            post: complaintEvent.post,
            empAge: complaintEvent.empAge,
            eventType:complaintEvent.eventType,
            sex: this.getSex(complaintEvent.emIdCard),
            empPhone: complaintEvent.empPhone,
            businessTypeName: businessTypeName, // 业务类型
            socialInsurance: socialOption[complaintEvent.socialStatus],
            insurance: this.getIncurance(data.tcomplaintVo.insurance),
            area: `${complaintEvent.provinceName || ''}${complaintEvent.cityName || ''}${complaintEvent.townName || ''}`,
            contractRange: `${complaintEvent.contractStart || ''}~${complaintEvent.contractEnd || ''}`,
            workLengthWxYear: `${complaintEvent.workLengthWxYear || ''}年${complaintEvent.workLengthWxMonth || ''}月`,
            workLengthCustomerYear: `${complaintEvent.workLengthCustomerYear || ''}年${complaintEvent.workLengthCustomerMonth || ''}月`,
            averageSalary: complaintEvent.averageSalary,
            enventTypeName: '监察投诉',
            eventCode: complaintEvent.eventCode,
            eventOccurrenceTime: complaintEvent.eventOccurrenceTime,
            eventOccurrenceAddr: complaintEvent.eventOccurrenceAddr, // 劳动保障行政部门
            eventResource: complaintEvent.eventResource, // 投诉渠道（0电话/1纸质）
            eventThrough: complaintEvent.eventThrough, // 投诉内容
            fileList: []
          }
        }
        break
    }
    return detail
  }

  // 获取皖信组织
  getWxOrgin(data) {
    let riskBear = null
    let riskBearOrgan = null
    if (data.type === '0') {
      riskBear = data.workInjuryVo.workInjuryEvent.riskBear
      riskBearOrgan = riskBear === '0' ? data.workInjuryVo.workInjuryEvent.riskBearOrgan : data.workInjuryVo.workInjuryEvent.riskBearUnit
    } else if (data.type === '1') {
      riskBear = data.nonWorkVo.nonWorkEvent.riskBear
      riskBearOrgan = riskBear === '0' ? data.nonWorkVo.nonWorkEvent.riskBearUnit : data.nonWorkVo.nonWorkEvent.riskBearUnit
    }
    const _this = this
    if (riskBear === '1') {
      axios.get(`/hrBase/tcustomerinfo/${riskBearOrgan}`).then(res => {
        if (res && Number(res.data.code) === 200) {
          const data = res.data.data
          _this.setState({
            riskBearOrgan: data ? data.customerName : ''
          })
        }
      })
    } else if (riskBear === '0') {
      axios.get(`/admin/organizationinfo/getOrgForCus`).then(res => {
        if (res && Number(res.data.code) === 200) {
          const orginate = res.data.data
          const result = orginate.find(item => String(item.id) === String(riskBearOrgan))
          _this.setState({
            riskBearOrgan: result ? result.organName : ''
          })
        }
      })
    }
  }

  // 突发事件详情
  loadDetail(id) {
    const index = Number(sessionStorage.getItem('index'))
    const total = this.state.total
    const _this = this;
    axios.get(url.emergencyDetail, {
      params: { id: id }
    }).then(res => {
      if (res && Number(res.data.code) === 200) {
        var data = res.data.data
        this.setState({
          detail: data,
          multistate: 'content',
          errorMsg: '',
          index: index,
          hasLast: index > 1,
          hasNext: index < total
        })
        
        sessionStorage.setItem(EMERGENCY_ID, data.eventId);
        // this.getDepart(data.settleDomainId, data.cusId)
        this.getFileList(data.eventId, data.type) // 获取附件
        // this.getWxOrgin(data)
        
        this.goToTop();
      }
    }).catch(function (error) {
      _this.setState({
        errorMsg: error,
        multistate: 'error'
      })
    });
  }

  

  // 当前页面滚动到顶部
  goToTop() {
    const scrollToTop = window.setInterval(function () {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 2);
  }

  // 获取审批流程数据
  loadProcessRecord(id, type) {
    axios.get(url.emergencyProcessRecord, {
      params: { eventType: type, eventId: id }
    }).then(res => {
      if (res && Number(res.data.code) === 200) {
        this.setState({
          processRecord: res.data.data.records
        })
      }
    })
  }

  // 检查有没有上下一条数据
  checkDataHasNextOrLast = async (index, total) => {
    if (Number(index) > Number(total)) {
      sessionStorage.setItem('index', 0);
      this.props.history.push(router.emergency.list.url)
    }
    const _this = this
    const res = await axios.get(url.emergecySideDetail + '?curPage=' + index);
    const data = this.getResultData(res,null);
    if(data && data.total){
      sessionStorage.setItem(EMERGENCY_ID, data.eventId);
      sessionStorage.setItem('index', index);
      let details = await _this.handleData(data);
      if(details){
        // 获取附件
        const fileRes = await axios.get(`/hrEmergency/fileUpload/ossFileUrl/domain/${data.eventId}?type=${data.type}`);
        details.fileList = _this.handleFileList(fileRes);
        const record = await axios.get(url.emergencyProcessRecord, {params: { size:50,eventType: data.type, eventId: data.eventId }});
        const temp6 = _this.getResultData(record,[]);
        const processRecord = temp6 ? temp6.records : [];
        _this.setState({
          detail: details, // 突发事件详情
          fileList: details.fileList,
          enventType:data.type,
          processRecord:processRecord,
          multistate: 'content',
          errorMsg: '',
          index: index,
          hasLast: index > 1,
          hasNext: index < total
        })
        _this.goToTop();
      }else{
        Toast.fail('该事件不存在', 1)
        const _this = this;
        setTimeout(function(){
          window.close();
        },1500);
      }
    }
    
  }

  // 切换当前页面数据
  modifyData(id) {
    // 更新接口数据
    this.loadNetData(id)
  }

  // 当前页面数据切换成上一条或者下一条
  showLastOrNextData(type) {
    switch (type) {
      case 'last':
        if (this.state.hasLast) {
          this.checkDataHasNextOrLast(Number(this.state.index) - 1, Number(this.state.total));
        }
        break;
      case 'next':
        if (this.state.hasNext) {
          this.checkDataHasNextOrLast(Number(this.state.index) + 1, Number(this.state.total));
        }
        break;
      default:
    }
  }

  // 上传审核结果后处理下一条数据，如果没有下一条数据则处理上一条数据，如果都没有数据则退出
  showNextData(tag) {
    const _this = this
    this.setState({
      multistate: 'load', // 页面状态
      errorMsg: ''
    });
    setTimeout(() => {
      if (tag === 'last') {
        _this.showLastOrNextData('last');
      } else if (tag === 'next') {
        _this.showLastOrNextData('next');
      }
    }, 800);
  }

  wxConfig(config) {
    window.wx.config({
      beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.corpid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳
      nonceStr: config.noncestr, // 必填，生成签名的随机串
      signature: config.sign,// 必填，签名
      jsApiList: ['previewImage'] // 必填，需要使用的JS接口列表
    });
  }

  // 转为重大事件
  toMajorButton = () => {
    this.setState({
      toMajorShow: true
    });
  }

  // 退回
  backButton = () => {
    this.setState({
      modelType: 0,
      modelTitle: '退回',
      formLabel: '请填写退回原因',
      enableButton: false,
      rejectModalVisible: true
    })
  }
  // 待补充
  supplementButton = () => {
    this.setState({
      modelType: 1,
      modelTitle: '待补充',
      formLabel: '请填写待补充内容',
      enableButton: false,
      rejectModalVisible: true
    })
  }
  // 审核通过
  accessButton = () => {
    this.setState({
      modelType: 2,
      modelTitle: '审核通过',
      formLabel: '请填写审批意见',
      enableButton: false,
      rejectModalVisible: true
    })
  }

  // 弹窗确定按钮
  handleRejectOk = e => {
    const modelType = this.state.modelType;
    const inputApprovalOpinion = this.state.inputApprovalOpinion;
    let errorMsg = ''
    let backUrl = ''
    switch (modelType) {
      case 0:
        errorMsg = '退回原因不能为空'
        backUrl = `${url.emergecyBackUrl}?id=${this.state.id}&info=${inputApprovalOpinion}`;
        break
      case 1:
        errorMsg = '待补充内容不能为空'
        backUrl = `${url.emergecySuportUrl}?id=${this.state.id}&info=${inputApprovalOpinion}`;
        break
      case 2:
        errorMsg = '审批意见不能为空'
        backUrl = `${url.emergecyPassUrl}?id=${this.state.id}&auditContent=${inputApprovalOpinion}`;
        break
    }
    if (!inputApprovalOpinion) {
      Toast.fail(errorMsg, 1)
      return
    }

    this.submitAuditResult(backUrl, '操作成功，即将跳转下一条')
    this.setState({
      rejectModalVisible: false,
    });
  };

  handleToMajor = () => {
    const _this = this;
    Toast.loading('提交中...', 0);
    axios.post(url.emergecyMajorUrl + _this.state.id)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          this.handleAfterSubmit(res.data.msg)
          setTimeout(() => {
            this.checkDataHasNextOrLast(Number(this.state.index) + 1, this.state.total);
          }, 1100)

          try {
            // 清空缓存后回到列表页，列表页会重新请求数据
            var token = sessionStorage.getItem(TOKEN);
            sessionStorage.removeItem(token); // 清空缓存
          } catch (e) {

          }
        } else {
          Toast.offline(res.data.msg, 2);
        }
        this.setState({
          enableButton: true,
          toMajorShow: false
        });
      })
      .catch(function (error) {
        Toast.offline(error, 2);
        _this.setState({
          enableButton: true
        });
      });
  }

  // 弹窗取消按钮
  handleRejectCancel = e => {
    this.setState({
      rejectModalVisible: false, // 这两个按钮可以同时可用
      bigPicModalVisible: false,
      enableButton: true
    });
  };

  handleAfterSubmit(msg) {
    debugger
    let index = Number(this.state.index)
    let total = Number(this.state.total)
    // 无数据返回列表
    if (total - 1 == 0) {
      Toast.success('操作成功', 1);
      sessionStorage.setItem('index', 0);
      this.props.history.push(router.emergency.list.url)
      return
    } else {
      Toast.success(msg, 1);
    }
    total = (total - 1 > 0) ? (total - 1) : 0;
    index = (index > total) ? total : index;
    // 总数减一条
    this.setState({
      index: index,
      total: total,
      hasLast: index > 0,
      hasNext: index < total
    })
  }
  // 上传审核结果
  submitAuditResult(submitUrl, msg) {
    let index = Number(this.state.index)
    let total = Number(this.state.total)

    const _this = this;
    Toast.loading('提交中...', 0);
    axios.post(submitUrl)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          this.handleAfterSubmit(msg)
          setTimeout(() => {
            this.checkDataHasNextOrLast(Number(this.state.index) + 1, this.state.total);
          }, 1100)

          try {
            // 清空缓存后回到列表页，列表页会重新请求数据
            var token = sessionStorage.getItem(TOKEN);
            sessionStorage.removeItem(token); // 清空缓存
          } catch (e) {

          }
        } else {
          Toast.offline(res.data.msg, 2);
        }
        this.setState({
          enableButton: true
        });
      })
      .catch(function (error) {
        Toast.offline(error, 2);
        _this.setState({
          enableButton: true
        });
      });
  }

  // 退回理由输入框监听
  theModelTextareaChangeListener(text) {
    this.setState({
      inputApprovalOpinion: text
    })
  }

  // 预览大图
  previewImage(url, urls) {
    const reg = RegExp(/Windows|Macintosh/i);
    if (navigator.userAgent.match(reg)) {
      this.setState({
        bigPicModalVisible: true,
        bigPicModalImgUrl: url
      })
    } else {
      this.setState({
        bigPicModalVisible: true,
        bigPicModalImgUrl: url
      })
    }
  }

  closeToMajor = () => {
    this.setState({
      toMajorShow: false
    });
  }

  getIncurance(arr) {
    if (!arr) return '未购买'
    const res = arr.map(item => item.insuranceTypeName)
    return res.join(',')
  }

  // 获取附件
  getFileList(id, type) {
    if (id === undefined) {
      _this.setState({
        fileList: []
      })
      return
    }
    const _this = this
    axios.get(`/hrEmergency/fileUpload/ossFileUrl/domain/${id}?type=${type}`).then(res => {
      if (res && Number(res.data.code) === 200) {
        _this.setState({
          fileList: res.data.data
        })
      }
    })
  }

  // 获取性别
  getSex(idCard) {
    return parseInt(idCard.substr(16, 1)) % 2 == 1 ? '男' : '女'
  }

  getFieldName(list, value, field, name) {
    const res = Array.isArray(list) ? list.find(item => item[field] == value) : '';
    return res ? res[name] : '-';
  }

  

  getCustomer(id){
    axios.get(`/hrBase/tcustomerinfo/${id}`).then(res => {
      if (res && Number(res.data.code) === 200) {
        const data = res.data.data
        // data.customerName
      }
    })
  }

  renderRisk(type, detail) {
    if (String(type) === '0' || String(type) === '1') {
      let name = ''
      let one = ''
      if (detail.riskBear === '0') {
        name = '具体部门'
        one = '皖信'
      } else {
        name = '具体单位'
        one = '客户单位'
      }
      return (<>
        <KVMap title="风险承担" className="kv_item">{(detail) ? one : ''}</KVMap>
        <KVMap title={name} className="kv_item">{detail ? detail.riskBearOrgan : ''}</KVMap>
      </>)
    } else {
      return
    }
  }

  renderWorkLength(type, detail) {
    if (Number(type) > 1) {
      return (<>
        <KVMap title="皖信工龄" className="kv_item">{detail ? detail.workLengthWxYear : ''}</KVMap>
        <KVMap title="客户单位工龄" className="kv_item">{detail ? detail.workLengthCustomerYear : ''}</KVMap>
        <KVMap title="前12个月平均工资" type="fix" className="kv_item">{detail ? detail.averageSalary : ''}</KVMap>
      </>)
    } else {
      return
    }
  }
  // 获取默认值
  getValue(val, str1, str2) {
    switch (val) {
      case '0':
        return str1
        break
      case '1':
        return str2
        break
      default:
        return
    }
  }
  // 获取字典值
  getDictVal(list, val, field, name) {
    const res = list.find(item => item[field] == val)
    return res ? res[name] : ''
  }

  renderThings(type, data) {
    switch (type) {
      case '0':
      case '1':
        if(type === '0' && data.eventType === '5'){
          return (<>
            <KVMap title="事件发生时间" className="kv_item">{(data) ? data.eventOccurrenceTime.substring(0, 10) : ''}</KVMap>
            <KVMap title="事件发生地点" className="kv_item">{(data) ? data.eventOccurrenceAddr : ''}</KVMap>
            <KVMap title="车辆归属单位" className="kv_item">{(data) ? data.carOfUnit : ''}</KVMap>
            <KVMap title="交强险赔付额" className="kv_item">{(data) ? data.payStrongInsurance : ''}</KVMap>
            <KVMap title="车险购买单位" className="kv_item">{(data) ? data.carInsuranceUnit : ''}</KVMap>
            <KVMap title="第三责任险保额" className="kv_item">{(data) ? data.thirdPartyLiabilityInsurance : ''}</KVMap>
            <KVMap title="伤者户口归属" className="kv_item">{(data) ? data.woundedHukouAttribution : ''}</KVMap>
            <KVMap title="受伤部位" className="kv_item">{(data) ? data.injuredArea : ''}</KVMap>
            <KVMap title="受伤程度" className="kv_item">{(data) ? data.injuredDegree : ''}</KVMap>
            <KVMap title="年龄" className="kv_item">{(data) ? data.age : ''}</KVMap>
            <KVMap title="第三人身份号" className="kv_item">{(data) ? data.thirdPersonIdCard : ''}</KVMap>
            <KVMap title="伤者家庭情况" className="kv_item">{(data) ? data.injuredFamilyStatus : ''}</KVMap>
            <KVMap title="治疗费（预判）" className="kv_item">{(data) ? data.treatmentCosts : ''}</KVMap>
            <KVMap title="事情发生经过" className="kv_item">{(data) ? data.eventThrough : ''}</KVMap>
          </>)
        }else{
          return (<>
            <KVMap title="事件发生时间" className="kv_item">{(data) ? data.eventOccurrenceTime.substring(0, 10) : ''}</KVMap>
            <KVMap title="事件发生地点" className="kv_item">{(data) ? data.eventOccurrenceAddr : ''}</KVMap>
            <KVMap title="伤残等级" className="kv_item">{(data) ? data.disabilityLevel : ''}</KVMap>
            <KVMap title="伤害部位" className="kv_item">{(data) ? data.injuryPosition : ''}</KVMap>
            <KVMap title="是否治疗" className="kv_item">{(data) ? this.getValue(data.isCuring, '是', '否') : ''}</KVMap>
            <KVMap title="治疗类型" className="kv_item">{(data) ? this.getDictVal(this.state.treatmentDict, data.curingType, 'value', 'label') : ''}</KVMap>
            <KVMap title="就诊医院" className="kv_item">{(data) ? data.curingHospital : ''}</KVMap>
            <KVMap title="事情发生经过" className="kv_item">{(data) ? data.eventThrough : ''}</KVMap>
          </>)
        }
        break
      case '2':
        return (<>
          <KVMap title="事件发生时间" className="kv_item">{(data) ? data.eventOccurrenceTime.substring(0, 10) : ''}</KVMap>
          <KVMap title="事件类别" className="kv_item">{(data) ? data.eventTypeName : ''}</KVMap>
          <KVMap title="事件说明" className="kv_item">{(data) ? data.eventThrough : ''}</KVMap>
        </>)
        break
      case '3':
        return (<>
          <KVMap title="仲裁时间" className="kv_item">{(data) ? data.eventOccurrenceTime.substring(0, 10) : ''}</KVMap>
          <KVMap title="仲裁委员会" className="kv_item">{(data) ? data.eventOccurrenceAddr : ''}</KVMap>
          <KVMap title="仲裁个人诉求" className="kv_item">{(data) ? data.eventThrough : ''}</KVMap>
        </>)
        break
      case '4':
        return (<>
          <KVMap title="原告/被告" className="kv_item">{(data) ? this.getValue(data.plaintiffOrDefendant, '原告', '被告') : ''}</KVMap>
          <KVMap title="诉讼时间" className="kv_item">{(data) ? data.eventOccurrenceTime.substring(0, 10) : ''}</KVMap>
          <KVMap title="客户单位" className="kv_item">{(data) ? data.unitId : ''}</KVMap>
          <KVMap title="法院" className="kv_item">{(data) ? data.eventOccurrenceAddr : ''}</KVMap>
          <KVMap title="原告诉求" className="kv_item">{(data) ? data.eventThrough : ''}</KVMap>
        </>)
        break
      case '5':
        return (<>
          <KVMap title="发生时间" className="kv_item">{(data) ? data.eventOccurrenceTime.substring(0, 10) : ''}</KVMap>
          <KVMap title="劳动保障部门" className="kv_item">{(data) ? data.eventOccurrenceAddr : ''}</KVMap>
          <KVMap title="投诉渠道" className="kv_item">{(data) ? this.getValue(data.eventResource, '电话', '纸质') : ''}</KVMap>
          <KVMap title="投诉内容" className="kv_item">{(data) ? data.eventThrough : ''}</KVMap>
        </>)
        break
      default:
        return
    }
  }

  renderOpinion(type, data) {
    if (type < 3) {
      return (<ItemContainer>
        <KVMap title="赔付方案" className="kv_item">{(data) ? data.payPlan : ''}</KVMap>
        <KVMap title="客户意见" className="kv_item">{(data) ? data.customerOpinion : ''}</KVMap>
      </ItemContainer>)
    } else {
      return (<></>)
    }
  }

  renderShowBtn(type,data){
    const emergencyType = sessionStorage.getItem(SHOW_EMERGENCY_TYPE);
    if(type<=2){
      // if( ((data.type === '0' && data.curloginUser === data.curAuditMan) || (data.type === '1')) && emergencyType !== '1'){
      if( emergencyType === '0' ){
        return (<>
          <Button size="small" enable={this.state.enableButton} type="red" onClick={this.backButton} style={{ flexGrow: 2, marginRight: '3px' }}>退回</Button>
          <Button size="small" enable={this.state.enableButton} type="green" onClick={this.supplementButton} style={{ flexGrow: 2, marginRight: '3px' }}>待补充</Button>
          <Button size="small" enable={this.state.enableButton} type="green" onClick={this.accessButton} style={{ flexGrow: 2, marginRight: '3px' }}>审核通过</Button>
        </>)
      }
    }else{
      if(data.auditStatus === '1' && emergencyType !== '1'){
        return (<>
          <Button size="small" enable={this.state.enableButton} type="red" onClick={this.backButton} style={{ flexGrow: 2, marginRight: '3px' }}>退回</Button>
          <Button size="small" enable={this.state.enableButton} type="green" onClick={this.supplementButton} style={{ flexGrow: 2, marginRight: '3px' }}>待补充</Button>
          <Button size="small" enable={this.state.enableButton} type="green" onClick={this.accessButton} style={{ flexGrow: 2, marginRight: '3px' }}>审核通过</Button>
        </>)
      }
    }
  }

  render() {
    // 分页列表传过来的数据
    const data = this.state.detail;
    const advanceLetterTempUrls = [];
    const _this = this;
    const fileListView = ((this.state.fileList && this.state.fileList.length > 0) ?
      (this.state.fileList.map(function (item) {
        var reg = (/(.jpg|.jpeg|.png|.gif|.bmp)/i);
        var pdf = (/(.pdf)|(.doc(x)?)|(.docx)|(.ppt(x)?)|(.xls(x)?)|(.csv)/i);
        var pc = /Windows|Macintosh/i;
        
        // if (reg.test(item.attaName)) { // 过滤掉非图片
        //   advanceLetterTempUrls.push(item.attaSrc);
        //   return (
        //   <a key={item.id}>
        //     <img onClick={() => {
        //     _this.previewImage(item.attaSrc, advanceLetterTempUrls)
        //   }} style={{ width: "46px", height: "46px", padding: "0 10px 3px 0", float: 'left' }} src={item.attaSrc} alt="附件" /></a>)
        // } else if (pdf.test(item.attaName)) {
        //   let enclosureUrl = item.attaSrc
        //   if (pc.test(navigator.userAgent) && !pdf.test(item.attaName)) { // 如果是pc且非pdf格式，使用office365在线预览
        //     enclosureUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(item.attaSrc)}`;
        //   }
        //   return <a href={enclosureUrl} key={item.id} target="_blank" rel="noopener noreferrer" style={{ float: 'left' }}><div className='contract-enclosure' >查看<br />附件</div></a>
        // } else {
        //   return <a href={item.attaSrc} key={item.id} target="_blank" rel="noopener noreferrer" style={{ float: 'left' }}><div className='contract-enclosure' >查看<br />附件</div></a>
        // }
        
        if (reg.test(item.attaName)) { // 过滤掉非图片
          advanceLetterTempUrls.push(item.attaSrc);
          return (
          <a key={item.id}
            href="javascript:;"
            style={{display:'block'}}
            onClick={() => {
            _this.previewImage(item.attaSrc, advanceLetterTempUrls)}} >
            { item.attaName }
          </a>
          )
        } else if (pdf.test(item.attaName)) {
          let enclosureUrl = item.attaSrc
          if (pc.test(navigator.userAgent) && !pdf.test(item.attaName)) { // 如果是pc且非pdf格式，使用office365在线预览
            enclosureUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(item.attaSrc)}`;
          }
          return <a href={enclosureUrl} key={item.id} target="_blank" rel="noopener noreferrer" style={{display:'block'}}>{ item.attaName }</a>
        } else {
          return <a href={item.attaSrc} key={item.id} target="_blank" rel="noopener noreferrer" style={{display:'block'}}>{ item.attaName }</a>
        }
      }))
      : <div>-</div>)

    // 判断是否需要显示底部操作按钮
    let bottomOperateShow = false
    // if(data && (data.auditStatus === '1' || data.auditStatus === '8') &&  data.curloginUser === data.curAuditMan){
    if(data && (data.auditStatus === '1' || data.auditStatus === '8') && sessionStorage.getItem(SHOW_EMERGENCY_TYPE) !== '1'){
      bottomOperateShow = true
    }
    return data ? (
      <Multistate type={this.state.multistate} msg={this.state.errorMsg}>
        <div className={this.props.className}>
          <div className={`ap-detail-tab ${(Number(data.auditStatus) === 1) ? '' : 'hidden'}`}>
            <div>共{this.state.total}条待处理</div>
            <div className="button">
              <div onClick={() => this.showLastOrNextData('last')}>
                <img src={(this.state.hasLast) ? showLast : unShowLast} alt="上一条" />
              </div>
              <div onClick={() => this.showLastOrNextData('next')}>
                <img src={(this.state.hasNext) ? showNext : unShowNext} alt="下一条" />
              </div>
            </div>
          </div>

          <ItemContainer>
            <div className='titleWrap' style={{ marginBottom: '10px' }}>
              <div className="titleText">{(data) ? data.emName : ''}的{data.enventTypeName}申请</div>
              <Tag type={2} status={(data) ? data.auditStatus : ''}>审批中</Tag>
            </div>
            <KVMap title="提交时间" ><Time type="LDH">{(data) ? data.reportDate : ''}</Time></KVMap>
            <KVMap title="客户单位" className="kv_item">{(data) ? data.customerName : ''}</KVMap>
            <KVMap title="结算主体" className="kv_item">{(data) ? data.departName : ''}</KVMap>
            {this.renderRisk(this.state.enventType, data)}
          </ItemContainer>
          <ItemContainer>
            <KVMap title="姓名" className="kv_item">{data ? data.emName : ''}</KVMap>
            <div className="line" />
            <KVMap title="性别" className="kv_item">{data ? data.sex : ''}</KVMap>
            <div className="line" />
            <KVMap title="年龄" className="kv_item">{data ? data.empAge : ''}</KVMap>
            <div className="line" />
            <KVMap title="岗位" className="kv_item">{data ? data.post : ''}</KVMap>
            <div className="line" />
            <KVMap title="联系方式" className="kv_item">{data ? data.empPhone : ''}</KVMap>
            <div className="line" />
            <KVMap title="业务类型" className="kv_item">{data ? data.businessTypeName : ''}</KVMap>
            <div className="line" />
            <KVMap title="社会保险" className="kv_item">{data ? data.socialInsurance : ''}</KVMap>
            <div className="line" />
            <KVMap title="商业保险" className="kv_item">{data ? data.insurance : ''}</KVMap>
            <div className="line" />
            <KVMap title="社保参保地" className="kv_item">{data ? data.area : ''}</KVMap>
            <div className="line" />
            <KVMap title="劳动合同期限" className="kv_item">{data ? data.contractRange : ''}</KVMap>
            {this.renderWorkLength(this.state.enventType, data)}
          </ItemContainer>
          <ItemContainer className={(this.state.enventType === '0' && data.eventType === '5')? 'lineRow' : ''}>
            <KVMap title="事件类型" className="kv_item">{(data) ? data.enventTypeName : ''}</KVMap>
            <KVMap title="事件编码" className="kv_item">{(data) ? data.eventCode : ''}</KVMap>
            {this.renderThings(this.state.enventType, data)}
          </ItemContainer>
          {this.renderOpinion(this.state.enventType, data)}
          <ItemContainer>
            <KVMap title="材料附件" className="kv_item">
              {fileListView}
            </KVMap>
          </ItemContainer>

          <ItemContainer>
            <div className="title" style={{ marginBottom: "16px" }}>审批流程</div>
            <div className="vertical-layout">
              <div className="horizontal-layout step-container">
                <div className={`step-line ${(this.state.processRecord && this.state.processRecord.length) ? '' : 'hidden'}`} >

                </div>
                <div className="step-dot-container">
                  <StepIcon type="primary" />
                </div>
                <div className="vertical-layout step-item-container">
                  {
                    (this.state.processRecord && this.state.processRecord.length) ?
                      this.state.processRecord.map(function (item, index) {
                        return (<EmergecyProcess info={item} index={index} key={item.id} />)
                      }) : null
                  }
                </div>
              </div>

            </div>
          </ItemContainer>

          <div className={`ap-detail-apply horizontal-layout ${bottomOperateShow ? '' : 'hidden'}`}>
            {this.renderShowBtn(this.state.enventType,data)}
            {(Number(this.state.enventType) < 3 && data.type !== '1' && (data.auditStatus === '1'||data.auditStatus === '8') && data.curloginUser === data.curAuditMan) ? (<Button size="small" enable={this.state.enableButton} type="yellow" onClick={this.toMajorButton} style={{ flexGrow: 2 }}>转为重大事件</Button>) : ''}
          </div>

        </div>

        {/* 退回弹窗 */}
        <Modal
          title={this.state.modelTitle}
          className="ap-modal"
          transparent
          visible={this.state.rejectModalVisible}
          onOk={this.handleRejectOk}
          onClose={this.handleRejectCancel}
          maskClosable={true}
          footer={[{ text: '关闭', onPress: () => { this.handleRejectCancel(); } }, { text: '确定', onPress: () => { this.handleRejectOk(); } }]}
        >
          <TextareaItem
            placeholder={this.state.formLabel}
            count={50}
            rows={3}
            onChange={(val) => this.theModelTextareaChangeListener(val)}
          />
        </Modal>
        {/* 图片显示 */}
        <Modal
          onClose={this.handleRejectCancel}
          className="ap-modal"
          closable={true}
          transparent={true}
          visible={this.state.bigPicModalVisible}
          maskClosable={true}
          footer={[]}
        >
          <img style={{ width: "90%", height: "90%" }} src={this.state.bigPicModalImgUrl} alt="" />
        </Modal>
        
        <Modal
          title='转为重大事件'
          className="ap-modal"
          transparent={true}
          visible={this.state.toMajorShow}
          maskClosable={true}
          onClose={this.closeToMajor}
          footer={[{ text: '关闭', onPress: () => { this.closeToMajor(); } }, { text: '确定', onPress: () => { this.handleToMajor(); } }]}
        >
          <p>确定转为重大事件，提交督查办审核吗？</p>
        </Modal>

      </Multistate>
    ) : null
  }
}

export default withActivation(Detail)
