import React, { Component } from 'react';
import axios from 'axios';
import url from '../../config/url-config.js';
import router from '../../config/router-config.js';
import { Tabs, ListView, PullToRefresh, Badge } from 'antd-mobile';
import ContractItem from './components/ContractItem';
import Multistate from '../../components/Multistate';
import { withActivation } from 'react-activation'

import { SHOW_ADVANCE_TYPE, TOKEN } from '../../config/constant';
import './style.css';

const dataSource = new ListView.DataSource({
  rowHasChanged: (row1, row2) => row1 !== row2,
});

// tab 用于无数据显示的具体谁无数据
const tabNames = ['待处理', '已处理', '抄送我', '我发起']

class ContractList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 1,// 当前请求数据的页码
      noMoreData: false,// 没有更多数据了，为true时不触发加载更多
      tabSelectIndex: 0,// 当前选中的tab下标
      listData: [], // 列表数据
      isLoading: false,
      multistate: 'load',
      errorMsg: '',
      listHeight: document.documentElement.clientHeight - 43.5,
      dictionaries: {
        businessType: {}
      },
      tabs: [
        { title: "待处理", key: 0 },
        { title: "已处理", key: 1 },
        { title: "抄送我", key: 2 },
        { title: "我发起的", key: 3 },
      ]
    };
  }

  componentDidActivate() { // 缓存激活
    document.title = router.contract.list.title;
    var token = sessionStorage.getItem(TOKEN);
    if (!sessionStorage.getItem(token)) { // 详情中对数据操作后，返回该列表是刷新列表数据
      this.refreshData(0);
    }
  }

  componentDidMount() {
    document.title = router.contract.list.title;
    this.cacheData();
    // 正常请求数据
    this.refreshData(0);
  }

  // 缓存数据
  cacheData() {
    // 正常请求数据
    this.refreshData(0);
    axios.get(url.bussinessType)
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          this.setState({
            dictionaries: {
              businessType: this.filterMultiDictText(res.data.data)
            }
          })
        }
      })
      .catch(e => {
        console.log('get exception:',e);
      });
  }

  // 请求数据
  getData(tabSelectIndex, page) {

    var tempUrl = null;
    switch (tabSelectIndex) {
      case 0:
        sessionStorage.setItem(SHOW_ADVANCE_TYPE, 3);
        tempUrl = url.tcustomercontractWxAuditPage;
        break;
      case 1:
        sessionStorage.setItem(SHOW_ADVANCE_TYPE, 0);
        tempUrl = url.tcustomercontractWxHandledPage;
        break;
      case 2:
        sessionStorage.setItem(SHOW_ADVANCE_TYPE, 4);
        tempUrl = url.tcustomercontractWxCopyPage;
        break;
      case 3:
        sessionStorage.setItem(SHOW_ADVANCE_TYPE, 0);
        tempUrl = url.tcustomercontractWxCreateUser;
        break;
      default:
        return null
    }

    const _this = this;

    axios.get(tempUrl, {
      params: {
        current: page,
        size: 10
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          this.handleData(res.data.data, page, tabSelectIndex);
        }
      })
      .catch(function (error) {
        _this.setState({
          isLoading: false,
          multistate: 'error',
          errorMsg: error
        });
      });
  }

  // 字典数据转换
  filterMultiDictText(optionArray) {
    var result = {};
    if (optionArray && Array.isArray(optionArray)) {
      optionArray.forEach(item => {
        result[item.id] = item.label;
      });
    }
    return result;
  }

  // 处理数据
  handleData(tempData, page, tabSelectIndex) {
    var templistData = []
    if (Number(page) === 1) {
      templistData = tempData.records || [];
    } else {
      templistData = this.state.listData.concat(tempData.records)
    }

    if (tabSelectIndex === 0) {
      this.setState({
        tabs: [
          { title: <Badge text={tempData && (tempData.total || 0)}>待处理</Badge>, key: 0 },
          { title: "已处理", key: 1 },
          { title: "抄送我", key: 2 },
          { title: "我发起的", key: 3 },
        ]
      })
    }

    this.setState({
      noMoreData: tempData.current >= tempData.pages, // 当前页等于总页数，数据已全部请求
      pageIndex: tempData.current,
      listData: templistData,
      isLoading: false,
      multistate: (templistData.length === 0 && Number(page) === 1) ? 'empty' : 'contnent',
      errorMsg: (templistData.length === 0 && Number(page) === 1) ? `暂无${tabNames[tabSelectIndex]}的申请` : ''
    });
  }

  // 刷新数据
  refreshData(tabSelectIndex) {
    // 重置数据并重新请求
    this.setState({
      pageIndex: 1,
      noMoreData: false
    })

    this.getData(tabSelectIndex, 1);
  }

  // 加载数据
  loadMoreData() {

    var num = this.state.pageIndex;
    num++
    this.getData(this.state.tabSelectIndex, num);
  }

  // 加载更多触发
  onEndReached = (event) => {
    if (this.state.isLoading && this.state.noMoreData) {
      return;
    }

    this.setState({ isLoading: true });
    this.loadMoreData();
  }

  // 下拉刷新触发
  onRefresh = () => {
    this.setState({ isLoading: true });
    this.refreshData(this.state.tabSelectIndex);
  };

  multistateRefresh = () => {

    this.setState({
      multistate: 'load',
      errorMsg: ''
    });
    this.refreshData(this.state.tabSelectIndex);
  }

  // tab 切换
  tabSelectCallback(tab, index) {
    this.setState({
      tabSelectIndex: index,
      multistate: 'load',
      errorMsg: ''
    })
    this.refreshData(index);
  }

  render() {

    var tabIndex = this.state.tabSelectIndex;

    const row = (data, sectionID, rowID, highlightRow) => {
      return (<ContractItem dictionaries={this.state.dictionaries} key={rowID}
        index={rowID} data={data} myAdvanceType={tabIndex} style={{ marginTop: (Number(rowID) === 0 ? "10px" : "0px") }} />);
    };

    return (<div className="contract" >
      <Tabs tabs={this.state.tabs}
        ref={el => this.tabs = el}
        page={tabIndex}
        swipeable={false}
        prerenderingSiblingsNumber={0}
        onChange={(tab, index) => this.tabSelectCallback(tab, index)}
        tabBarBackgroundColor="#ffffff" // 背景色
        tabBarActiveTextColor="#4378BE" // 激活文字颜色
        tabBarInactiveTextColor="#323232" // 未激活文字颜色
        tabBarUnderlineStyle={{ width: '18%', marginLeft: '3.5%', border: '1px #4378BE solid' }} // tabBar下划线样式
        tabBarTextStyle={{ fontSize: '15px' }} // tabBar文字样式
      >

        <Multistate type={this.state.multistate} msg={this.state.errorMsg} ref={el => this.multistate = el} refresh={() => this.multistateRefresh()}>
          <ListView
            ref={el => this.lv = el}
            initialListSize={this.state.listData.length}
            dataSource={dataSource.cloneWithRows(this.state.listData)}
            renderFooter={() => (<div style={{ padding: 10, textAlign: 'center' }}>
              {this.state.noMoreData ? '没有更多数据了' : this.state.isLoading ? '加载中...' : '请求完成'}
            </div>)}
            renderRow={row}
            style={{
              height: this.state.listHeight,
              overflow: 'auto'
            }}
            contentContainerStyle={{ height: "100%" }}
            scrollRenderAheadDistance={500}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={500}
            pullToRefresh={<PullToRefresh
              refreshing={this.state.isLoading}
              onRefresh={this.onRefresh}
            />}
          />
        </Multistate>
      </Tabs>
    </div >);
  }
}

export default withActivation(ContractList);
