import React, { Component } from 'react';
import './style.css';
import KVMap from './KVMap';
import Time from './Time';
import { withRouter } from 'react-router-dom';
import router from '../config/router-config.js';

import { EMERGENCY_ID, TOKEN } from '../config/constant';


class EmergencyItem extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  saveData() {
    var token = sessionStorage.getItem(TOKEN);
    sessionStorage.setItem(token, this.props.data.id); // 清空缓存
    sessionStorage.setItem(EMERGENCY_ID, this.props.data.id);
    sessionStorage.setItem('index', Number(this.props.index) + 1);
    this.props.history.push(router.emergency.detail.url)
  }

  getFieldName(list, value, field, name) {
    const res = list.find(item => String(item[field]) === String(value))
    return res ? res[name] : '-'
  }

  render() {
    const data = this.props.data;
    const departAll = this.props.departAll;
    const res = departAll[data.emSettleId]
    const departName = res ? res['departName'] : '-';
    const customerName = res ? res['customerName'] : '-';
    const dictData = this.props.dictData;
    if (data == null) {
      return null;
    }
    const auditStatusOption = ['待提交', '待审核', '材料待补充', '已退回', '待办理', '办理中', '待办结', '已办结', '审核中'];
    let eventTypeName = ''
    let businessTypeName = ''
    switch (data.emergencyType) {
      case '0':
        eventTypeName = this.getFieldName(dictData.injuryDict, data.eventType, 'value', 'label')
        businessTypeName = this.getFieldName(dictData.businessTypeDict, data.businessType, 'id', 'label')
        return (
          <div className={this.props.className}>
            <div className="normal-container advancel-container" onClick={(e) => this.saveData()}>
              <div className="horizontal-layout advance-title">
                <div className="advance-user-name">{data.emName}的工伤事件申请</div>
                <Time className="advance-time" type="fromNow">{data.reportDate}</Time>
              </div>
              <KVMap title="客户单位">{customerName}</KVMap>
              <KVMap title="结算主体">{departName}</KVMap>
              <KVMap title="风险承担方">{String(data.riskBear) === '1' ? '客户单位' : '皖信'}</KVMap>
              <KVMap title="事件类别">{eventTypeName}</KVMap>
              <KVMap title="申请时间">{data.reportDate.substring(0, 10)}</KVMap>
              <KVMap title="审批状态">{auditStatusOption[data.auditStatus]}</KVMap>
            </div>
          </div>
        );
        break
      case '1':
        eventTypeName = this.getFieldName(dictData.nonworkDict, data.eventType, 'value', 'label')
        businessTypeName = this.getFieldName(dictData.businessTypeDict, data.businessType, 'id', 'label')
        return (
          <div className={this.props.className}>
            <div className="normal-container advancel-container" onClick={(e) => this.saveData()}>
              <div className="horizontal-layout advance-title">
                <div className="advance-user-name">{data.emName}的非因工事件申请</div>
                <Time className="advance-time" type="fromNow">{data.reportDate}</Time>
              </div>
              <KVMap title="客户单位">{customerName}</KVMap>
              <KVMap title="结算主体">{departName}</KVMap>
              <KVMap title="风险承担方">{String(data.riskBear) === '1' ? '客户单位' : '皖信'}</KVMap>
              <KVMap title="事件类别">{eventTypeName}</KVMap>
              <KVMap title="申请时间">{data.reportDate.substring(0, 10)}</KVMap>
              <KVMap title="审批状态">{auditStatusOption[data.auditStatus]}</KVMap>
            </div>
          </div>
        );
        break
      case '2':
        eventTypeName = this.getFieldName(dictData.retiredDict, data.eventType, 'value', 'label')
        businessTypeName = this.getFieldName(dictData.businessTypeDict, data.businessType, 'id', 'label')
        return (
          <div className={this.props.className}>
            <div className="normal-container advancel-container" onClick={(e) => this.saveData()}>
              <div className="horizontal-layout advance-title">
                <div className="advance-user-name">{data.emName}的退工事件申请</div>
                <Time className="advance-time" type="fromNow">{data.reportDate}</Time>
              </div>
              <KVMap title="岗位">{data.post}</KVMap>
              <KVMap title="客户单位">{customerName}</KVMap>
              <KVMap title="结算主体">{departName}</KVMap>
              <KVMap title="事件类别">{eventTypeName}</KVMap>
              <KVMap title="劳动合同期限">{data.contractStart ? `${data.contractStart} ~ ${data.contractEnd}` : '-'}</KVMap>
              <KVMap title="申请时间">{data.reportDate.substring(0, 10)}</KVMap>
              <KVMap title="审批状态">{auditStatusOption[data.auditStatus]}</KVMap>
            </div>
          </div>
        );
        break
      case '3':
        businessTypeName = this.getFieldName(dictData.businessTypeDict, data.businessType, 'id', 'label')
        return (
          <div className={this.props.className}>
            <div className="normal-container advancel-container" onClick={(e) => this.saveData()}>
              <div className="horizontal-layout advance-title">
                <div className="advance-user-name">{data.emName}的仲裁事件申请</div>
                <Time className="advance-time" type="fromNow">{data.reportDate}</Time>
              </div>
              <KVMap title="客户单位">{customerName}</KVMap>
              <KVMap title="结算主体">{departName}</KVMap>
              <KVMap title="业务类型">{businessTypeName}</KVMap>
              <KVMap title="仲裁委员会">{data.eventOccurrenceAddr}</KVMap>
              <KVMap title="申请时间">{data.reportDate.substring(0, 10)}</KVMap>
              <KVMap title="审批状态">{auditStatusOption[data.auditStatus]}</KVMap>
            </div>
          </div>
        );
        break
      case '4':
        businessTypeName = this.getFieldName(dictData.businessTypeDict, data.businessType, 'id', 'label')
        return (
          <div className={this.props.className}>
            <div className="normal-container advancel-container" onClick={(e) => this.saveData()}>
              <div className="horizontal-layout advance-title">
                <div className="advance-user-name">{data.emName}的诉讼事件申请</div>
                <Time className="advance-time" type="fromNow">{data.reportDate}</Time>
              </div>
              <KVMap title="客户单位">{customerName}</KVMap>
              <KVMap title="结算主体">{departName}</KVMap>
              <KVMap title="业务类型">{businessTypeName}</KVMap>
              <KVMap title="法院">{data.eventOccurrenceAddr}</KVMap>
              <KVMap title="申请时间">{data.reportDate.substring(0, 10)}</KVMap>
              <KVMap title="审批状态">{auditStatusOption[data.auditStatus]}</KVMap>
            </div>
          </div>
        );
        break
      case '5':
        businessTypeName = this.getFieldName(dictData.businessTypeDict, data.businessType, 'id', 'label')
        return (
          <div className={this.props.className}>
            <div className="normal-container advancel-container" onClick={(e) => this.saveData()}>
              <div className="horizontal-layout advance-title">
                <div className="advance-user-name">{data.emName}的监察投诉事件申请</div>
                <Time className="advance-time" type="fromNow">{data.reportDate}</Time>
              </div>
              <KVMap title="客户单位">{customerName}</KVMap>
              <KVMap title="结算主体">{departName}</KVMap>
              <KVMap title="业务类型">{businessTypeName}</KVMap>
              <KVMap title="劳动保障部门">{data.eventOccurrenceAddr}</KVMap>
              <KVMap title="申请时间">{data.reportDate.substring(0, 10)}</KVMap>
              <KVMap title="审批状态">{auditStatusOption[data.auditStatus]}</KVMap>
            </div>
          </div>
        );
        break
      default:
        return null
    }

  }
}

export default withRouter(EmergencyItem);