import React, { Component } from 'react';
import './style.css';
import { parseFormatNum } from '../../../utils/conversion';

// 标题和内容显示组件
// title=> 左边标题
// children => 内容
// type=> money:显示金钱 逗号分割，两位小数
class ItemKV extends Component {
  // className={[this.props.className,'tagBox'].join(' ')}
    render() {
      const defaultClass = `horizontal-layout item-kv-layout ${this.props.className}`;
      let result = defaultClass;
      if(this.props.fixWidthLeft){
        result = [defaultClass,'fixWidthLeft'].join(' ')
      }
      let money = (this.props.type === 'money') ? parseFormatNum(this.props.children, 2) : (this.props.children || "-")
      return (
          <div className={result} style={this.props.style}>
              <div className="item-kv-title">{this.props.title || 'key'}</div>
              <div className="item-kv-content">{money}</div>
          </div>
      );
    }
}

export default ItemKV;
