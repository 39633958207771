import React, { Component } from 'react';
import './style.css';

import Time from '../../../components/Time';

// name=>操作的对象，即人员名称
// type=>状态（ 1.审核通过 2审核未通过 3待审核） CC 已抄送 ,不传或传其他:无状态
// time=>操作时间
class ProcessInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvalResultOpts: { 0: "同意", 1: "驳回", 2: "待审核", 3: "提交", CC:'已抄送' }, // 审核状态
      resultColorOpts: {
        0: "#2AA852",
        1: "#E84E4E",
        2: "#F18834",
        3: "#2AA852",
        CC: '#323232',
      }, // 审核状态颜色
    }
  }

  getStatus(type) {
    if (String(type) === '1') {
      // 驳回
      if (this.props.backNodeName) {
        return '驳回至' + this.props.backNodeName;
      }
      return '驳回';
    } else if(String(type) === 'CC'){
      return '已抄送'
    }else {
      return this.state.approvalResultOpts[String(type)];
    }
  }

  render() {
    const status = this.getStatus(this.props.type);
    let reasonView = null
    if (this.props.reason) {
      reasonView = (
        <div className="reason-container">
          <p className="reason-text">{this.props.reason}</p>
        </div>
      )
    }
    return (
      <div className="exam-item" style={this.props.style}>
        <div className="horizontal-layout pi-container">
          <div style={{width:this.props.maxLength?Number(this.props.maxLength)*13:'auto'}}>{this.props.name || '-'}</div>
          <div style={{ marginLeft: "10px", color: this.state.resultColorOpts[String(this.props.type)] }}>{status}</div>
          <div className="times">
            <Time type='YYYY-MM-DD HH:mm' >{this.props.time}</Time>
          </div>
        </div>
        {reasonView}
      </div>
    )
  }
}
export default ProcessInfo;
