
// 嵌套路由父级路由名称
const routerPath = {
    advancepayment: "/advancepayment",
    contract: "/contract",
    emergency: "/emergency"
}

// 全局定义路由
export default {
    // 登录
    login: {
        url: '/auth/oauth/wxLogin', // 路由地址
        title: '登录' // 路由标题，doucument标题
    },

    // 垫付审批
    advancepayment: {
        url: routerPath.advancepayment,
        title: '垫付审批',
        // 垫付审批列表
        list: {
            url: routerPath.advancepayment + '/list',
            title: '垫付审批'
        },
        // 垫付审批详情
        detail: {
            url: routerPath.advancepayment + '/detail',
            title: '垫付详情'
        },
        // 结算单详情
        settleaccounts: {
            url: routerPath.advancepayment + '/settleaccounts',
            title: '结算单'
        }
    },

    // 合同审批
    contract: {
        url: routerPath.contract,
        title: '合同审批',
        // 合同审批列表
        list: {
            url: routerPath.contract + '/list',
            title: '合同审批'
        },
        // 合同审批详情
        detail: {
            url: routerPath.contract + '/detail',
            title: '合同详情'
        },
        // 合同审批驳回
        reject: {
            url: routerPath.contract + '/reject',
            title: '驳回'
        },
        // 合同审核同意
        agree:{
            url: routerPath.contract + '/agree',
            title: '同意'

        }
    },

    // 突发事件审批
    emergency: {
        url: routerPath.emergency,
        title: '突发事件审批',
        // 垫付审批列表
        list: {
            url: routerPath.emergency + '/list',
            title: '突发事件审批'
        },
        // 垫付审批详情
        detail: {
            url: routerPath.emergency + '/detail',
            title: '详情'
        },
    }

}