import React, { Component } from 'react';
import './style.css';


// type => primary:蓝色，ghost:灰色
class StepIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<div className={`si-container ${this.props.type || 'primary'}`} />);
    }
}

export default StepIcon;