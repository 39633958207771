import React, { Component } from 'react';
import './style.css';
import { parseFormatNum } from '../utils/conversion';

// 标题和内容显示组件
// title=> 左边标题
// children => 内容
// space => 内容居标题的距离较小，默认不传, 可传值 samll
// theme => 内容颜色，不传黑色 warn：重要色 gray:灰色
// darkTitle => 标题颜色 black
// type=> money:显示金钱 逗号分割，两位小数
class KYMap extends Component {



    render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                <div className="horizontal-layout kvmap-layout" >
                    <div className={`kvmap-title ${((this.props.space) || '')} ${((this.props.darkTitle) ? 'dark' : '')} ${this.props.type === 'fix' ? 'averSlary' : ''}`}>{this.props.title || 'key'}</div>
                    <div className={`kvmap-content ${((this.props.space) || '')} ${((this.props.theme) || '')}`}>{(this.props.type === 'money') ?  parseFormatNum(this.props.children, 2) : (this.props.children || "-")}</div>
                </div>
            </div>
        );
    }
}

export default KYMap;