import React, { Component } from 'react';
import './style.css';
import KVMap from '../../../components/KVMap';
import Tag from '../components/Tag';
import Time from '../../../components/Time';
import { withRouter } from 'react-router-dom';
import router from '../../../config/router-config.js';
import Dictionaries from '../../../components/Dictionaries';
import { CONTRACT_ID, CUSTOMER_ID, TOKEN } from '../../../config/constant';
// myAdvanceType => 结算单类型 0：待处理
class ContractItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auditTypeFlag:{
                2:'合同',
                3:'合同办理',
                7:'合同作废',
                8:'合同停止合作'
            }
            // 0客户新增 1.客户信息变更 2合同审核流程 3合同办理 4商机审核 5报价单 6团队共享
        }
    }

    saveData(e) {

        var token = sessionStorage.getItem(TOKEN);
        sessionStorage.setItem(token, this.props.data.id); // 清空缓存

        sessionStorage.setItem(CONTRACT_ID, this.props.data.id);
        sessionStorage.setItem(CUSTOMER_ID,this.props.data.customerId);
        this.props.history.push(router.contract.detail.url)
    }

    render() {

        const data = this.props.data;

        if (data == null) {
            return null;
        }

        return (

            <div className="contract-item-container" onClick={(e) => this.saveData(e)} style={this.props.style}>

                <div className="horizontal-layout contract-item-title">
                    <div className="contract-item-user-name"><div style={{
                        color: "#323232",
                        textOverflow: 'ellipsis',
                        maxWidth: '55px',
                        whiteSpace: 'nowrap',
                        overflow:'hidden',
                        wordBreak:'break-all',
                    }}>{data.submitManName || '-'}</div>&nbsp;发起的合同申请</div>
                    <Tag type={data.prAuditFlag} style={{ marginLeft: "7.5px" }}></Tag>
                    <Time className="contract-item-time" type="fromNow">{data.createTime}</Time>
                </div>
                <KVMap title="客户单位:" style={{ marginTop: "4.75px" }}>{data.customerName}</KVMap>
                <KVMap title="客户类型:"><Dictionaries type='customerType'>{data.customerType}</Dictionaries></KVMap>
                <KVMap title="业务类型:"><Dictionaries datas={this.props.dictionaries.businessType}>{data.businessType}</Dictionaries></KVMap>
                <KVMap title="归属部门:">{data.businessDeveloperOrganName}</KVMap>
                <KVMap type="money" title="收入预估:">{data.estimatedIncome}</KVMap>
            </div>
        );
    }
}

export default withRouter(ContractItem);