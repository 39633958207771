import React, { Component } from 'react';
import './style.css';

// type => 字符串 0待提交/1待审核/2审核通过/3审核退回/4已作废 其他内容隐藏此标签
class Tag extends Component {

  getText(type) {
    switch (type) {
      case "0":
        return "已通过";
      case "1":
        return "不通过";
      case "2":
        return "待审核";
      case "3":
        return "待提交";
      case "5":
        return "已办理";
      case "6":
        return "已归档";
      default:
        return "";
    }
  }

  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        <div className={`tag-style tag-style-${(this.props.type || 'no')}`}>
          {this.getText(this.props.type)}
        </div>
      </div>
    )
  }
}

export default Tag;