import React, { Component } from 'react';
import axios from 'axios';
import url from '../config/url-config.js';
import router from '../config/router-config.js';
import Multistate from '../components/Multistate';

import { CODE, STATE, SHOW_ADVANCE_TYPE, ADVANCE_ID, CONTRACT_ID,EMERGENCY_ID } from '../config/constant';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: 'code...',
      errorMsg: '登录中...',
      multistate: "load"
    }
  }

  componentDidMount() {
    document.title = router.login.title
    const searchParams = new URLSearchParams(this.props.location.search)
    const code = searchParams.get(CODE);
    const state = searchParams.get(STATE);
    if (state) {
      var pageType = state.substring(0, 1) //打开详情页 1：垫付审批 2：垫付抄送  3:合同审批 4：合同抄送
      var type = Number(pageType)
      const id = state.substring(1)
      sessionStorage.setItem(SHOW_ADVANCE_TYPE, type);
      sessionStorage.setItem('login_state', state);
      if (type === 1 || type === 2) {
        sessionStorage.setItem(ADVANCE_ID, id);
      }
      if (type === 3 || type === 4) {
        sessionStorage.setItem(CONTRACT_ID, id);
      }
      if (type === 5) {
        sessionStorage.setItem(EMERGENCY_ID, id);
      }

      this.setState({
        code: code,
        state: state,
        type: type,
        id: id
      })
    }
    this.handlerLogin(code, type, state);
  }

  handlerLogin = (code, type, state) => {
    var formdata = new FormData();
    formdata.append(CODE, code);
    const _this = this;
    axios.post(url.login, formdata)
      .then(res => {
        if (Number(res.data.code) === 200) {
          // localStorage.setItem('res:',JSON.stringify(res.data))
          sessionStorage.setItem('token', res.data.data.oauth2AccessToken.access_token)
          sessionStorage.setItem('CRM_CODE',JSON.stringify(res.data.data.user.clientRoleMap.crm));
          // 垫付审批列表
          if (String(state) === 'STATE' || String(state) === 'advancepayment') {
            this.props.history.replace(router.advancepayment.list.url)
            return
          }
          // 合同审批列表
          if (String(state) === 'contract') {
            this.props.history.replace(router.contract.list.url)
            return
          }
          // 突发事件审批列表
          if (String(state) === 'emergency') {
            this.props.history.replace(router.emergency.list.url)
            return
          }
          // 垫付审批详情
          if (type === 1 || type === 2) {
            this.props.history.replace(router.advancepayment.detail.url)
            return
          }
          // 合同审批详情
          if (type === 3 || type === 4) {
            this.props.history.replace(router.contract.detail.url)
            return
          }
          // 突发事件审批详情
          if (type === 5) {
            
            this.props.history.replace(router.emergency.detail.url)
            return
          }
          _this.setState({
            multistate:'content'
          })
        } else {
          _this.setState({
            multistate: "error",
            errorMsg: '登录失败,请联系管理员：' + res.data.msg
          })
        }

      })
      .catch(function (error) {
        const errorMsg = '登录失败,请联系管理员：' + error;
        _this.setState({
          multistate: "error",
          errorMsg: errorMsg
        })
      });
  }
  render() {
    return (<>
      <Multistate type={this.state.multistate} msg={this.state.errorMsg} refresh={() => this.multistateRefresh()} />
    </>);
  }
}

export default Login;
