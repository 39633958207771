import React, { Component } from 'react';
import './style.css';

import Time from './Time';

// name=>操作的对象，即人员名称
// type=>状态（ 1.审核通过 2审核未通过 3待审核） CC 已抄送 ,不传或传其他:无状态
// time=>操作时间
class EmergecyProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const info = this.props.info;
        const index = this.props.index;
        var html = null;
        if (info) {
            html = (<>
                <div className="horizontal-layout pi-container emergency">
                    <Time type='DH'>{info.operationTime}</Time>
                </div>
            </>)
        }

        return (<div className={this.props.className} style={this.props.style}>
            <div className="horizontal-layout pi-container">
                <div className="emergencyWidth">{info ? info.operationUserName : '未知'}  <span>{info.nodeName}</span></div>
                {html}
            </div>
            <div className="emergecyRemark">{/提交审核|材料补齐|办理中|办结/.test(info.nodeName) ? '备注：' : '审批意见：'}{/转重大|办理中|办结/.test(info.nodeName) ? '无' : info.remark}</div>
        </div>);
    }
}

export default EmergecyProcess;