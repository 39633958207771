import React, { Component } from 'react';
import { Icon } from 'antd-mobile';

import '../App.css';

// 多状态组件
// type => loading:加载 empty：无数据  nonet：无网络 404：路由错误 error：其他错误 content:显示子布局
// msg => 错误信息
// refresh => 刷新
class Multistate extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        var multistate = null;

        switch (this.props.type) {
            case "load":
            case "loading":
                multistate = (
                    <div className="vertical-layout center">
                        <Icon type='loading' size="lg" />
                        <div style={{ marginTop: "10px" }}>{(this.props.msg) ? this.props.msg : '数据加载中'}</div>
                    </div>
                )
                break;
            case 'empty':
                multistate = (
                    <div className="vertical-layout center" style={{ marginTop: "50px" }}>
                        <div>{(this.props.msg) ? this.props.msg : '当前页面无数据'}</div>
                        {/* <Button style={{ marginTop: "50px", width: "200px" }} type="blue" onClick={this.props.refresh} enable>刷新</Button> */}
                    </div>
                )
                break;
            case "nonet":
                multistate = (
                    <div>{(this.props.msg) ? this.props.msg : '当前网络异常，请检查网络'}</div>
                )
                break;
            case "404":
                multistate = "404 the page is not found.";
                break;
            case "error":
                multistate = (this.props.msg) ? this.props.msg : '加载失败'
                break;
            default:
                multistate = null
        }

        return (<>
            {
                (multistate) ?
                    (<div className="default-container">
                        {multistate}
                    </div>)
                    : this.props.children
            }
        </>);
    }
}

export default Multistate;