import React, { Component } from 'react';
import './style.css';

// type => primary:主色，ghost:灰色，blue:蓝色
// img => 一张高12px的图片
// enable => 是否可点击

class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        var img = null
        if (this.props.img) {
            img = (<img src={this.props.img} alt="icon" />)
        }

        return (<div className={this.props.className} style={this.props.style} onClick={(this.props.enable) ? this.props.onClick : null}>

            <div className={`b-container ${this.props.type || 'primary'} ${(this.props.enable) ? '' : 'unenable'}`}>
                {img}
                {this.props.children}
            </div>

        </div>);
    }
}

export default Button;