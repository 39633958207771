import React, { Component } from 'react';
import './style.css';
import KVMap from './KVMap';
import Tag from './Tag';
import Time from './Time';
import { withRouter } from 'react-router-dom';
import router from '../config/router-config.js';

// import { switchAdvanceList } from '../redux/data/advancelist';
import { ADVANCE_ID, TOKEN } from '../config/constant';

// myAdvanceType => 结算单类型 0：待处理
class AdvanceItem extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  saveData(e) {
    var token = sessionStorage.getItem(TOKEN);
    sessionStorage.setItem(token, this.props.data.id); // 清空缓存
    sessionStorage.setItem(ADVANCE_ID, this.props.data.id);
    this.props.history.push(router.advancepayment.detail.url)
  }

  render() {

    const data = this.props.data;
    if (data == null) {
      return null;
    }

    return (
      <div className={this.props.className} id="iddddddd">
        <div className="normal-container advancel-container" onClick={(e) => this.saveData(e)}>

          <div className="horizontal-layout advance-title">
            <div className="advance-user-name">{data.createUser}的垫付申请</div>
            <Tag type={data.auditStatus} style={{ marginLeft: "7.5px" }}></Tag>
            <Time className="advance-time" type="fromNow">{data.createTime}</Time>
          </div>
          <KVMap type="money" title="垫付金额" style={{ marginTop: "10.75px" }}>{data.advanceMoney}</KVMap>
          <KVMap type="money" title="结算金额">{data.settleMoney}</KVMap>
          <KVMap title="客户单位">{data.customerName}</KVMap>
          <KVMap title="结算主体">{data.departName}</KVMap>
        </div>
      </div>
    );
  }
}

export default withRouter(AdvanceItem);