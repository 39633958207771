import axios from 'axios';
import url from './url-config.js';

import { Toast } from 'antd-mobile';

axios.defaults.baseURL = url.baseUrl;
axios.defaults.headers.common['Authorization'] = url.qyWeixinLoginAuthorization;
axios.defaults.headers.post['Content-Type'] = url.defaultContentType;

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么

  if (config.url === url.login) {
    // 如果是登录接口，使用默认验证，否则使用服务器提供的token
    config.headers.Authorization = url.qyWeixinLoginAuthorization
  } else {
    config.headers.Authorization = 'bearer ' + sessionStorage.getItem('token');
    // config.headers.Authorization = 'bearer 25d31d4c-10bb-4408-967c-3af86af2cb8e';
  }

  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么

  return response;
}, function (error) {
  // 对响应错误做点什么
  var errorMsg = "请求失败：" + error;

  if (error.response && error.response.status === 401) {
    // token失效,跳转登录
    Toast.offline('登录失效', 1)
    window.location.href = url.qyweixinUrl
    return
  } else {

    if (error.response && error.response.data && error.response.data.msg) {
      errorMsg = '' + error.response.data.msg
    }

    Toast.offline(errorMsg, 2);

  }

  return Promise.reject(errorMsg);
});