import React from 'react';

class Dictionaries extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '-',
      customerType: {
        "0": '集团客户',
        "1": '重点客户',
        "2": '一般客户',
        "3": '电信客户',
      },
      signingStatus: {
        "1": "新签",
        "0": "续签",
        "2": "重签",
        "3": "补充协议",
        "4": "先于合同执行",
        "5": "虚拟合同"
      },
      oldCustomerFlag: {
        "0": "否",
        "1": "是"
      },
      outWithFlag: {
        "0": "否",
        "1": "是"
      },
      chapterCustomerPre: {
        "0": "否",
        "1": "是",
      },
      serviceSide: {
        "1": "甲方",
        "2": "乙方"
      },
      r2Flag: {
        // "0": '非R2业务',
        // "1": 'R2业务',
        "0":'R1业务',
        "1": 'R2业务',
        "2": '集客T1业务'
      },
      priceFlagOpts: {
        "1": '低于红线价格'
      },
      chapterType: {
        "1": "合同章",
        "2": "法人章"
      },
      chapterUnit: {
        "1": "皖信",
        "2": "柯恩",
        "3": "易服",
        "4": "昊伦",
        "5": "赤道线",
      },
      contractTemplate: {
        "1": "公司模板无修改",
        "2": "公司模板有修改",
        "3": "客户指定",
        "4": "其他模板"
      },
      settleCycle: {
        "1": "月结",
        "2": "季度结",
        "3": "半年结",
        "4": "年结"
      },
      settleType: {
        "1": "当月结当月费用",
        "2": "当月结上月费用",
        "3": "当月结下月费用",
        "4": "其他"
      },
    }
  }

  text(id, datas, type) {

    if (type === 'customerType') {
      return this.state.customerType[id] || '-';
    }

    if (type === 'signingStatus') {
      return this.state.signingStatus[id] || '-';
    }

    if (type === 'oldCustomerFlag') {
      return this.state.oldCustomerFlag[id] || '-';
    }

    if (type === 'outWithFlag') {
      return this.state.outWithFlag[id] || '-';
    }
    if (type === 'chapterCustomerPre') {
      return this.state.chapterCustomerPre[id] || '-';
    }

    if (type === 'r2Flag') {
      return this.state.r2Flag[id] || '-';
    }

    if (type === 'priceFlag') {
      return this.state.priceFlagOpts[id] || '-';
    }
    
    if (type === 'serviceSide') {
      return this.state.serviceSide[id]? `（${this.state.serviceSide[id]}）`:'';
    }
    if (type === 'chapterType') {
      const tempArr = id ? id.split(',') : []
      const _this = this
      const res = tempArr.map(item => {
        return _this.state.chapterType[item]
      })
      return res.join(',');
    }

    if (type === 'chapterUnit') {
      return this.state.chapterUnit[id] || '-';
    }

    if (type === 'contractTemplate') {
      return this.state.contractTemplate[id] || '-';
    }

    if (type === 'settleCycle') {
      return this.state.settleCycle[id] || '-';
    }

    if (type === 'settleType') {
      return this.state.settleType[id] || '-';
    }

    if (datas) {
      return datas[id] || '-';
    }

    if (id) {
      return id;
    }

    return '-';
  }

  render() {
    return (<>{this.text(this.props.children, this.props.datas, this.props.type)}</>)
  }
}

export default Dictionaries;
