import React, { useEffect, useState } from 'react';

import axios from 'axios';
import url from '../config/url-config';

// 将地区码转成文本
const DataById = props => {

    const { children, type } = props;

    const [name, setName] = useState('-');

    useEffect(() => {
        if (children && type) { // 如果有值再处理
            if (String(type) === 'area') {
                getArea(children)
            }
            if (String(type) === 'organizationinfo') {
                getOrganizationinfo(children)
            }
            if(String(type) === 'user'){
                getUser(children);
            }
        }
    }, [children, type])

    // 获取地区数据
    function getArea(id) {
        axios.get(`${url.sysarea}${id}`)
            .then(res => {
                if (res && res.data && Number(res.data.code) === 200 && res.data.data) {
                    setName(res.data.data.areaName || '-');
                }
            })
    }

    // 获取开发部门
    function getOrganizationinfo(id) {
        axios.get(`${url.organizationinfo}${id}`)
            .then(res => {
                if (res && res.data && Number(res.data.code) === 200 && res.data.data) {
                    setName(res.data.data.organName || '-');
                }
            })
    }

     // 获取人员名称
     function getUser(id) {
        axios.get(`${url.user}${id}`)
            .then(res => {
                if (res && res.data && Number(res.data.code) === 200 && res.data.data) {
                    setName(res.data.data.nickname || '-');
                }
            })
    }


    return (<>{name}</>)
}

export default DataById;
