import React, { Component } from 'react';
import './style.css';
import { ADVANCE_AUDIT_STATUS,EMERGENCY_AUDIT_STATUS } from '../config/constant';

// type => 字符串 0待提交/1待审核/2审核通过/3审核退回/4已作废 其他内容隐藏此标签
class Tag extends Component {

  getText(status,type) {
    // type 0 垫付  2突发事件
    switch (type) {
      case 0:
        return ADVANCE_AUDIT_STATUS[status]
        
      case 2:
        return EMERGENCY_AUDIT_STATUS[status]
      default:
        return ''
    }
    // switch (type) {
    //   case "0":
    //     return "待提交";
    //   case "1":
    //     return "审批中";
    //   case "2":
    //     return "已通过";
    //   case "3":
    //     return "已驳回";
    //   case "4":
    //     return "已作废";
    //   case "5":
    //     return "已终止";
    //   default:
    //     return "";
    // }
  }

  render() {
    return (
      <div className={[this.props.className,'tagBox'].join(' ')} style={this.props.style}>
        <div className={`tag-style advance-tag-style-${(this.props.status || 'no')}`}>
          {this.getText(this.props.status,this.props.type)}
        </div>
      </div>
    )
  }
}

export default Tag;