import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import router from '../../config/router-config.js'
import AdvancePaymentList from './APList';
import AdvanceRouter from './AdvanceRouter';
import KeepAlive, { AliveScope } from 'react-activation';

class AdvancePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <AliveScope>
        <Switch>
          <Route path={router.advancepayment.list.url} render={props => (
            <KeepAlive id={router.advancepayment.list.url} name={router.advancepayment.list.url}>
              <AdvancePaymentList {...props} />
            </KeepAlive>
          )} />

          <Route render={props => (
            <KeepAlive when={[false, true]} id={router.advancepayment.detail.url} name={router.advancepayment.detail.url}>
              <AdvanceRouter {...props} />
            </KeepAlive>
          )} />
        </Switch>
      </AliveScope>
    )
  }
}

export default AdvancePayment;