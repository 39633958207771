export const CODE = 'code'; // 登录接口参数

export const STATE = 'state'; // 登录接口参数 

export const TOKEN = 'token'; // 用户识别码 

export const SHOW_ADVANCE_TYPE = 'showAdvanceType'; // 当前展示的结算单类型， 1 待处理，2 抄送我

export const ADVANCE_ID = 'advanceId'; // 结算单id

export const ADVANCE_BILL_TYPE = 'advanceBillType'; // 结算单类型 1 普通薪资,2 工程薪资

export const SALARY_SETTLE_BILL_ID = 'salarySettleBillId'; // 普通结算单

export const ENGINEERING_BILL_ID = 'engineeringBillId'; // 工程结算单

export const ADVANCE_AUDIT_STATUS = ['待提交','审批中','已通过','已驳回','已作废','已终止'];

export const CONTRACT_ID = 'contract_id';// 合同id

export const CUSTOMER_ID = 'customer_id'; // 客户id

export const AUDIT_RESULT = 'audit_result'; // 审核结果通知

export const CONTRACT_CREATE_USER_NAME = 'contract_create_user_name'; // 合同创建人姓名

export const REJECT_STACT = 'reject_stact'; // 驳回状态

export const SHOW_EMERGENCY_TYPE = 'showEmergencyType'; // 突发事件类型

export const EMERGENCY_ID = 'emergencyId'; // 突发事件id

export const  EMERGENCY_AUDIT_STATUS = ['待提交','待审核','材料待补充','已退回','待办理','办理中','待办结','已办结','审核中']; // 突发事件审核状态
