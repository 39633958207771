
  export function parseFormatNum(number, n) {
    if(number === null || number === undefined){
        return '-'
    } else if(Number(number)===0){
        return 0
    }

    if (n !== 0) {
        n = (n > 0 && n <= 20) ? n : 2;
    }
    number = parseFloat((number + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
    var sub_val = number.split(".")[0].split("").reverse();
    var sub_xs = number.split(".")[1];

    var show_html = "";
    for (var i = 0; i < sub_val.length; i++) {
        show_html += sub_val[i] + ((i + 1) % 3 === 0 && (i + 1) !== sub_val.length ? "," : "");
    }

    if (n === 0) {
        return show_html.split("").reverse().join("");
    } else {
        return show_html.split("").reverse().join("") + "." + sub_xs;
    }

}