import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import router from '../../config/router-config.js'
import ContractList from './ContractList';
import ContractRouter from './components/ContractRouter';
import KeepAlive, { AliveScope } from 'react-activation';

class Contract extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AliveScope>
        <Switch>
          <Route exact path={router.contract.list.url} render={props => (
            <KeepAlive id={router.contract.list.url} name={router.contract.list.url}>
              <ContractList {...props} />
            </KeepAlive>
          )} />
          <Route render={props => (
            <KeepAlive when={[false, true]}>
              <ContractRouter {...props} />
            </KeepAlive>
          )} />
        </Switch>
      </AliveScope>
    );
  }
}
export default Contract;
