import React from 'react';
import './style.css';

class ItemContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={`item-container ${this.props.className}`} style={this.props.style}>
                <div className="title">{this.props.title}</div>
                {this.props.children}
            </div>
        );
    }

}

export default ItemContainer;
