import React, { Component } from 'react';
import axios from 'axios';
import url from '../../config/url-config.js';
import router from '../../config/router-config.js';
import { TOKEN } from '../../config/constant';
import { Tabs, ListView, PullToRefresh } from 'antd-mobile';
import AdvanceItem from '../../components/AdvanceItem';
import Multistate from '../../components/Multistate';
import { SHOW_ADVANCE_TYPE } from '../../config/constant';
import './style.css';
import { withActivation } from 'react-activation'

const dataSource = new ListView.DataSource({
  rowHasChanged: (row1, row2) => row1 !== row2,
});

class APList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pageIndex: 1,// 当前请求数据的页码
      noMoreData: false,// 没有更多数据了，为true时不触发加载更多
      tabSelectIndex: 0,// 当前选中的tab下标
      listData: [], // 列表数据
      isLoading: false,
      multistate: 'load',
      errorMsg: '',
      listHeight: document.documentElement.clientHeight - 43.5,
      tabs: [
        { title: "待处理", key: 0 },
        { title: "已处理", key: 1 },
        { title: "抄送我", key: 2 },
        { title: "我提交", key: 3 },
      ]
    };
  }

  componentDidActivate() { // 缓存激活
    document.title = router.advancepayment.list.title;
    const token = sessionStorage.getItem(TOKEN);
    if (!sessionStorage.getItem(token)) { // 详情中对数据操作后，返回该列表是刷新列表数据
      this.refreshData(0);
    }
  }

  componentDidMount() {
    document.title = router.advancepayment.list.title;
    this.refreshData(0); // 获取数据
  }

  // 请求数据
  getData(tabSelectIndex, page) {
    var tempUrl = null;
    switch (tabSelectIndex) {
      case 0:
        sessionStorage.setItem(SHOW_ADVANCE_TYPE, 1);
        tempUrl = url.tadvancepaymentWxAuditPage;
        break;
      case 1:
        sessionStorage.setItem(SHOW_ADVANCE_TYPE, 0);
        tempUrl = url.tadvancepaymentWxHandledPage;
        break;
      case 2:
        sessionStorage.setItem(SHOW_ADVANCE_TYPE, 2);
        tempUrl = url.tadvancepaymentWxCopyPage;
        break;
      case 3:
        sessionStorage.setItem(SHOW_ADVANCE_TYPE, 0);
        tempUrl = url.tadvancepaymentWxCreateUser;
        break;
      default:
        return null
    }
    const _this = this;
    axios.get(tempUrl, {
      params: {
        current: page,
        size: 10
      }
    })
      .then(res => {
        if (res && Number(res.data.code) === 200) {
          this.handleData(res.data.data, page, tabSelectIndex);
        }
      })
      .catch(function (error) {
        _this.setState({
          isLoading: false,
          multistate: 'error',
          errorMsg: error
        });
      });
  }

  // 处理数据
  handleData(tempData, page, tabSelectIndex) {
    var templistData = []
    if (page === 1) {
      templistData = tempData.records || [];
    } else {
      templistData = this.state.listData.concat(tempData.records)
    }
    this.setState({
      noMoreData: tempData.current >= tempData.pages, // 当前页等于总页数，数据已全部请求
      pageIndex: tempData.current,
      listData: templistData,
      isLoading: false,
      multistate: (templistData.length === 0 && page === 1) ? 'empty' : 'contnent',
      errorMsg: (templistData.length === 0 && page === 1) ? `暂无${this.state.tabs[tabSelectIndex].title}的申请` : ''
    });
  }

  // 刷新数据
  refreshData(tabSelectIndex) {
    // 重置数据并重新请求
    this.setState({
      pageIndex: 1,
      noMoreData: false
    })

    this.getData(tabSelectIndex, 1);
  }

  // 加载数据
  loadMoreData() {
    var num = this.state.pageIndex;
    this.getData(this.state.tabSelectIndex, ++num);
  }

  // 加载更多触发
  onEndReached = () => {
    if (this.state.isLoading && this.state.noMoreData) {
      return;
    }
    this.setState({ isLoading: true });
    this.loadMoreData();
  }

  // 下拉刷新触发
  onRefresh = () => {
    this.setState({ isLoading: true });
    this.refreshData(this.state.tabSelectIndex);
  };

  multistateRefresh = () => {
    this.setState({
      multistate: 'load',
      errorMsg: ''
    });
    this.refreshData(this.state.tabSelectIndex);
  }

  // tab 切换
  tabSelectCallback(_, index) {
    this.setState({
      tabSelectIndex: index,
      multistate: 'load',
      errorMsg: ''
    })
    this.refreshData(index);
  }

  render() {
    var tabIndex = this.state.tabSelectIndex;
    const row = (data, _, rowID, highlightRow) => {
      return (<AdvanceItem key={rowID} index={rowID} data={data} myAdvanceType={tabIndex} />);
    };

    return (
      <div>
        <Tabs tabs={this.state.tabs}
          ref={el => this.tabs = el}
          page={tabIndex}
          swipeable={false}
          prerenderingSiblingsNumber={0}
          onChange={(tab, index) => this.tabSelectCallback(tab, index)}
          tabBarBackgroundColor="#f6f8fa" // 背景色
          tabBarActiveTextColor="#50a0ff" // 激活文字颜色
          tabBarInactiveTextColor="#1a1a1a" // 未激活文字颜色
          tabBarUnderlineStyle={{ width: '12%', marginLeft: '6.5%' }} // tabBar下划线样式
          tabBarTextStyle={{ fontSize: '15px' }} // tabBar文字样式
        >
          <Multistate type={this.state.multistate} msg={this.state.errorMsg} ref={el => this.multistate = el} refresh={() => this.multistateRefresh()}>
            <ListView
              ref={el => this.lv = el}
              initialListSize={this.state.listData && this.state.listData.length}
              dataSource={dataSource.cloneWithRows(this.state.listData || [])}
              // renderHeader={() => <span>Pull to refresh</span>}
              renderFooter={() => (
                <div style={{ padding: 10, textAlign: 'center' }}>
                  {this.state.noMoreData ? '没有更多数据了' : this.state.isLoading ? '加载中...' : '请求完成'}
                </div>
              )}
              renderRow={row}
              style={{
                height: this.state.listHeight,
                overflow: 'auto'
              }}
              contentContainerStyle={{ height: "100%" }}
              scrollRenderAheadDistance={500}
              onEndReached={this.onEndReached}
              onEndReachedThreshold={500}
              pullToRefresh={<PullToRefresh
                refreshing={this.state.isLoading}
                onRefresh={this.onRefresh}
              />}
            />
          </Multistate>
        </Tabs>

      </div >
    )
  }
}

export default withActivation(APList);