import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import router from '../../config/router-config.js';
import AdvancePaymentDetail from './Detail';
import SettleAccounts from './SettleAccounts';
import KeepAlive from 'react-activation';

class AdvanceRouter extends Component {

  render() {
    return (
      <Switch>
        <Route path={router.advancepayment.detail.url} render={props => (
          <KeepAlive id='advancepayment_detail' name={router.advancepayment.detail.url}>
            <AdvancePaymentDetail {...props} />
          </KeepAlive>
        )} />

        <Route path={router.advancepayment.settleaccounts.url} render={props => (
          <KeepAlive id='advancepayment_settleaccounts' name={router.advancepayment.settleaccounts.url}>
            <SettleAccounts {...props} />
          </KeepAlive>
        )} />
      </Switch>
    );
  }
}
export default AdvanceRouter;
