import React, { Component } from 'react';
import './style.css';
import { Flex } from 'antd-mobile';
import { withRouter } from 'react-router-dom';
import router from '../config/router-config.js';

class SalaryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const data = this.props.data

    return (
      <div>
         <Flex className="salaryListItem">
          <Flex.Item><span>{ data.name }</span></Flex.Item>
          <Flex.Item><span>{ data.relaySalary }</span></Flex.Item>
          <Flex.Item><span>{ data.actualSalarySum }</span></Flex.Item>
        </Flex>
      </div>
    );
  }
}

export default withRouter(SalaryItem);