import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import router from '../../../config/router-config.js';
import ContractDetail from '../../contract/Detail';
import Reject from '../../contract/Reject';
import Agree from '../Agree.jsx';
import KeepAlive from 'react-activation';

class ContractRouter extends Component {

    render() {
        return (
            <Switch>
                <Route path={router.contract.detail.url} render={props => (
                    <KeepAlive id='contract_detail' name={router.contract.detail.url}>
                        <ContractDetail {...props} />
                    </KeepAlive>
                )} />
                <Route path={router.contract.reject.url} render={props => (
                    <KeepAlive id='contract_reject' name={router.contract.reject.url}>
                        <Reject {...props} />
                    </KeepAlive>
                )} />
                <Route path={router.contract.agree.url} render={props => (
                    <KeepAlive id='contract_agree' name={router.contract.agree.url}>
                        <Agree {...props} />
                    </KeepAlive>
                )} />
            </Switch>
        );
    }
}

export default ContractRouter;
