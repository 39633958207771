import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';

// 日期格式化，内部组件使用参考文档 http://momentjs.cn/
// 日期格式化 children 转换前的格式
// type=>转换类型 fromNow 与当前时间的差值

// H    11:09
// DH   07/24 11:09
// LDH  2020/07/24 11:09
// Y    2020/07/24
// momentjs 提供的解析格式 ,更多格式请查看http://momentjs.cn/docs/#/displaying/
// ('LT');   // 11:09
// ('LTS');  // 11:09:32
// ('L');    // 2020/07/24
// ('l');    // 2020/7/24
// ('LL');   // 2020年7月24日
// ('ll');   // 2020年7月24日
// ('LLL');  // 2020年7月24日上午11点09分
// ('lll');  // 2020年7月24日 11:09
// ('LLLL'); // 2020年7月24日星期五上午11点09分
// ('llll'); // 2020年7月24日星期五 11:09
class Time extends Component {
    constructor(props) {
        super(props);

        moment.locale('zh-cn');
        this.state = {}
    }

    dealTime() {
        // 数据格式 2020-06-23 10:26:16
        // 传入的数据时间
        const fromatTime = this.props.children;

        if (!fromatTime) {
            return '-';
        }

        if (this.props.type === 'fromNow') {

            const now = Date.now(); // 当前时间
            const todayStart = moment(moment(now).format("YYYYMMDD"), "YYYYMMDD").format('x'); // 今天0点时的毫秒
            const show = moment(fromatTime, "YYYYMMDDhhmmss").format('x'); // 数据时间转成毫秒

            const todayDValue = (now - todayStart) / 1000; // 今天已经过去的时间
            const dValue = (now - show) / 1000; // 计算差值,结果为秒

            // 优先显示昨天
            if (dValue >= (todayDValue + 24 * 60 * 60)) {
                return moment(fromatTime, "YYYYMMDDhhmmss").format('M[/]DD');
            } else if (dValue >= todayDValue) {
                return '昨天';
            } else if (dValue > 60 * 60) {
                return `${parseInt((dValue / 60) / 60)}小时前`;
            } else if (dValue >= 60) {
                return `${parseInt(dValue / 60)}分钟前`;
            } else if (dValue < 60) {
                return '刚刚';
            } else {
                return '时间错误';
            }
        } if (this.props.type === 'H') {
            return moment(fromatTime, "YYYYMMDDhhmmss").format('HH:mm');
        } if (this.props.type === 'DH') {
            return moment(fromatTime, "YYYYMMDDhhmmss").format('MM[/]DD[ ]HH:mm');
        } if (this.props.type === 'LDH') {
            return moment(fromatTime, "YYYYMMDDhhmmss").format('YYYY[/]MM[/]DD[ ]HH:mm');
        } if (this.props.type === 'Y') {
            return moment(fromatTime, "YYYYMMDDhhmmss").format('YYYY[/]MM[/]DD');
        } else {
            return moment(fromatTime, "YYYYMMDDhhmmss").format(this.props.type);
        }
    }

    render() {
        return (<div className={this.props.className} style={this.props.style}>
            {this.dealTime()}
        </div>);
    }
}

export default Time;