import { ADVANCE_LIST } from "./actionTypes";

const redAdvance = (state=null, action) => {

    if (action === undefined) {
        return state
    }

    switch (action.type) {
        case ADVANCE_LIST:
            return {
                ...state,
                ...action
            }
        default:
            return state
    }

}

export default redAdvance 