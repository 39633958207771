import React from 'react';
import { CONTRACT_ID, TOKEN, CUSTOMER_ID, AUDIT_RESULT } from '../../config/constant';
import { Toast, TextareaItem, Picker, List, WhiteSpace, Button, Checkbox, Icon } from 'antd-mobile';
import './style.css';

import router from '../../config/router-config.js';
import axios from 'axios';
import url from '../../config/url-config';
import emitter from '../../utils/events';
import { withActivation } from 'react-activation'
import qs from 'qs';

const CheckboxItem = Checkbox.CheckboxItem;

// 驳回合同审批
class Agree extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      inputApprovalOpinion: '', // 审核通过的原因
      enableButton: true, // button 是否可用
      oldCustomerFlag: null,  // 是否老客户
      oldCustomerFlagData: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ],
      r2Flag: null, // 业务划分
      businessSelectOpts: [
        {
          label: 'R1业务',
          value: '0'
        },
        {
          label: 'R2业务',
          value: '1'
        },
        {
          label: '集客T1业务',
          value: '2'
        }
      ],
      priceFlag: null,
      priceFlagOpts: [{
        label: '低于红线价格',
        value: '1'
      }],
      isOldCustomer: false, // 接口判断
      isBussinessBranch: false, // 是否是业务分支
      loading:false,
      isModalBg: false,  //更多标签显示与隐藏
      labelName: '请选择',
      isCheck: false,
      isTipsShow: false,  //提示语的显示与隐藏
      nextNodeInfo: [],
      nextNodeId: '',
      nextNodeType: ''
    }
    this.switchIsModalBg = this.switchIsModalBg.bind(this);
    this.switchClose = this.switchClose.bind(this);
    this.switchTrue = this.switchTrue.bind(this);
  }

  componentDidActivate() { // 缓存激活，此处没有效果，需要注意解决方案
    document.title = router.contract.agree.title;
    this.setState({
      inputApprovalOpinion: ''
    })
  }

  initData(){
    document.title = router.contract.agree.title;
    var id = sessionStorage.getItem(CONTRACT_ID);
    var nextNodeInfo = this.props.location.state.nextNodeInfo
    console.log(nextNodeInfo,'nextNodeInfo')
    this.setState({
      id: id,
      inputApprovalOpinion: '',
      nextNodeInfo: nextNodeInfo
    })
    const customer_id = sessionStorage.getItem(CUSTOMER_ID);
    const crm_code = sessionStorage.getItem('CRM_CODE');
    this.getSystemRoleInfo(JSON.parse(crm_code));
    this.getIsOldFlagUrl(customer_id);
  }

  componentDidMount() {
    console.log('componentDidMount')
    let that = this;
    this.initData();
    this.props.history.listen(r => {
      if(r.pathname === '/contract/agree'){
        that.initData()
      }
    })



  }

  getSystemRoleInfo(crm_code) {
    let that = this;
    const param = { ids: crm_code };
    axios.get(`${url.getSysRoleCodeList}?${qs.stringify(param, { indices: false })}`).then(res => {
      const data = res.data.data;
      if (data && data.length) {
        const item = data.find(info => info === 'ROLE_CRM_CONTR_LABEL');
        that.setState({
          isBussinessBranch: !!item,
          enableButton: !item,
        })
      }
    })
  }

  getIsOldFlagUrl(id) {
    axios.get(`${url.isOldFlagUrl}?id=${id}`).then(res => {
      if (res && res.data && Number(res.data.code) == 200) {
        const info = res.data.data;
        this.setState({
          isOldCustomer: info
        })
        if(info==true) {
          this.setState({
            isTipsShow: false,
            oldCustomerFlag: [1]
          })
        } else {
          this.setState({
            isTipsShow: false,
            oldCustomerFlag: [0]
          })
        }
      }
    })
  }

  // 驳回理由输入框监听
  theModelTextareaChangeListener(text) {
    this.setState({
      inputApprovalOpinion: text
    })
  }

  // 上传审核结果
  submitAuditResult = () => {
    this.loading = true;

    const id = this.state.id;
    const _this = this;
    Toast.loading('提交中...', 0);
    const params = {
      id,
      prAuditFlag: 0, // 0通过 1不通过
      prAuditOpinion: _this.state.inputApprovalOpinion,
      nextNodeId: String(_this.state.nextNodeId),
      nextNodeType: _this.state.nextNodeType
    }
    if(_this.state.isBussinessBranch){
      params.oldCustomerFlag = this.state.oldCustomerFlag[0];
      params.r2Flag = this.state.r2Flag[0];
      if(this.state.priceFlag && this.state.priceFlag.length){
        params.priceFlag = this.state.priceFlag[0];
      }
    }
    // 同意接口
    axios.post(url.tcustomercontractWxAudit,params,).then(res => {
        if (res && res.data.code === 200) {
          // 审核成功,处理下一条数据
          Toast.success('已完成审批', 1)
          setTimeout(() => {
            // 驳回成功，处理数据
            emitter.emit(AUDIT_RESULT, {
              type: 0,
              result: 'success'
            });
            _this.props.history.goBack();
          }, 1100)
          try {
            // 清空缓存后回到列表页，列表页会重新请求数据
            var token = sessionStorage.getItem(TOKEN);
            sessionStorage.removeItem(token);
          } catch (e) {
          }
        } else {
          Toast.offline(res.data.msg, 2);
        }

        _this.setState({
          loading:false,
        })
      })
      .catch(function (error) {
        Toast.offline(error, 2);
        _this.props.history.goBack();
        _this.setState({
          loading:false,
        })
      });
  }

  onChange(prop, value,e) {
    this.setState({
      [prop]: value,
    });
    if(prop=='nextNodeId') {
      console.log(value)
      for (var i = 0, lenI = e.length; i < lenI; ++i) {
        if (e[i].value == value) {
          this.setState({
            nextNodeType: e[i].type
          })
        }
      }
      // this.setState({
      //   nextNodeType: e[0].type
      // })
    }
    if(prop=='oldCustomerFlag') {
      if(this.state.isOldCustomer===true) {
        if(value.toString()==='0'){
          this.setState({
            isTipsShow: true
          })
        } else {
          this.setState({
            isTipsShow: false
          })
        }
      } else {
        this.setState({
          isTipsShow: false
        })
      }
    }
    if(prop=='priceFlag') {
      if(e.target.checked==true){
        this.setState({
          priceFlag: 1
        })
      } else {
        this.setState({
          priceFlag: null
        })
      }
    }
  }
  //打开其他标签的蒙层
  switchIsModalBg() {
    if(this.state.isModalBg===true){
      this.setState({
        isModalBg: false
      })
    } else {
      this.setState({
        isModalBg: true
      })
    }
    if(this.state.priceFlag==1){
      this.setState({
        isCheck: true
      })
    } else {
      this.setState({
        isCheck: false
      })
    }
  }
  //确定按钮
  switchTrue() {
    if(this.state.priceFlag==1){
      this.setState({
        isModalBg: false,
        labelName: '低于红线价格'
      })
    } else {
      this.setState({
        isModalBg: false,
        labelName: '请选择'
      })
    }
  }

  //取消按钮
  switchClose() {
    this.setState({
      isModalBg: false
    })
    if(this.state.isCheck==true){
      this.setState({
        priceFlag: 1
      })
    } else {
      this.setState({
        priceFlag: null
      })
    }
  }

  render() {
    return (<div className="contract-agree-container" >

      <WhiteSpace />
      {
        this.state.isBussinessBranch && <>
          {
            this.state.isOldCustomer && this.state.isTipsShow && <div className="contract-agree-tips">该客户已在系统存在审核通过的合同，请确认！</div>
          }

          <WhiteSpace />
          <Picker cols={1} data={this.state.oldCustomerFlagData}
            onChange={val => this.onChange('oldCustomerFlag', val)}
            value={this.state.oldCustomerFlag}>
            <List.Item className="contract-require-item" arrow="horizontal">是否老客户</List.Item>
          </Picker>

          <Picker cols={1} data={this.state.businessSelectOpts}
            onChange={val => this.onChange('r2Flag', val)}
            value={this.state.r2Flag}>
            <List.Item className="contract-require-item" arrow="horizontal">业务划分</List.Item>
          </Picker>

          <WhiteSpace />

          {/*<Picker cols={1} data={this.state.priceFlagOpts}*/}
          {/*  onChange={val => this.onChange('priceFlag', val)}*/}
          {/*  value={this.state.priceFlag}>*/}
          {/*  <List.Item arrow="horizontal">其他标签</List.Item>*/}
          {/*</Picker>*/}
          <div className="otherChioce">
            <div>其他标签</div>
            <div onClick={this.switchIsModalBg}><span>{this.state.labelName}</span><Icon type="right" size="md" color="#888" /></div>
          </div>
          {this.state.isModalBg?(
            <div className="modalBg">
              <div className="modal">
                <div className="modalTop">
                  <div onClick={this.switchClose}>取消</div>
                  <div onClick={this.switchTrue}>确定</div>
                </div>
                {this.state.priceFlagOpts.map(i => (
                    <CheckboxItem defaultChecked={this.state.isCheck}  key={i.value} onChange={(e) => {this.onChange('priceFlag',i.value,e)}}>
                      {i.label}
                    </CheckboxItem>
                ))}
            </div>
          </div>):null }

          <WhiteSpace />
        </>
      }

      <div className="contract-reject-content">
        {
          this.state.nextNodeInfo.length > 1 &&
          <Picker cols={1} data={this.state.nextNodeInfo}
                  onChange={val => this.onChange('nextNodeId', val, this.state.nextNodeInfo)}
                  value={this.state.nextNodeId}>
            <List.Item className="contract-require-item" arrow="horizontal">审核至</List.Item>
          </Picker>
        }

        <div className="contract-agree-title contract-agree-title1">审核意见</div>

        <TextareaItem
          value={this.state.inputApprovalOpinion}
          placeholder='请填写审批意见，不超过200字'
          count={200}
          rows={10}
          style={{ fontSize: 14 }}
          onChange={(val) => this.theModelTextareaChangeListener(val)}
        />

      </div>

      {
        this.state.nextNodeInfo.length < 2 &&
        <div className="contract-agree-button">
          <Button disabled={(this.state.oldCustomerFlag === null || this.state.oldCustomerFlag === undefined || this.state.r2Flag === null || this.state.r2Flag === undefined) && this.state.isBussinessBranch}
                  type="primary" onClick={this.submitAuditResult}>确认</Button>
        </div>
      }
      {
        this.state.nextNodeInfo.length > 1 &&
        <div className="contract-agree-button">
          <Button disabled={(this.state.oldCustomerFlag === null || this.state.oldCustomerFlag === undefined || this.state.r2Flag === null || this.state.r2Flag === undefined ) && this.state.isBussinessBranch || this.state.nextNodeId ===''}
                  type="primary" onClick={this.submitAuditResult}>确认</Button>
        </div>
      }

    </div>)
  }
}



export default withActivation(Agree);
