// 网络接口地址
export default {

    // 权限验证，固定值 base64加密
    qyWeixinLoginAuthorization: 'yifu d3hocjp0aGlzaXN3eGhy',

    // 默认传参方式
    defaultContentType: 'multipart/form-data',

    // 前后端分离，数据请求接口基地址
    // baseUrl:'http://wangan.qicp.vip/',
    //baseUrl:'http://qas-hro-yifu-gateway.yifucenter.com',   //测试环境地址
    //  baseUrl: 'http://prd-hro-yifu-gateway.worfu.com', // 皖信生产地址
     baseUrl:'http://kems.wandoujob.com/api/', // 柯恩生产（新）

    // 企业微信授权登录
    login: '/auth/oauth/wxLogin',

    // 企业微信获取code接口，项目入口 测试环境
    //qyweixinUrl: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwbcb090af0dfe50e5&redirect_uri=http://test-wx.worfu.com/auth/oauth/wxLogin&response_type=code&scope=snsapi_base&state=${sessionStorage.getItem("login_state") || "STATE"}#wechat_redirect`,
    // 人力云企业微信垫付审批
    // qyweixinUrl: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwbcb090af0dfe50e5&redirect_uri=http://wx.worfu.com/api/auth/oauth/wxLogin&response_type=code&scope=snsapi_base&state=${sessionStorage.getItem("login_state") || "STATE"}#wechat_redirect`,
    // 科恩企业微信垫付审批
     qyweixinUrl: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww3588a3e75e1e13eb&redirect_uri=http://kems.wandoujob.com/auth/oauth/wxLogin&response_type=code&scope=snsapi_base&state=${sessionStorage.getItem("login_state") || "STATE"}#wechat_redirect`,
    
    // 企业微信垫付审批记录待处理
    tadvancepaymentWxAuditPage: '/hrApproval/tadvancepayment/wx/audit/page',

    // 企业微信垫付审批记录已处理
    tadvancepaymentWxHandledPage: '/hrApproval/tadvancepayment/wx/handled/page',

    // 企业微信垫付审批记录抄送我
    tadvancepaymentWxCopyPage: '/hrApproval/tadvancepayment/wx/copy/page',

    // 企业微信垫付审批:创建人
    tadvancepaymentWxCreateUser: '/hrApproval/tadvancepayment/wx/createUser/page',

    // 企业微信垫付审批待处理:获取上下一个 id
    tadvancepaymentWxGetPreOrNextOne: '/hrApproval/tadvancepayment/wx/getPreOrNextOne',

    // 企业微信获取签名
    tadvancepaymentWxGetSign: '/hrApproval/tadvancepayment/wx/getSign',

    // 垫付详情 wx/getById?id=1289094010341720065
    tadvancepaymentDetail: '/hrApproval/tadvancepayment/wx/getById',

    // 垫付记录累计垫付数据 hrApproval/tadvancepayment/query/wxpass/sumBySellteDomin?id=1240835033945665537
    tadvancepaymentQueryWxPassSumBySellteDomin: '/hrApproval/tadvancepayment/query/wxpass/sumBySellteDomin',

    // 垫付详情查看垫付函数据 /hrApproval/tpaymentattainfo/geteAttas/{id}
    tpaymentattainfoGeteAttas: '/hrApproval/tpaymentattainfo/geteAttas/',

    // 审批抄送列表数据 hrApproval/tapproveauditinfo/wx/getAllAuditAndCopyInfo?mainId=1288386287807594498
    tapproveauditinfoWxGetAllAuditAndCopyInfo: '/hrApproval/tapproveauditinfo/wx/getAllAuditAndCopyInfo',

    // 审批垫付提交审核(wxhr:tpaymentapprovalrecord_submit_approve) { "advancePaymentId": "1286637272111874050", "approvalOpinion": "原因", "approvalResult": "0"  0通过 1不通过 }
    tpaymentapprovalrecordSubmitAndaudit: '/hrApproval/tpaymentapprovalrecord/submitAndaudit',

    // 普通薪资结算单 ?salaryFormId=1285885465752244226
    salaryOpenBill: '/salary/salary/wxOpenBill',

    // 工程薪资结算单  ?salaryFormId=1285908347922337793
    engineerOpenBill: '/salary/engineer/wxOpenBill',

    noTaxBill: '/salary/tnontaxsalaryaccount/page',

    // 企业微信垫付审批记录待处理
    tcustomercontractWxAuditPage: '/crmCustomer/tcustomercontract/wx/audit/page',

    // 企业微信垫付审批记录已处理
    tcustomercontractWxHandledPage: '/crmCustomer/tcustomercontract/wx/handle/page',

    // 企业微信垫付审批记录抄送我
    tcustomercontractWxCopyPage: '/crmCustomer/tcustomercontract/wx/copy/page',

    // 企业微信垫付审批:创建人
    tcustomercontractWxCreateUser: '/crmCustomer/tcustomercontract/wx/createUser/page',

    // 字典数据
    itemType: '/admin/dict/itemType/',

    // 业务类型单独接口 add by hfcai
    bussinessType:'/hrBase/sysdictbusinesstype/getSysDictBusinessTypeList',

    // 获取地区名称
    sysarea: '/hrBase/sysarea/',

    // 获取开发部门名称
    organizationinfo: '/admin/organizationinfo/',

    // 获取人员名称 开发人 /admin/user/{id}
    user: '/admin/user/',

    // 合同详情 /tcustomercontract/{id}
    tcustomercontractDetail: '/crmCustomer/tcustomercontract/',

    // 通过合同id查询所有的审核记录  /tcustomercontract/getNewAllAuditInfo/{mainId}
    getNewAllAuditInfo: '/crmCustomer/tcustomercontract/getNewAllAuditInfo/',


    rejectList:'/crmCustomer/tapprovalrecord/getBackRecord/byDomainIdAndAuditType', // 驳回至列表数据

    isOldFlagUrl: "/crmCustomer/tcustomerinfo/detail/getLabels", // 是否老客户

    getSysRoleCodeList:'admin/role/getSysRoleCodeList/byIds', // 获取当前客户所有角色

    // 企业微信审核列表总数
    tcustomercontractWxAuditCount: '/crmCustomer/tcustomercontract/wx/audit/count',

    // 获取下一条数据
    tcustomercontractWxGetPreOrNextOne: '/crmCustomer/tcustomercontract/wx/getPreOrNextOne',

    // /tcustomercontract/audit/{id} ('合同主体信息审核wxhr:tcustomercontract_audit')
    tcustomercontractWxAudit: '/crmCustomer/tcustomercontract/doAudit',

    salaryList: '/salary/tsalaryaccount/getAccountSimpleDetail', // 工资列表

    noPaybackList: '/hrApproval/tadvancepayment/getExistOverduePayment/',

    emergencyDetail: '/hrEmergency/h5/emergency/getEventDetailById', // 突发事件详情

    emergencyProcessRecord: '/hrEmergency/recordeventoperation/page', // 突发事件审批记录

    emergecyBackUrl:'/hrEmergency/h5/emergency/back',

    emergecyPassUrl:'/hrEmergency/h5/emergency/auditPass',

    emergecyMajorUrl:'/hrEmergency/h5/emergency/toMajor/',

    emergecySuportUrl:'/hrEmergency/h5/emergency/toSupplemented',

    emergecySideDetail:'/hrEmergency/h5/emergency/getWxPreOrNextOne',




}